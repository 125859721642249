import { useQuery, UseQueryResult } from '@tanstack/react-query';
import {
  ExpansionOpportunity,
  Mandates,
  MarketData,
} from 'utils/principal-service/principal-endpoints.types';
import { CRMMandate } from '../../shared/types/crm';
import { CRMMandatesPayload } from '../../utils/firebase';
import { getPrincipalMandateAssignments } from '../../utils/principal-service/principal-endpoints';

export type Mandate = CRMMandate & MarketData;

export function isMandate(obj: Mandate | ExpansionOpportunity): obj is Mandate {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    'aze_mandateid' in obj &&
    typeof (obj as Mandate).aze_mandateid === 'string'
  );
}

export const useCRMMandates = (
  { filter, principalPri }: CRMMandatesPayload,
  allowIncompleteFilter?: boolean,
): UseQueryResult<Mandates | undefined> => {
  return useQuery({
    queryFn: () => {
      if (filter?.hasAllMandates || !filter) {
        return getPrincipalMandateAssignments(principalPri);
      }
      if (allowIncompleteFilter) {
        return getPrincipalMandateAssignments(principalPri, filter);
      }
      if (
        filter &&
        (filter.countries?.length || filter.territories?.length) &&
        filter.marketSegments?.length &&
        filter.portfolios?.length
      ) {
        return getPrincipalMandateAssignments(principalPri, filter);
      }
      return undefined;
    },
    queryKey: [
      'mandates',
      principalPri,
      {
        hasAllMandates: filter?.hasAllMandates,
        countries: filter?.countries,
        marketSegments: filter?.marketSegments,
        portfolios: filter?.portfolios,
        territories: filter?.territories,
      },
    ],
    refetchOnWindowFocus: false,
  });
};
