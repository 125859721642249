import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { SubFeatureType } from 'shared/types/features/FeatureIds';
import { getOperationalPerformanceTabsConfiguration } from 'utils/principal-service/configuration-endpoints';

export type PerformanceTabConfigKeys =
  | SubFeatureType.PERFORMANCE_SALES
  | SubFeatureType.PERFORMANCE_OPPORTUNITIES
  | SubFeatureType.PERFORMANCE_LEADS
  | SubFeatureType.PERFORMANCE_PRODUCT_SAMPLES
  | SubFeatureType.PERFORMANCE_SUPPLY_CHAIN;

export type OpPerfTabConfigs = {
  [tabKey in PerformanceTabConfigKeys]: {
    title: string;
    reportPage: string;
    reportPage_customerData?: string;
    disableMarketsegments: boolean;
  };
};

export const useOpPerfTabConfigs = (): UseQueryResult<OpPerfTabConfigs> =>
  useQuery({
    queryFn: getOperationalPerformanceTabsConfiguration,
    queryKey: ['configurations', 'operational-performance-tabs'],
    select: ({ data }) => data,
  });
