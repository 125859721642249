import { useEffect, useState } from 'react';
import { MarketInsight } from 'utils/firebase/collection';
import { getCRMMarketInsights } from '../../utils/principal-service/crm-endpoints';
import { Timestamp } from 'firebase/firestore';

export const useCRMMarketInsights = (
  selectedPrincipal: string,
): { data: MarketInsight[]; isLoading: boolean } => {
  const [data, setData] = useState<MarketInsight[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setData([]);
    setIsLoading(true);

    async function getInsights() {
      const insights = await getCRMMarketInsights(selectedPrincipal);

      setData(
        insights.map((insight) => ({
          ...insight,
          principalId: insight.principalId ?? selectedPrincipal,
          link: {
            href: insight.link?.href,
            label: insight.link?.label ?? insight.link?.href,
          },
          publicationDate: insight.publicationDate
            ? Timestamp.fromDate(
                new Date(insight.publicationDate as unknown as string),
              )
            : undefined,
        })),
      );
      setIsLoading(false);
    }

    getInsights();
  }, [selectedPrincipal]);

  return { data, isLoading };
};
