import { orderBy, QueryConstraint, where } from 'firebase/firestore';
import { getCurrentPrincipal } from 'lib/currentPrincipal';
import { useMemo } from 'react';
import { MarketInsight } from 'utils/firebase/collection';
import { useCollection } from 'utils/firebase/hooks/useCollection';
import { useCurrentUser, usePrincipals } from './';

export function useMarketInsights(): {
  data: MarketInsight[];
  isLoading: boolean;
} {
  const { user } = useCurrentUser();
  const { data: principals } = usePrincipals();
  const { currentPrincipal } = getCurrentPrincipal(user.userId, principals);

  const byPrincipalId = useMemo<QueryConstraint[]>(() => {
    const constraints = [orderBy('publicationDate', 'desc')];
    return [...constraints, where('principalId', '==', currentPrincipal.id)];
  }, [currentPrincipal]);

  const collection = useCollection('marketinsights', byPrincipalId);
  const result = (collection || []).map(({ data, id }) => ({
    ...data,
    marketSegment: { name: data.marketSegment, value: data.marketSegment },
    id,
  }));
  return { data: result, isLoading: !collection };
}
