import { useCurrentUser } from 'hooks/queries';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DropdownSingleSelect } from '../../components/Dropdown/DropdownVariants/DropdownSingleSelect';
import { DropdownOptionProps } from '../../components/Dropdown/helper/DropdownTypes';
import { Modal } from '../../components/Modal';
import {
  Currency,
  currencyFullNameMapping,
  currencyShortNameWithSymbolMapping,
} from '../../utils/principal-service/user-endpoints.types';

export type CurerncyChangerModalProps = {
  isBigMenu: boolean;
};

const isValidCurrency = (value: string | number): value is Currency => {
  return typeof value === 'string' && Object.keys(Currency).includes(value);
};

export const CurrencyChanger: React.FC<CurerncyChangerModalProps> = ({
  isBigMenu,
}) => {
  const { user, update: updateCurrentUser } = useCurrentUser();
  const [showCurrencyModal, setShowCurrencyModal] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<Currency>(
    user.currency,
  );
  const { t } = useTranslation();

  const showCurrencyChanger =
    process.env.REACT_APP_SHOW_CURRENCY_CHANGER === 'true';

  const availableCurrencyOptions = Object.entries(currencyFullNameMapping).map(
    ([value, name]) => ({
      name: t(`features:currencySwitcher:${name}`),
      value,
    }),
  );

  const onConfirm = () => {
    updateCurrentUser({ currency: selectedCurrency });
    setShowCurrencyModal(false);
  };

  const onChange = (selectedOption: DropdownOptionProps) => {
    if (
      isValidCurrency(selectedOption.value) &&
      // 👇 Removing this prevents us from changing the dropdown option
      selectedCurrency !== selectedOption.value
    ) {
      setSelectedCurrency(selectedOption.value);
    }
  };

  useEffect(() => {
    // 👇 Needed to force a reset in the Dropdown initial value when there are
    // multiple instances of this component being rendered at once
    setSelectedCurrency(user.currency);
  }, [user.currency]);

  return (
    // 👇 AZEPP-1387 Once PowerBi is ready to receive the Currency, add env
    // variable REACT_APP_SHOW_CURRENCY_CHANGER="true"
    showCurrencyChanger ? (
      <>
        <Link
          to="#"
          className={`block h-full px-5 py-3 hover:bg-blue-100 md:flex lg:block ${
            isBigMenu ? '' : 'lg:px-2'
          }`}
          onClick={() => setShowCurrencyModal(true)}
        >
          <div className="shrink-0 border-b">
            {t(
              `features:currencySwitcher:${
                currencyShortNameWithSymbolMapping[user.currency]
              }`,
            )}
          </div>
        </Link>
        <Modal
          isBig={false}
          icon="Currency"
          isIconAlignedCenter
          isHidden={!showCurrencyModal}
          confirmText={t(`modals:currencyChangerModal:confirmText`)}
          heading={t(`modals:currencyChangerModal:heading`)}
          headingAlign="center"
          isCloseIconHidden={false}
          isConfirmButtonAlignedRight={false}
          isConfirmButtonDisabled={false}
          onCancel={() => setShowCurrencyModal(false)}
          onConfirm={onConfirm}
        >
          <span className="text-grey-700">
            {t(`modals:currencyChangerModal:dropDownTitle`)}
          </span>
          <DropdownSingleSelect
            onChange={onChange}
            initialSelection={availableCurrencyOptions.find(
              ({ value }) => value === selectedCurrency,
            )}
            options={availableCurrencyOptions}
          ></DropdownSingleSelect>
        </Modal>
      </>
    ) : null
  );
};
