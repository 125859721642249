export type MarketSegmentCountryDetails = {
  icon: string;
  name: string;
  customerPortalName?: string;
};

export type MarketSegmentDefinitions = Record<
  string,
  MarketSegmentCountryDetails
>;

export const marketSegments: MarketSegmentDefinitions = {
  AG: {
    icon: 'SpecialityAgriHorti',
    name: 'Agricultural & Environmental Solutions',
  },
  AN: {
    icon: 'AnimalNutrition',
    name: 'Animal Nutrition',
  },
  CASE: {
    icon: 'Case',
    name: 'CASE',
  },
  CO2: {
    icon: 'OtherApplications',
    name: 'CO2',
  },
  COPH: {
    icon: 'Pharma',
    name: 'Compounding Pharma',
  },
  DS: {
    icon: 'OtherApplications',
    name: 'De-Icing Salt',
  },
  EL: {
    icon: 'Electronics',
    name: 'Electronics',
  },
  ES: {
    icon: 'EssentialChemicals',
    name: 'Essential Chemicals',
  },
  FC: {
    icon: 'FineChemicals',
    name: 'Fine Chemicals',
  },
  FF: {
    icon: 'OtherApplications',
    name: 'Flavors and fragrances',
  },
  FH: {
    icon: 'FoodAndHealth',
    name: 'Food & Health',
  },
  HCIC: {
    icon: 'HomecareAndIndustrialCleaning',
    name: 'Homecare and Industrial Cleaning',
    customerPortalName: 'Home Care & Industrial Cleaning',
  },
  LMF: {
    icon: 'LubricantsAndMetalWorkingFluids',
    name: 'Lubricants & MWF',
  },
  OTHER: {
    icon: 'OtherApplications',
    name: 'Other Applications',
  },
  PC: {
    icon: 'PersonalCare',
    name: 'Personal Care',
  },
  PCH: {
    icon: 'EssentialChemicals',
    name: 'Performance Chemicals',
  },
  PH: {
    icon: 'Pharma',
    name: 'Pharma',
  },
  RPA: {
    icon: 'RubberAndPlasticAdditives',
    name: 'Advanced Materials & Additives',
  },
  TL: {
    icon: 'TextilesLeatherAndPaper',
    name: 'Textiles & Leather',
  },
};
