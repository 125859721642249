import { UserData } from 'utils/firebase/collection';

export type ViewUsersResponse = {
  result: UserData[] | UserData;
};

export const currencyShortNameWithSymbolMapping: Record<Currency, string> = {
  EUR: 'symbol-eur-short',
  USD: 'symbol-usd-short',
};

export const currencyFullNameMapping: Record<Currency, string> = {
  EUR: 'eur',
  USD: 'usd',
};

export const currencyPowerBiMapping: Record<Currency, string> = {
  EUR: 'Euro',
  USD: 'Dollar',
};

export enum Currency {
  EUR = 'EUR',
  USD = 'USD',
}
