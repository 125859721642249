import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getExpansionOpportunities } from 'utils/principal-service/principal-endpoints';
import { type ExpansionOpportunity } from 'utils/principal-service/principal-endpoints.types';

export const useExpansionOpportunities = ({
  principalId,
}: {
  principalId: string;
}): UseQueryResult<ExpansionOpportunity[], Error> => {
  return useQuery({
    queryKey: ['expansionOpportunities', principalId],
    queryFn: async () => {
      return await getExpansionOpportunities(principalId);
    },
    select: ({ data }) => {
      return data.items;
    },
  });
};
