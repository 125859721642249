import { IPublicClientApplication } from '@azure/msal-browser';
import {
  AuthProvider,
  AuthProviderCallback,
  Client,
} from '@microsoft/microsoft-graph-client';
import { sharepointSilentRequest } from '../shared/authentication-config';

export type SharePointApiResult = {
  sharePointFolder: string;
  value: FileTreeElement[];
  subFileTrees?: SharePointUpdateResult[];
  updates?: number;
  updatedFiles?: FileTreeElement[];
};

export type SharePointUpdateResult = {
  sharePointFolder: string;
  updates: number;
  updatedFiles: FileTreeElement[];
  filesInAllSubTrees: number;
  fileTree?: FileTreeElement[];
  subFileTrees?: SharePointUpdateResult[];
};

export type SharePointUpdateSubResult = {
  updates: number;
  updatedFiles: FileTreeElement[];
  filesInAllSubTrees: number;
  subFileTrees: SharePointUpdateResult[];
};

export type SharePointStateType = {
  lastupdated: string;
  state: FileTreeElement | undefined;
};

export type File = {
  mimeType: string;
};

export type Folder = {
  childCount: number;
};

export type FileTreeElement = {
  id: string;
  parentReference: {
    driveId: string;
    driveType: string;
    id: string;
    path: string;
  };
  name: string;
  webUrl: string;
  size: number;
  createdBy: {
    application?: {
      id: string;
      displayName: string;
    };
    user: {
      id?: string;
      email?: string;
      displayName: string;
    };
  };
  createdDateTime: string;
  lastModifiedBy: {
    user: {
      id?: string;
      displayName: string;
    };
  };
  lastModifiedDateTime: string;
  file?: File;
  folder?: Folder;
  modifiedSinceLastLogin?: boolean;
  '@microsoft.graph.downloadUrl'?: string;
};

export const isFolder = (element: FileTreeElement): boolean => {
  return element.folder ? true : false;
};

export const isSharePointUpdateResult = (
  data: SharePointUpdateResult | FileTreeElement,
): data is SharePointUpdateResult => {
  return (data as SharePointUpdateResult).updates !== undefined;
};

export function initSharePointConnection(
  instance: IPublicClientApplication,
): Client {
  const authProvider: AuthProvider = (callback: AuthProviderCallback) => {
    instance
      .acquireTokenSilent(sharepointSilentRequest)
      .then((response) => {
        callback(null, response.accessToken);
      })
      .catch((err) => {
        callback(err, null);
      });
  };
  return Client.init({ authProvider: authProvider });
}

export function getSharePointFolder(
  client: Client,
  sharePointDriveId: string,
  sharePointFolder: string,
  setResultFunction: (
    r: SharePointApiResult,
  ) => void | Promise<SharePointUpdateResult>,
  setErrorMessage: (e: string) => void,
  t: (s: string) => string,
): Promise<SharePointUpdateResult | void> | undefined {
  if (sharePointFolder) {
    return client
      .api(`/drives/${sharePointDriveId}/items/${sharePointFolder}/children`)
      .get()
      .then(
        (
          apiResult,
        ): void | Promise<SharePointUpdateResult> | SharePointUpdateResult => {
          if (apiResult) {
            apiResult.sharePointFolder = sharePointFolder;
            return setResultFunction(apiResult);
          } else {
            return setResultFunction({
              sharePointFolder: sharePointFolder,
              value: [],
            });
          }
        },
      )
      .catch((err: Error) => {
        switch (err.message) {
          case 'Access denied':
            setErrorMessage(
              t('features:shared-documents:errors:api:accessDenied'),
            );
            break;

          default:
            setErrorMessage(t('features:shared-documents:errors:api:default'));
        }
      });
  }
  return undefined;
}

export async function getLastSharepointUpdatesForFolder(
  client: Client,
  sharePointDriveId: string,
  sharePointFolder: string,
  lastCheck: number | null,
  t: (t: string) => string,
): Promise<SharePointUpdateResult> {
  /*
    ### Aggregation / sumFunction to sum up subFolder results
  */
  const sumFunction = async (
    results: SharePointApiResult,
  ): Promise<SharePointUpdateResult> => {
    const subPromises = results.value.map(
      async (resultItem: FileTreeElement) => {
        if (isFolder(resultItem)) {
          // Recursive follow sub folders
          return await getLastSharepointUpdatesForFolder(
            client,
            sharePointDriveId,
            resultItem.id,
            lastCheck,
            t,
          );
        } else {
          return resultItem;
        }
      },
    );
    const updatesReduced: SharePointUpdateSubResult = await Promise.all(
      subPromises,
    ).then((completedPromises) => {
      const fileTrees: SharePointUpdateResult[] = [];
      let numberOfFiles = 0;
      let numberOfUpdates = 0;
      let updatedFiles: FileTreeElement[] = [];
      completedPromises.forEach(
        (promiseResult: SharePointUpdateResult | FileTreeElement) => {
          // If it is a Sharepoint Update Result it as a Folder with a subtree
          if (isSharePointUpdateResult(promiseResult)) {
            // ### It is a folder ###
            const updateResult: SharePointUpdateResult =
              promiseResult as SharePointUpdateResult;
            numberOfUpdates += updateResult.updates;
            numberOfFiles += updateResult.filesInAllSubTrees;
            fileTrees.push(updateResult);
            updatedFiles = updatedFiles.concat(updateResult.updatedFiles);
          } else {
            // ### It is a file ###
            numberOfFiles++;
            // Check Timestamp of Files, if file was modified in the last 14 days return item
            if (
              promiseResult &&
              new Date(Date.now() - 12096e5).getTime() <
                new Date(
                  (promiseResult as FileTreeElement).lastModifiedDateTime,
                ).getTime()
            ) {
              // Check if it was modified since last login for Menu Indication
              if (
                (lastCheck ? lastCheck : Date.now()) <
                new Date(
                  (promiseResult as FileTreeElement).lastModifiedDateTime,
                ).getTime()
              ) {
                numberOfUpdates++;
                (promiseResult as FileTreeElement).modifiedSinceLastLogin =
                  true;
              }
              updatedFiles.push(promiseResult as FileTreeElement);
            }
          }
        },
      );

      return {
        updates: numberOfUpdates,
        updatedFiles: updatedFiles,
        filesInAllSubTrees: numberOfFiles,
        subFileTrees: fileTrees,
      } as SharePointUpdateSubResult;
      /**
       * Completed Subpromises END
       */
    });

    if (updatesReduced) {
      return {
        sharePointFolder: results.sharePointFolder,
        updates: updatesReduced.updates,
        updatedFiles: updatesReduced.updatedFiles,
        filesInAllSubTrees: updatesReduced.filesInAllSubTrees,
        fileTree: results.value,
        subFileTrees: updatesReduced.subFileTrees,
      };
    }
    return {
      sharePointFolder: results.sharePointFolder,
      updates: 0,
      updatedFiles: [],
      filesInAllSubTrees: 0,
      fileTree: results.value,
    };
  };

  /**
   *  END Aggregation / Sum Up
   */

  const finalResult = await getSharePointFolder(
    client,
    sharePointDriveId,
    sharePointFolder,
    sumFunction,
    (e) => console.info(e),
    t,
  );

  if (!finalResult || isNaN(finalResult.updates)) {
    return {
      sharePointFolder: '',
      updates: 0,
      updatedFiles: [],
      filesInAllSubTrees: 0,
      fileTree: undefined,
    };
  } else {
    return finalResult;
  }
}

export function getRegionAndSubTypeFolderForFile(file: FileTreeElement): {
  region: string;
  subType: string;
} {
  const folderSeparator = '/External - Principal Portal Files/';

  if (file.parentReference.path.includes(folderSeparator)) {
    const nestedFolderPath =
      file.parentReference.path.split(folderSeparator)[1];
    const seperatedFolder = nestedFolderPath.split('/');
    const region = seperatedFolder[0];
    return {
      region: region || '',
      subType: nestedFolderPath.split(region)[1].substring(1) || '',
    };
  }

  return {
    region: '',
    subType: '',
  };
}
