import axios from 'axios';
import { getFirebaseBearerToken } from '../../lib/getFirebaseBearerToken';
import {
  EventReportsFilterDTO,
  TrackReportViewedDTO,
  TrackSocialListeningReportDTO,
} from './event-endpoints.types';

const baseRequestFunction = async <T>(
  method: string,
  urlExtention: string,
  body?: Record<string, unknown>,
) => {
  const bearerToken = await getFirebaseBearerToken();
  return axios<T>({
    url: `${process.env.REACT_APP_PORTAL_SERVICE_BASE_URL}/events/${urlExtention}`,
    method: `${method}`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
    },
    data: body,
  });
};

export const trackUserLoggedIn = async (currentPrincipal: string) => {
  return baseRequestFunction('post', 'login', {
    principalSlug: currentPrincipal,
  });
};

export const sendEventReportsFilter = (body: EventReportsFilterDTO) => {
  return baseRequestFunction<void>('post', 'reports/filter', body);
};

export const trackMarketIntelligenceLinkClick = async (
  principalId: string,
  marketInsightTitle: string,
) => {
  return baseRequestFunction('post', 'market-insights/click', {
    principalId,
    marketInsightTitle,
  });
};

export const trackMarketIntelligenceShare = async (
  principalId: string,
  marketInsightTitle: string,
) => {
  return baseRequestFunction('post', 'market-insights/share', {
    principalId,
    marketInsightTitle,
  });
};

export const trackSocialListeningReportDownload = async (
  data: TrackSocialListeningReportDTO,
) => {
  return baseRequestFunction('post', 'market-intelligence/download', data);
};

export const trackReportViewed = (body: TrackReportViewedDTO) => {
  return baseRequestFunction('post', 'reports/view', body);
};

export const trackSharepointDownload = async (
  data: TrackSocialListeningReportDTO,
) => {
  return baseRequestFunction('post', 'shared-documents/download', data);
};
