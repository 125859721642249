import { useMsal } from '@azure/msal-react';
import classNames from 'classnames';
import { Alert } from 'components/Alert';
import { Heading } from 'components/Heading';
import { Icon } from 'components/Icon';
import { Loading } from 'components/Loading';
import { ReportPreview, ReportPreviewProps } from 'components/ReportPreview';
import { Table } from 'components/Table/Table';
import { TableBody } from 'components/Table/TableBody';
import { TableCell } from 'components/Table/TableCell';
import { TableHeader } from 'components/Table/TableHeader';
import { TableRow } from 'components/Table/TableRow';
import {
  comparer,
  dateStringComparer,
  stringComparer,
} from 'features/PortalMembers/PortalMembers';
import FallbackSocialListeningReportImg from 'images/Fallback_Social_Listening_Report.png';
import { FileTreeElement, initSharePointConnection } from 'lib/sharepoint';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SortingConfig } from 'shared/types/SortingConfig';
import { trackSocialListeningReportDownload } from 'utils/principal-service/event-endpoints';

const sorter = (
  itemA: FileTreeElement,
  itemB: FileTreeElement,
  { column, asc }: SortingConfig<SocialListeningArchivesTableColumn>,
) => {
  switch (column) {
    case SocialListeningArchivesTableColumn.NAME:
      return comparer(asc, itemA.name, itemB.name, stringComparer);
    case SocialListeningArchivesTableColumn.LASTMODIFIED:
      return comparer(
        asc,
        itemA.lastModifiedDateTime,
        itemB.lastModifiedDateTime,
        dateStringComparer,
      );
    case SocialListeningArchivesTableColumn.OWNER:
      return comparer(
        asc,
        itemA.lastModifiedBy.user.displayName,
        itemB.lastModifiedBy.user.displayName,
        stringComparer,
      );
    default:
      return 0;
  }
};

export enum SocialListeningArchivesTableColumn {
  NAME = 'name',
  LASTMODIFIED = 'lastModified',
  OWNER = 'owner',
}

export const SocialListeningReportTab: FC<{ currentPrincipalId: string }> = ({
  currentPrincipalId,
}) => {
  const { t } = useTranslation();
  const { instance } = useMsal();
  const client = initSharePointConnection(instance);
  const sharePointDriveId = process.env.REACT_APP_SHAREPOINT_DRIVE_ID as string;

  const [currentSocialListeningData, setCurrentSocialListeningData] = useState<
    ReportPreviewProps | string
  >();
  const [archivedSocialListeningData, setArchivedSocialListeningData] =
    useState<FileTreeElement[]>();

  const [sortConfig, setSortConfig] = useState<
    SortingConfig<SocialListeningArchivesTableColumn>
  >({
    column: SocialListeningArchivesTableColumn.NAME,
    asc: false,
  });

  const handleSortingChange = (
    targetColumn: SocialListeningArchivesTableColumn,
  ) => {
    const asc = sortConfig.column === targetColumn ? !sortConfig.asc : true;
    setSortConfig({ column: targetColumn, asc });
  };

  useEffect(() => {
    const isDataLoaded =
      !!currentSocialListeningData && !!archivedSocialListeningData;

    if (client && sharePointDriveId && !isDataLoaded) {
      client
        .api(
          `/drives/${sharePointDriveId}/items/01HGRIMZQ2KUTITX3MHFDIPHPPH3ZNDAYV/children`,
        )
        .get()
        .then((data: { value: FileTreeElement[] }) => {
          if (!data.value) return;

          const currentReport = data.value.find(
            ({ file }) => file?.mimeType === 'image/jpeg',
          );

          if (!currentReport) {
            setCurrentSocialListeningData('false');
            return;
          }

          setCurrentSocialListeningData({
            title: currentReport.name,
            imageUrl: currentReport.webUrl,
          });
        });

      client
        .api(
          `drives/${sharePointDriveId}/items/01HGRIMZWNN5BWN5DGPNHZSCGPP5IHAO4Z/children`,
        )
        .get()
        .then((data: { value: FileTreeElement[] }) => {
          if (!data.value) return;

          const pdfReports = data.value.filter(
            ({ file }) => file?.mimeType === 'application/pdf',
          );

          setArchivedSocialListeningData(pdfReports);
        });
    }
  }, [
    archivedSocialListeningData,
    client,
    currentSocialListeningData,
    sharePointDriveId,
  ]);

  const CurrentReportElement = useMemo(() => {
    if (!currentSocialListeningData || !archivedSocialListeningData)
      return <Loading loading />;
    const fallbackDoc = archivedSocialListeningData.sort(
      (a, b) =>
        new Date(b.createdDateTime).getTime() -
        new Date(a.createdDateTime).getTime(),
    )[0];
    return typeof currentSocialListeningData === 'string' || !fallbackDoc ? (
      <Alert
        message={`${t(
          'features:market-intelligence:socialListeningReport:reportDataError',
        )}`}
        flavour="error"
      />
    ) : (
      <ReportPreview
        {...currentSocialListeningData}
        fallback={{
          link: fallbackDoc.webUrl,
          title: fallbackDoc.name,
          imageUrl: FallbackSocialListeningReportImg,
        }}
      />
    );
  }, [currentSocialListeningData, archivedSocialListeningData, t]);

  const onFileClick = (
    fileTreeElement: FileTreeElement,
    principalId: string,
  ) => {
    trackSocialListeningReportDownload({
      principalId,
      fileName: fileTreeElement.name,
    });

    window.open(fileTreeElement.webUrl, '_blank');
  };

  return (
    <>
      <Heading
        text={t(
          'features:market-intelligence:socialListeningReport:currentReport',
        )}
        level="h2"
      />
      <div className="mb-9">{CurrentReportElement}</div>
      <Heading
        text={t(
          'features:market-intelligence:socialListeningReport:allReports',
        )}
        level="h2"
      />
      {!archivedSocialListeningData && <Loading loading />}
      {archivedSocialListeningData && !archivedSocialListeningData?.length && (
        <Alert
          message={t(
            'features:market-intelligence:socialListeningReport:noReports',
          )}
          flavour="info"
        />
      )}
      {!!archivedSocialListeningData?.length && (
        <Table>
          <TableHeader>
            <TableRow>
              {Object.values(SocialListeningArchivesTableColumn).map((key) => (
                <TableCell onClick={() => handleSortingChange(key)} key={key}>
                  <div className="flex flex-row">
                    {t(
                      `features:market-intelligence:socialListeningReport:archivesTable:${key}`,
                    )}
                    <div
                      className={classNames('w-20px ml-1', {
                        'text-grey-300': sortConfig.column !== key,
                      })}
                    >
                      <Icon
                        name={
                          sortConfig.column === key
                            ? sortConfig.asc
                              ? 'ArrowNavDown'
                              : 'ArrowNavUp'
                            : 'ArrowNavDown'
                        }
                      />
                    </div>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {archivedSocialListeningData
              .sort((a: FileTreeElement, b: FileTreeElement) =>
                sorter(a, b, sortConfig),
              )
              .map((element) => (
                <TableRow
                  key={element.id}
                  hoverable
                  onClick={() => onFileClick(element, currentPrincipalId)}
                >
                  <TableCell>
                    <div className="flex flex-row">
                      <div className="mr-1 grow-0">
                        <Icon name="FilePDF" />
                      </div>{' '}
                      {element.name}
                    </div>
                  </TableCell>
                  <TableCell>
                    {new Date(element.lastModifiedDateTime).toLocaleDateString(
                      'de-DE',
                    )}
                  </TableCell>
                  <TableCell>
                    {element.lastModifiedBy.user.displayName}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};
