import { useMsal } from '@azure/msal-react';
import { useNewSharedDocumentsCount } from 'hooks/useNewSharedDocumentsCount';
import { default as React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { loginRequest } from 'shared/authentication-config';
import { Role } from 'shared/types/authorization';
import { resetAll } from 'store/sharepoint/sharepoints.action';
import { signOut as signOutFromFirebase } from 'utils/firebase/firebase-auth';

import { getCurrentPrincipal } from 'lib/currentPrincipal';
import { MainFeatureType } from 'shared/types/features/FeatureIds';
import {
  findRouteDefintionByPathname,
  hasMainMenuConfig,
  InternalRoute,
  MainMenuConfig,
  routes,
} from '../../controllers/ContentController/Routes';
import { CurrencyChanger } from '../../features/CurrencyChanger/CurrencyChanger';
import { useAuth, useCurrentUser, usePrincipals } from '../../hooks/queries';
import { usePrincipalFeatures } from '../../hooks/usePrincipalFeatures';
import { tracking } from '../../lib/tracking';
import { Icon } from '../Icon';
import { AuxiliaryNav } from './AuxiliaryNav';
import { Logos } from './Logos';
import { MainNav } from './MainNav';

export const siteHeaderStyles = {
  menuItemSpanBase: 'w-4 md:w-full ml-5 md:ml-0 text-white lg:w-4',
  bigMenuLinks: 'lg:flex-row lg:justify-start',
  bigMenuIcons: 'lg:ml-4 lg:my-auto',
  bigMenuLabels: 'hidden lg:block lg:ml-3 lg:my-auto',
};
export type BigMenuProps = {
  bigMenu: boolean;
};
export type NavProps = {
  bigMenu?: boolean;
  toggleBigMenu?: () => void;
  getCurrentPath?: () => string;
  getMenuItems?: (menuSection: string) => (InternalRoute & {
    mainMenuConfig: MainMenuConfig;
    permissions: string[];
  })[];
};

export const SiteHeader: React.FC = () => {
  const { instance } = useMsal();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { user, update: updateUser, role } = useCurrentUser();
  const { data: principals } = usePrincipals();
  const { currentPrincipal } = getCurrentPrincipal(user.userId, principals);
  const auth = useAuth();

  const dashboardRoute = routes(currentPrincipal.slug).find(
    MainFeatureType.DASHBOARD,
  );
  const otherRoutes = routes(currentPrincipal.slug).routes.filter(
    (route) => route.routeId !== MainFeatureType.DASHBOARD,
  );

  //Gets mainMenuTitle of current route
  const getCurrentPath = (): string => {
    if (
      location.pathname === dashboardRoute.url &&
      hasMainMenuConfig(dashboardRoute)
    ) {
      return dashboardRoute.mainMenuConfig.title;
    }

    const currentRoute = otherRoutes.find((route) =>
      location.pathname.startsWith(route.url),
    );
    if (currentRoute && hasMainMenuConfig(currentRoute)) {
      return currentRoute.mainMenuConfig.title;
    } else {
      return '';
    }
  };

  const userHasBigMenuOpen = user?.bigMenuIsOpenToggle;

  const { data: principalFeatures } = usePrincipalFeatures(currentPrincipal.id);

  const [bigMenu, setBigMenu] = useState<boolean>(!!userHasBigMenuOpen);
  const [showMenu, setShowMenu] = useState(false);

  const newSharedDocuments = useNewSharedDocumentsCount();

  const logoutHandler = async () => {
    try {
      await signOutFromFirebase();
      trackingOnLogoutHandler();
      dispatch(resetAll());
    } catch (error) {
      console.error(error);
    }
  };

  const trackingOnLogoutHandler = () => {
    tracking.push('content', 'Content View', {
      category: 'User Area',
      type: 'Sign out',
      content: 'User Sign out',
    });

    tracking.push('user', 'User Logout', {
      loginStatus: 'logged out',
      type: role,
      principalID: currentPrincipal.id,
    });
  };

  const toggleBigMenu = () => {
    updateUser({ bigMenuIsOpenToggle: !bigMenu });
    setBigMenu((): boolean => !bigMenu);
  };

  /** Tracking */
  const signOut = async () => {
    await logoutHandler();
    instance.logoutRedirect({
      ...loginRequest,
      account: instance.getActiveAccount(),
    });
  };

  useEffect(() => {
    setBigMenu(!!userHasBigMenuOpen);
  }, [userHasBigMenuOpen]);

  const toggleShowMenu = () => {
    setShowMenu((): boolean => !showMenu);
    setBigMenu(false);
  };

  useEffect(() => {
    const pathname = location.pathname;
    const {
      additionalInformation: { trackingCategory = '', trackingType = '' },
      name = '',
    } = findRouteDefintionByPathname(pathname);
    tracking.push('content', 'Content View', {
      category: trackingCategory,
      type: trackingType,
      content: name,
    });
  }, [location]);

  const HEADER_BASE_CLASSES =
    'flex sticky lg:flex-col shrink-0 flex-row justify-between flex-wrap md:flex-nowrap bg-grey-700 text-white md:px-4 lg:px-0 z-50 top-0 left-0';
  const HEADER_DEFAULT_CLASSES = `${HEADER_BASE_CLASSES} h-8 lg:h-screen lg:w-80px`;
  const HEADER_BIG_CLASSES = `${HEADER_BASE_CLASSES} h-8 lg:h-screen lg:w-auto`;

  const getMenuItems = (
    menuSection: string,
  ): (InternalRoute & {
    mainMenuConfig: MainMenuConfig;
  })[] =>
    routes(currentPrincipal.slug)
      .routes.filter(hasMainMenuConfig)
      .filter((i) => i.mainMenuConfig.section === menuSection)
      .filter((route) =>
        principalFeatures?.find((feature) => feature.id === route.routeId),
      )
      .sort((a, b) =>
        a.mainMenuConfig.order > b.mainMenuConfig.order ? 1 : -1,
      );

  const logoRoute = user?.global
    ? dashboardRoute
    : user.roles?.[currentPrincipal.id] === Role.PRINCIPAL_DEVELOPER
    ? otherRoutes.find(
        (route) => route.routeId === MainFeatureType.API_KEY_GENERATOR,
      )
    : dashboardRoute;

  return (
    <header className={!bigMenu ? HEADER_DEFAULT_CLASSES : HEADER_BIG_CLASSES}>
      {logoRoute && (
        <>
          <Logos
            bigMenu={bigMenu}
            route={logoRoute}
            selectedPrincipal={currentPrincipal}
          />
        </>
      )}
      {auth && (
        <div
          className={`pt-4 md:pt-0${
            showMenu ? '' : ' hidden'
          } order-3 md:order-none md:flex md:justify-end lg:grow lg:flex-col lg:justify-between ${
            bigMenu ? `lg:w-240px lg:items-start` : 'w-full'
          } bg-grey-700 h-screen md:h-auto md:pt-0 lg:pt-2`}
        >
          <nav
            key="menu-loggedin"
            className={`flex flex-col md:flex-row md:items-stretch lg:flex-col ${
              bigMenu ? `w-full` : ''
            }`}
          >
            <div className="hidden md:block lg:hidden">
              <CurrencyChanger isBigMenu={false} />
            </div>
            <MainNav
              onMenuItemClick={() => setShowMenu(false)}
              bigMenu={bigMenu}
              getCurrentPath={getCurrentPath}
              getMenuItems={getMenuItems}
              isAuthEmpty={!auth}
              t={t}
              newSharedDocuments={newSharedDocuments}
            />
          </nav>
          <nav
            key="menu-basic"
            className={`flex flex-col md:flex-row md:items-stretch lg:flex-col ${
              bigMenu ? `w-full` : ''
            }`}
          >
            <AuxiliaryNav
              onMenuItemClick={() => setShowMenu(false)}
              bigMenu={bigMenu}
              getCurrentPath={getCurrentPath}
              toggleBigMenu={toggleBigMenu}
              getMenuItems={getMenuItems}
              isAuthEmpty={!auth}
              signOut={signOut}
              dashboardRoute={dashboardRoute}
              t={t}
            />
          </nav>
        </div>
      )}
      <button
        className={`order-none w-8 hover:bg-blue-300 md:hidden ${
          showMenu ? 'bg-blue-100' : ''
        }`}
        onClick={toggleShowMenu}
      >
        <span className="m-auto block w-4">
          <Icon
            name={showMenu ? 'Close' : 'Menu'}
            title={t('linksOrButtons:menuToggle')}
          />
        </span>
      </button>
    </header>
  );
};
