import axios from 'axios';
import { getFirebaseBearerToken } from '../../lib/getFirebaseBearerToken';
import { CustomerSuppplierPagesData } from './digital-activities-endpoints.types';

export const getCustomerSupplierPages = async (
  principalId: string,
): Promise<CustomerSuppplierPagesData> => {
  const bearerToken = await getFirebaseBearerToken();
  return (
    await axios<CustomerSuppplierPagesData>({
      url: `${process.env.REACT_APP_PORTAL_SERVICE_BASE_URL}/principals/${principalId}/customer-supplier-pages`,
      method: 'Get',
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
    })
  ).data;
};
