import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getCountriesInformation } from '../../utils/principal-service/crm-endpoints';

export type CRMCountryData = {
  id: number;
  region: string | undefined;
  cluster: string | undefined;
  country: { name: string | undefined; ISO3: string | undefined };
  staffCount: number;
  intro: string | undefined;
  offices: string[];
  managingDirector: {
    name: string | undefined;
    email: string | undefined;
  };
  marketSegments: { name: string; code: string; id: string; lab: boolean }[];
  servedBy?: {
    aze_countryid: string;
    aze_isocode: string;
    aze_name: string;
  };
  extraMessage?: string;
};

export type CountryWithColor = {
  ISO: string;
  colorGroup: {
    name: string;
    color: string;
  };
};

export const useCRMCountriesData = (): UseQueryResult<
  CRMCountryData[],
  Error
> => {
  return useQuery({
    queryFn: async () => await getCountriesInformation(),
    queryKey: ['countryData'],
    select: ({ items }) => items,
  });
};
