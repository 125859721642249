import { useEffect, useState } from 'react';
import { BaseUserType } from 'utils/firebase';
import { searchAdUsersByName } from '../../utils/principal-service/user-endpoints';

export const useAdUsersByName = (
  searchTerm: string | undefined,
): BaseUserType[] | undefined => {
  const [adUsers, setAdUsers] = useState<BaseUserType[]>();

  useEffect(() => {
    if (!searchTerm) return;
    setAdUsers(undefined);
    searchAdUsersByName(searchTerm).then(({ data }) => {
      setAdUsers(data?.results);
    });
  }, [searchTerm]);
  return adUsers;
};
