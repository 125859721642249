import axios from 'axios';
import { RoleDefinitions } from '../../hooks/queries/useRoleDefinitions';
import { getFirebaseBearerToken } from '../../lib/getFirebaseBearerToken';

export const getLegacyRoleDefinitions = async (): Promise<RoleDefinitions> => {
  const bearerToken = await getFirebaseBearerToken();
  const result = await axios<RoleDefinitions>({
    url: `${process.env.REACT_APP_PORTAL_SERVICE_BASE_URL}/legacy-role-definition`,
    method: 'Get',
    headers: {
      Authorization: `Bearer ${bearerToken}`,
    },
  });
  return result.data;
};
