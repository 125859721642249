import axios from 'axios';
import { BaseUserType, ViewUsersRequest } from 'utils/firebase';
import { UserData } from 'utils/firebase/collection';
import { NewUserDetails } from '../../components/UserManagementModals';
import { getFirebaseBearerToken } from '../../lib/getFirebaseBearerToken';
import { Role } from '../../shared/types/authorization';
import { ViewUsersResponse } from './user-endpoints.types';

const baseRequestFunction = async <T>(
  method: string,
  urlExtention: string,
  body?: Record<string, unknown>,
) => {
  const bearerToken = await getFirebaseBearerToken();
  return axios<T>({
    url: `${process.env.REACT_APP_PORTAL_SERVICE_BASE_URL}/users/${urlExtention}`,
    method: `${method}`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
    },
    data: body,
  });
};

export const getCurrentUser = async () => {
  return (await baseRequestFunction<UserData>('get', 'me')).data;
};

export function createPrincipalUser(
  { mandateFilters, ...userDetails }: NewUserDetails,
  principalId: string,
) {
  return baseRequestFunction<{ userId: string; inviteUrl: string }>(
    'post',
    `principal/${principalId}`,
    {
      mandateFilters: mandateFilters[principalId],
      ...userDetails,
    },
  );
}

export function updateGlobalUserRoles(
  userId: string,
  roles: Record<string, Role> | null,
) {
  return baseRequestFunction<void>('patch', `${userId}`, {
    roles: roles,
  });
}

export const searchAdUsersByName = async (searchTerm: string) => {
  return baseRequestFunction<{ results?: BaseUserType[] }>(
    'get',
    `azelis?searchTerm=${searchTerm}`,
  );
};

export const updateCurrentUser = async (
  newInformation: Record<string, unknown>,
) => {
  await baseRequestFunction('patch', 'me', newInformation);
};

export const updateUserById = async (
  userId: string,
  principalId: string,
  newInformation: Record<string, unknown>,
) => {
  await baseRequestFunction(
    'patch',
    `${userId}/${principalId}`,
    newInformation,
  );
};

export const deleteUsers = async (userIds: string[], principalId: string) => {
  const successfulDeletion: string[] = [];
  const failedDeletion: string[] = [];

  const resultsOfPromises = await Promise.all(
    userIds.map(async (singleUserId) => {
      try {
        await baseRequestFunction(
          'delete',
          `${singleUserId}/principal/${principalId}`,
        );
        return { success: true, userId: singleUserId };
      } catch (error) {
        console.error(`deleteUser for ${singleUserId} `, error);
        return { success: false, userId: singleUserId, error };
      }
    }),
  );

  //Process result after all requests are completed:
  resultsOfPromises.forEach((result) => {
    if (result.success) {
      successfulDeletion.push(result.userId);
    } else {
      failedDeletion.push(result.userId);
    }
  });
  return { successfulDeletion, failedDeletion };
};

export const viewUsers = async (
  params: ViewUsersRequest,
): Promise<ViewUsersResponse> => {
  const { allInternalUsers, currentPrincipal, userIdToView } = params;

  if (allInternalUsers) {
    const bearerToken = await getFirebaseBearerToken();
    const users = await axios<{ users: UserData[] }>({
      url: `${process.env.REACT_APP_PORTAL_SERVICE_BASE_URL}/users/?emailPart=@azelis&excludeGlobal=true`,
      method: 'get',
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
    });

    return {
      result: users.data.users,
    };
  } else if (userIdToView) {
    // Get single user
    const bearerToken = await getFirebaseBearerToken();
    const user = await axios<UserData>({
      url: `${process.env.REACT_APP_PORTAL_SERVICE_BASE_URL}/users/${userIdToView}/principal/${currentPrincipal}`,
      method: 'get',
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
    });

    return {
      result: user.data,
    };
  } else {
    // Get all users of current principal
    const bearerToken = await getFirebaseBearerToken();
    const users = await axios<{ users: UserData[] }>({
      url: `${process.env.REACT_APP_PORTAL_SERVICE_BASE_URL}/users/principal/${currentPrincipal}`,
      method: 'get',
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
    });

    return {
      result: users.data.users,
    };
  }
};

export const getAllGlobalUsers = () => {
  return baseRequestFunction<UserData[]>('get', 'global-user');
};
