import { ExpansionOpportunity } from 'components/ExpansionOpportunity';
import { Loading } from 'components/Loading';
import { getCurrentPrincipal } from 'lib/currentPrincipal';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type ExpansionOpportunity as ExpansionOpportunityType } from 'utils/principal-service/principal-endpoints.types';
import {
  useCurrentUser,
  useExpansionOpportunities,
  usePrincipals,
} from '../../hooks/queries';
import { EmptyState } from '../EmptyState';
import { Map } from '../Map/Map';

export const ExpansionOpportunitiesWithMap: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useCurrentUser();
  const { data: principals } = usePrincipals();
  const { currentPrincipal } = getCurrentPrincipal(user.userId, principals);

  const { data: expansionOpportunities } = useExpansionOpportunities({
    principalId: currentPrincipal.id,
  });

  const [countries, setCountries] = useState<string[]>([]);

  useEffect(() => {
    const tempCountries: string[] =
      expansionOpportunities?.map(({ country }) => country.iso ?? '') || [];
    if (JSON.stringify(countries) !== JSON.stringify(tempCountries)) {
      setCountries(tempCountries);
    }
  }, [countries, expansionOpportunities]);

  const isLoading = !expansionOpportunities;
  const hasExpansionOpportunities =
    expansionOpportunities && expansionOpportunities.length > 0;

  return (
    <Loading loading={isLoading}>
      <div className="xl:max-w-xl-content flex grow flex-wrap">
        <div className="mb-4 flex w-full flex-wrap md:mb-5">
          {hasExpansionOpportunities ? (
            <>
              <div className="xs:w-6/12">
                {expansionOpportunities.map(
                  (entry: ExpansionOpportunityType, index: number) => (
                    <ExpansionOpportunity
                      country={entry.country?.name ?? undefined}
                      date={entry.createdOn}
                      rationale={entry.description ?? ''}
                      principalPortfolio={entry.portfolio?.name ?? ''}
                      portfolioFit={+(entry.ratings.portfolioFit ?? 0)}
                      salesTeamStrength={
                        +(entry.ratings.salesTeamStrength ?? 0)
                      }
                      labSupportResources={
                        +(entry.ratings.labSupportResources ?? 0)
                      }
                      key={index}
                      marketSegmentName={entry.marketSegment?.name}
                    />
                  ),
                )}
              </div>
              <div className="xs:px-4 xs:w-6/12 w-full justify-center">
                {!!countries.length && (
                  <Map
                    opportunity={true}
                    countries={countries.map((country) => ({
                      name: country,
                      colorGroup: {
                        name: 'Expansion Opportunity',
                        color: 'bg-purple-100',
                      },
                    }))}
                  />
                )}
              </div>
            </>
          ) : (
            <div className="mt-4 w-full md:px-3">
              <EmptyState
                message={t(
                  'features:market-intelligence:expansionOpportunities:empty',
                )}
                hasBorder
              />
            </div>
          )}
        </div>
      </div>
    </Loading>
  );
};
