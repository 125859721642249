import { Checkbox } from 'components/Checkbox';
import { EmptyState } from 'components/EmptyState';
import { LoadingBounce } from 'components/Loading';
import { Table } from 'components/Table/Table';
import { TableBody } from 'components/Table/TableBody';
import { TableCell } from 'components/Table/TableCell';
import { TableHeader } from 'components/Table/TableHeader';
import { TableRow } from 'components/Table/TableRow';
import { BaseUseUserResult, useCurrentUser } from 'hooks/queries';
import { usePermission } from 'hooks/usePermission';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SortingConfig } from 'shared/types/SortingConfig';
import { Permission } from 'shared/types/authorization';
import {
  Mandates,
  Portfolio,
} from '../../utils/principal-service/principal-endpoints.types';
import { PortalMemberTableRow } from './PortalMemberTableRow';
import {
  PortalMemberTableColumn,
  ToggleUsersToDeleteFunc,
} from './PortalMembers';

interface PortalMemberTableContentProps {
  filteredUsers: BaseUseUserResult[];
  sortConfig: SortingConfig<PortalMemberTableColumn>;
  handleSortingChange: (targetColumn: PortalMemberTableColumn) => void;
  selectedPrinciple?: string;
  toggleUsersToDelete: ToggleUsersToDeleteFunc;
  userIdsToDelete: string[];
  mandates?: Mandates;
  availablePortfolios?: Portfolio[];
}
const sortOrderToShow = (
  targetColumn: string,
  { column, asc }: SortingConfig<PortalMemberTableColumn>,
): 'asc' | 'desc' | undefined => {
  if (column !== targetColumn) return;
  return asc ? 'asc' : 'desc';
};

type TableHeaderCellProps = {
  type: PortalMemberTableColumn;
  sortConfig: SortingConfig<PortalMemberTableColumn>;
  handleSortingChange: (targetColumn: PortalMemberTableColumn) => void;
};

const TableHeaderCell = ({
  type,
  sortConfig,
  handleSortingChange,
}: TableHeaderCellProps) => {
  const { t } = useTranslation();

  return (
    <TableCell
      noWrap
      displaySortingButton
      sortOrder={sortOrderToShow(type, sortConfig)}
      onClick={() => {
        handleSortingChange(type);
      }}
    >
      {t(`features:portal-members:labels:table:${type}`)}
    </TableCell>
  );
};

export const PortalMemberTableContent: React.FC<
  PortalMemberTableContentProps
> = ({
  filteredUsers,
  sortConfig,
  handleSortingChange,
  selectedPrinciple,
  toggleUsersToDelete,
  userIdsToDelete,
  mandates,
  availablePortfolios,
}: PortalMemberTableContentProps) => {
  const { t } = useTranslation();
  const { user: currentUser } = useCurrentUser();
  const { userHasPermission } = usePermission();
  const userCanEditMembers = userHasPermission(Permission.USER_ROLE_MGMT_EDIT);

  if (!filteredUsers) {
    return <LoadingBounce />;
  }

  if (filteredUsers.length === 0) {
    return (
      <div className="mt-4">
        <EmptyState
          message={t('features:portal-members:labels:noResults:users')}
          hasBorder
        />
      </div>
    );
  }

  const hasFilteredUsersAndUserIdsToDelete =
    !!filteredUsers?.length && !!userIdsToDelete.length;

  // Minus 1 from filtered users, to account for the
  // current user not being included in userIdsToDelete
  const filteredUsersMinusCurrentUserEqualsUsersToDelete =
    filteredUsers.length - 1 === userIdsToDelete.length;

  const selectAllCheckboxIsIntermediate =
    hasFilteredUsersAndUserIdsToDelete &&
    !filteredUsersMinusCurrentUserEqualsUsersToDelete;

  const selectAllCheckboxIsChecked =
    hasFilteredUsersAndUserIdsToDelete &&
    filteredUsersMinusCurrentUserEqualsUsersToDelete;

  return (
    <Table>
      <TableHeader>
        <TableRow darkBorder>
          {userCanEditMembers && (
            <td className="flex items-center justify-center px-3 py-4">
              <Checkbox
                onChange={(isChecked) =>
                  toggleUsersToDelete(
                    filteredUsers
                      .filter(({ user }) => user.userId !== currentUser?.userId)
                      .filter((user) => user.isGlobalUser === false)
                      .map(({ user }) => user.userId),
                    isChecked,
                  )
                }
                initialChecked={selectAllCheckboxIsChecked}
                displayAsIntermediate={selectAllCheckboxIsIntermediate}
              />
            </td>
          )}
          <TableHeaderCell
            type={PortalMemberTableColumn.NAME}
            handleSortingChange={handleSortingChange}
            sortConfig={sortConfig}
          />
          <TableHeaderCell
            type={PortalMemberTableColumn.MEMBER_SINCE}
            handleSortingChange={handleSortingChange}
            sortConfig={sortConfig}
          />
          <TableHeaderCell
            type={PortalMemberTableColumn.USER_TYPE}
            handleSortingChange={handleSortingChange}
            sortConfig={sortConfig}
          />
          <TableHeaderCell
            type={PortalMemberTableColumn.ROLE}
            handleSortingChange={handleSortingChange}
            sortConfig={sortConfig}
          />
          <TableHeaderCell
            type={PortalMemberTableColumn.ACTIVE_MANDATES}
            handleSortingChange={handleSortingChange}
            sortConfig={sortConfig}
          />
          <TableHeaderCell
            type={PortalMemberTableColumn.ACTIVE_PORTFOLIOS}
            handleSortingChange={handleSortingChange}
            sortConfig={sortConfig}
          />
        </TableRow>
      </TableHeader>
      <TableBody>
        {filteredUsers.map((user, index) => (
          <PortalMemberTableRow
            key={index}
            user={user}
            selectedPrincipal={selectedPrinciple || ''}
            toggleUsersToDelete={toggleUsersToDelete}
            userIdsToDelete={userIdsToDelete}
            availableMandatesCount={mandates?.count.mandates}
            availablePortfolioCount={availablePortfolios?.length}
          />
        ))}
      </TableBody>
    </Table>
  );
};
