import { TileButtonWithIcon } from 'components';
import { Heading } from 'components/Heading';
import { routes } from 'controllers/ContentController/Routes';
import { useCurrentUser, usePrincipals } from 'hooks/queries';
import { getCurrentPrincipal } from 'lib/currentPrincipal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SubFeatureType } from 'shared/types/features/FeatureIds';

export const AdminCenter: React.FC = () => {
  const { user } = useCurrentUser();
  const { data: principals } = usePrincipals();
  const { currentPrincipal } = getCurrentPrincipal(user.userId, principals);

  const globalUsersRoute = routes(currentPrincipal.slug).find(
    SubFeatureType.GLOBAL_USERS,
  );
  const principalAdministrationRoute = routes(currentPrincipal.slug).find(
    SubFeatureType.PRINCIPAL_ADMINISTRATION,
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateToPage = (link: string) => {
    navigate(link);
  };

  return (
    <div className="w-full px-4 md:px-6">
      <div className="mb-7 pt-6 md:mb-9 md:pt-7" data-test="page-header">
        <div className="flex flex-col items-baseline justify-between md:flex-row md:space-x-2">
          <Heading
            text={t('features:admin-center:heading')}
            margin={0}
            level="h1"
          />
        </div>
      </div>

      <div className="mt-9 flex w-full justify-center">
        <Heading
          text={t('features:admin-center:startMessage')}
          margin={0}
          level="h2"
        />
      </div>

      <div className="mt-9 flex w-full flex-col items-center gap-4 md:flex-row md:items-stretch md:justify-center lg:gap-6">
        <TileButtonWithIcon
          title={t('features:admin-center:principalAdministration:tile:title')}
          description={t(
            'features:admin-center:principalAdministration:tile:description',
          )}
          iconName="PrincipalAdministration"
          onClick={() => navigateToPage(principalAdministrationRoute.url)}
        />
        <TileButtonWithIcon
          title={t('features:admin-center:globalUserManagement:tile:title')}
          description={t(
            'features:admin-center:globalUserManagement:tile:description',
          )}
          iconName="Global"
          onClick={() => navigateToPage(globalUsersRoute.url)}
        />
      </div>
    </div>
  );
};
