import React, { ReactElement } from 'react';
import { TabTitle } from './TabTitle';

export type TabsProps = {
  children: (ReactElement | false)[];
  activeTab: string;
  onTabChange: (id: string) => void;
  isDisabled?: boolean;
};

export const Tabs: React.FC<TabsProps> = ({
  children,
  activeTab,
  onTabChange,
  isDisabled,
}: TabsProps) => {
  const nonFalseyChildren = children.filter(
    (item): item is ReactElement => !!item,
  );

  return (
    <div>
      <nav className="flex flex-col sm:flex-row">
        {nonFalseyChildren
          .filter((child) => child.props.tabKey)
          .map((item, index) => (
            <TabTitle
              key={index}
              title={item.props.title}
              tabKey={item.props.tabKey}
              selectedTab={activeTab}
              setSelectedTab={onTabChange}
              isDisabled={isDisabled}
            />
          ))}
        <div className="border-grey-500 grow border-b" />
      </nav>
      <div className="mt-7">
        {nonFalseyChildren.filter((item) => item.props.tabKey === activeTab)}
      </div>
    </div>
  );
};

export default Tabs;
