import { User } from 'firebase/auth';

export enum UserActionType {
  SET_AUTH = 'user/SET_AUTH',
}

export type UserState = {
  auth: User | null;
  error?: Error;
};
