import { User } from 'firebase/auth';
import { ActionWithPayload, createAction } from 'utils/reducer.utils';
import { UserActionType } from './user.types';

export type UserAction = SetAuthAction;

type SetAuthAction = ActionWithPayload<UserActionType.SET_AUTH, User | null>;

export const setAuth = (payload: User | null): SetAuthAction =>
  createAction(UserActionType.SET_AUTH, payload);
