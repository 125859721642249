import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import {
  APIKeyData,
  APIKeyLastAction,
  APIKeyStatus,
} from 'features/DataConnection/types';
import { signInWithCustomToken, User } from 'firebase/auth';
import { t } from 'i18next';
import { getCurrentPrincipal } from 'lib/currentPrincipal';
import { useCallback, useMemo } from 'react';
import { Role } from 'shared/types/authorization';
import { auth } from 'utils/firebase';
import { getFirebaseBearerToken } from '../../lib/getFirebaseBearerToken';
import { useCurrentUser } from './useCurrentUser';
import { usePrincipals } from './usePrincipals';
import { useUsers } from './useUsers';

type UserWithToken = User & { accessToken: string };

type arrayofSubscriptionHistoryRequestsData = {
  data: {
    history: getSubscriptionHistoryResponseData[];
  };
};

export type getSubscriptionHistoryResponseData = {
  activity: string;
  apiKey: string;
  dateOfActivity: string;
  keyId: string;
  projectName: string;
  scopes: string[];
  userName?: string;
  fromUserName?: string;
  fromUserEmail?: string;
  toUserName?: string;
  toUserEmail?: string;
  toUser?: string;
  invitedby?: string;
};

export type APIScope = {
  active: boolean;
  createdAt: string;
  description: string;
  id: string;
  name: string;
  updatedAt: string;
};

export type APISpecsResponseData = {
  id: string;
  type: string;
  name: string;
  properties: {
    format: string;
    value: {
      link: string;
    };
  };
};

export type InitialAPIData = {
  createdDate: string;
  displayName: string;
  endDate?: string;
  expirationDate: string;
  id: string;
  name: string;
  notificationDate?: string;
  primaryKey: string;
  principalId: string;
  scopes: APIScope[];
  startDate: string;
  state: APIKeyStatus;
  stateComment?: string;
  lastAction: APIKeyLastAction;
  user: {
    email: string;
    firebaseId: string;
    guid: string;
  };
};

export type InitialAPIHistoryData = {
  createdDate: string;
  displayName?: string;
  id: string;
  name: string;
  primaryKey: string;
  scopes: APIScope[];
  type: string;
  user: {
    email: string;
    firebaseId: string;
    guid: string;
  };
  fromUser?: {
    email: string;
    firebaseId: string;
    guid: string;
  };
  toUser?: {
    email: string;
    firebaseId: string;
    guid: string;
  };
  invitedBy?: {
    email: string;
    firebaseId: string;
    guid: string;
  };
};

export type APIHistory = {
  activity: string;
  apiKey: string;
  dateOfActivity: string;
  keyId: string;
  projectName: string;
  scopes: APIScope[];
  userName?: string;
  fromUserName?: string;
  toUserName?: string;
  invitedby?: string;
};

export type ApiUser = {
  firebaseId: string;
  email: string;
};

export type APIsHookResult = {
  creatorId: string;
  projectName: string;
};

export type UseAPIKeyResult = {
  apiKeys: APIKeyData[] | undefined;
  areAvailableKeysLoading: boolean;
  apiHistory?: APIHistory[];
  isApiKeyHistoryLoading: boolean;
  apiSpecification: APISpecsResponseData | undefined;
  isApiSpecificationLoading: boolean;
  createSubscription: (
    partial: Partial<APIKeyData>,
  ) => Promise<InitialAPIHistoryData>;
  deleteSubscription: (selectedApiId: string) => Promise<void>;
  moveSubscription: (
    selectedApiId: string,
    userIdFrom: string,
    userIdTo: string,
  ) => Promise<void>;
};

// 🍻 Code Bounty 🍻
// Find a way to move this logic to server-side.
// Tokens for Data Connection endpoints must be generated
// by the signInWithCustomToken() function, and I can't find
// a server-side equivallent.
export const getFirebaseData = async () => {
  const bearerToken = await getFirebaseBearerToken();
  const {
    data: { token },
  } = await axios({
    url: `${process.env.REACT_APP_PORTAL_SERVICE_BASE_URL}/auth/data-connection`,
    method: 'Get',
    headers: {
      Authorization: `Bearer ${bearerToken}`,
    },
  });
  if (!token) return;

  const { user } = await signInWithCustomToken(auth, token);

  return { bearerToken: (user as UserWithToken).accessToken };
};
///

export const useApiKeys = (): UseAPIKeyResult => {
  const queryClient = useQueryClient();
  const { user: currentUser, role } = useCurrentUser();
  const { users } = useUsers({
    disabled: role === Role.PRINCIPAL_DEVELOPER,
  });
  const { data: principals } = usePrincipals();
  const { currentPrincipal } = getCurrentPrincipal(
    currentUser.userId,
    principals,
  );

  const firebaseData = useMemo(async () => await getFirebaseData(), []);

  const { data: availableKeys, isLoading: areAvailableKeysLoading } = useQuery({
    queryFn: async () => {
      const bearerToken = (await firebaseData)?.bearerToken;
      return await axios({
        url: `${process.env.REACT_APP_PORTAL_SERVICE_BASE_URL}/subscriptions/${currentPrincipal.id}`,
        method: 'Get',
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      });
    },
    queryKey: [
      'subscriptions',
      currentPrincipal.id,
      users?.map(({ user }) => user.userId),
    ],
    select: ({ data }) => data.subscriptions as APIKeyData[],
    refetchOnWindowFocus: false,
  });

  const { data: apiHistory, isLoading: isApiKeyHistoryLoading } = useQuery({
    queryFn: async () => {
      const bearerToken = (await firebaseData)?.bearerToken;
      if (role === Role.PRINCIPAL_ADMIN) {
        const response = await axios({
          url: `${process.env.REACT_APP_PORTAL_SERVICE_BASE_URL}/subscriptions/${currentPrincipal.id}/history`,
          method: 'Get',
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        });
        return response.data.history;
      } else if (role === Role.PRINCIPAL_DEVELOPER) {
        if (!availableKeys) {
          return;
        }
        let arrayofSubscriptionHistory: arrayofSubscriptionHistoryRequestsData[];
        try {
          arrayofSubscriptionHistory = await Promise.all(
            availableKeys.map(({ keyId }) => {
              return axios({
                url: `${process.env.REACT_APP_PORTAL_SERVICE_BASE_URL}/subscriptions/${currentPrincipal.id}/history/${keyId}`,
                headers: {
                  Authorization: `Bearer ${bearerToken}`,
                },
                method: 'Get',
              });
            }),
          );
        } catch (error) {
          console.log('arrayofSubscriptionHistory error: ', error);
          return undefined;
        }

        const arrayofSubscriptionHistoryWithAddition =
          arrayofSubscriptionHistory
            .flatMap((response) => response.data.history)
            .map((historyItem) => {
              let overwrittenValues = {};
              const overwrittenValuesTranslationKey =
                'features:data-connection:apiKeyManagement:keyHistory';
              if (historyItem.fromUserEmail === currentUser.email) {
                overwrittenValues = {
                  userName: t(`${overwrittenValuesTranslationKey}:youCaps`),
                  fromUserName: t(`${overwrittenValuesTranslationKey}:youCaps`),
                  toUser: historyItem.toUserEmail,
                };
              }

              if (historyItem.userName === currentUser.name) {
                overwrittenValues = {
                  userName: t(`${overwrittenValuesTranslationKey}:youCaps`),
                  toUser: historyItem.toUserEmail,
                };
              }

              if (historyItem.toUserEmail === currentUser.email) {
                overwrittenValues = {
                  userName: historyItem.fromUserEmail,
                  toUser: t(`${overwrittenValuesTranslationKey}:youSmall`),
                  toUserName: t(`${overwrittenValuesTranslationKey}:youSmall`),
                };
              }

              return { ...historyItem, ...overwrittenValues };
            });

        return arrayofSubscriptionHistoryWithAddition;
      }
    },
    queryKey: ['history', currentPrincipal.id, users, availableKeys],
    select: (data) => (Array.isArray(data) ? data : undefined),
    refetchOnWindowFocus: false,
  });

  const createSubscription = useCallback(
    async (newApi: Partial<APIKeyData>): Promise<InitialAPIHistoryData> => {
      const bearerToken = (await firebaseData)?.bearerToken;
      const response = await axios({
        url: `${process.env.REACT_APP_PORTAL_SERVICE_BASE_URL}/subscriptions`,
        method: 'Post',
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
        data: {
          email: currentUser.email,
          principalId: currentPrincipal.id,
          projectName: newApi.projectName,
          scopes: newApi.scopes,
        },
      });
      queryClient.invalidateQueries({
        queryKey: ['subscriptions'],
      });

      return response.data;
    },
    [currentPrincipal.id, currentUser.email, firebaseData, queryClient],
  );

  const deleteSubscription = useCallback(
    async (selectedApiId: string): Promise<void> => {
      const bearerToken = (await firebaseData)?.bearerToken;
      await axios({
        url: `${process.env.REACT_APP_PORTAL_SERVICE_BASE_URL}/subscriptions/${currentPrincipal.id}/${selectedApiId}`,
        method: 'Delete',
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      queryClient.invalidateQueries({
        queryKey: ['subscriptions'],
      });
      queryClient.invalidateQueries({
        queryKey: ['history'],
      });
    },
    [currentPrincipal.id, firebaseData, queryClient],
  );

  const moveSubscription = useCallback(
    async (
      selectedApiId: string,
      userIdFrom: string,
      userIdTo: string,
    ): Promise<void> => {
      const bearerToken = (await firebaseData)?.bearerToken;
      const response = await axios({
        url: `${process.env.REACT_APP_PORTAL_SERVICE_BASE_URL}/subscriptions/${currentPrincipal.id}/${selectedApiId}`,
        method: 'Put',
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
        data: {
          userIdFrom,
          userIdTo,
        },
      });
      if (response) {
        queryClient.invalidateQueries({
          queryKey: ['subscriptions', 'history'],
        });
      }
    },
    [currentPrincipal.id, firebaseData, queryClient],
  );

  const { data: apiSpecification, isLoading: isApiSpecificationLoading } =
    useQuery({
      queryFn: async () => {
        const bearerToken = (await firebaseData)?.bearerToken;
        return await axios({
          url: `${process.env.REACT_APP_PORTAL_SERVICE_BASE_URL}/specification`,
          method: 'Get',
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        });
      },
      queryKey: ['apiSpecification'],
      select: ({ data }) => data,
      refetchOnWindowFocus: false,
    });

  const apiData = useMemo(() => {
    return {
      availableKeys,
      areAvailableKeysLoading,
      apiSpecification,
      isApiSpecificationLoading,
      apiHistory,
      isApiKeyHistoryLoading,
    };
  }, [
    apiHistory,
    apiSpecification,
    areAvailableKeysLoading,
    availableKeys,
    isApiKeyHistoryLoading,
    isApiSpecificationLoading,
  ]);

  return {
    areAvailableKeysLoading: apiData.areAvailableKeysLoading,
    apiKeys: apiData.availableKeys,
    apiSpecification: apiData.apiSpecification,
    isApiSpecificationLoading: apiData.isApiSpecificationLoading,
    apiHistory: apiData.apiHistory,
    isApiKeyHistoryLoading: apiData.isApiKeyHistoryLoading,
    createSubscription,
    deleteSubscription,
    moveSubscription,
  };
};
