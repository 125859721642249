import { EmptyState } from 'components/EmptyState';
import { Loading } from 'components/Loading';
import { Modal } from 'components/Modal';
import { SearchInputWithButton } from 'components/SearchInputWithButton/SearchInputWithButton';
import { Table } from 'components/Table/Table';
import { TableBody } from 'components/Table/TableBody';
import { TableCell } from 'components/Table/TableCell';
import { TableHeader } from 'components/Table/TableHeader';
import { TableRow } from 'components/Table/TableRow';
import { PortalMemberName } from 'features/PortalMembers/PortalMemberName';
import { useAdUsersByName } from 'hooks/queries/useAdUsersByName';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseUserType } from 'utils/firebase';

type SelectInternalUserProps = {
  selectedPrincipalId: string;
  onCancel: () => void;
  onConfirm: (user: BaseUserType) => void;
};

export const SelectInternalUser: React.FC<SelectInternalUserProps> = ({
  onConfirm,
  onCancel,
}) => {
  const { t } = useTranslation();
  const [selectedUser, setSelectedUser] = useState<BaseUserType>();
  const [searchTerm, setSearchTerm] = useState<string>();
  const internalUsersFromAd = useAdUsersByName(searchTerm);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | undefined>();

  const SearchElement = useCallback(
    () => (
      <SearchInputWithButton
        labelButton={t('labels:search')}
        placeholderInput={t('labels:userNameSearch')}
        minSearchTermLength={3}
        initialValue={searchTerm}
        onButtonClick={(newSearchTerm) => setSearchTerm(newSearchTerm)}
      />
    ),
    [searchTerm, t],
  );

  return (
    <Modal
      hideAllButtons={!internalUsersFromAd || !internalUsersFromAd.length}
      headingAlign="left"
      heading={t('modals:selectInternalUser:heading')}
      confirmText={t('linksOrButtons:next')}
      onCancel={onCancel}
      isBig
      isCancelButtonHidden
      onConfirm={() => selectedUser && onConfirm(selectedUser)}
      isConfirmButtonDisabled={!selectedUser}
      isConfirmButtonAlignedRight
    >
      {!searchTerm ? (
        <form className="mb-3 mt-6">
          <SearchElement />
        </form>
      ) : internalUsersFromAd ? (
        <form>
          <div className="mb-3 mt-5 flex justify-end">
            <div className="max-w-500px grow">
              <SearchElement />
            </div>
          </div>
          {internalUsersFromAd.length ? (
            <Table>
              <TableHeader>
                <TableRow darkBorder>
                  <TableCell
                    displaySortingButton
                    sortOrder={sortOrder}
                    onClick={() =>
                      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
                    }
                  >
                    {t('labels:name')}
                  </TableCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {internalUsersFromAd
                  ?.sort(({ firstName: a }, { firstName: b }) =>
                    sortOrder === 'desc'
                      ? -a.localeCompare(b)
                      : a.localeCompare(b),
                  )
                  .map((user) => (
                    <TableRow
                      key={`internalUsersFromAd-${user.email}`}
                      hoverable
                      selected={user.email === selectedUser?.email}
                      onClick={() => setSelectedUser(user)}
                    >
                      <TableCell>
                        <PortalMemberName
                          name={`${user.firstName} ${user.lastName}`}
                          email={user.email?.toLowerCase()}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          ) : (
            <div className="mb-3 mt-6">
              <EmptyState
                hasBorder
                message={t('modals:selectInternalUser:emptyStateMessage', {
                  searchTerm,
                })}
              />
            </div>
          )}
        </form>
      ) : (
        <div className="mt-5">
          <Loading loading />
        </div>
      )}
    </Modal>
  );
};
