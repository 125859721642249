import { Badge } from 'components/Badge';
import { LoadingBounce } from 'components/Loading';
import { MaintenanceState } from 'components/MaintenanceState/MaintenanceState';
import { PermissionDenied } from 'components/PermissionDenied/PermissionDenied';
import { useCRMMandates, useCurrentUser, usePrincipals } from 'hooks/queries';
import { useCustomerSupplierPages } from 'hooks/queries/useCustomerSupplierPages';
import { getCurrentPrincipal } from 'lib/currentPrincipal';
import { getFromLocalStorage, LocalStorageKeys } from 'lib/localStorageManager';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  MainFeatureType,
  SubFeatureType,
} from 'shared/types/features/FeatureIds';
import { Heading } from '../../components/Heading';
import { Tab, Tabs } from '../../components/Tabs';
import {
  MainFeatureComponent,
  routes,
} from '../../controllers/ContentController/Routes';
import { marketSegments } from '../../utils/marketSegments.utils';
import { CustomerPortalInsightsTab } from './CustomerPortalInsightsTab';
import {
  DaLocalizationConfig,
  DigitalActivitiesModal,
} from './DigitalActivitiesModal';
import { EmptyConfig } from './EmptyConfig';
import { LocalizationConfigDisplay } from './LocalizationConfigDisplay';
import { SupplierPagesTab } from './SupplierPagesTab';

export const DigitalActivities: MainFeatureComponent = ({ subFeatures }) => {
  const { tab: tabFromURL } = useParams<{ tab: string | undefined }>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useCurrentUser();
  const { data: principals } = usePrincipals();
  const { currentPrincipal } = getCurrentPrincipal(user.userId, principals);
  const [selectedTab, setSelectedTab] = useState<string>('');

  const { data: mandates } = useCRMMandates({
    principalPri: currentPrincipal.id,
  });
  const countriesfromMandates = useMemo(
    () =>
      mandates?.countries.map((country) => ({
        ISO_A3: country.iso,
        name: country.name,
      })) ?? [],
    [mandates],
  );

  const cookieResult: Record<string, DaLocalizationConfig> | null =
    getFromLocalStorage(LocalStorageKeys.DIGITAL_ACTIVITIES);

  const localizationConfig: DaLocalizationConfig | undefined =
    cookieResult && currentPrincipal.manufacturerName
      ? cookieResult[currentPrincipal.manufacturerName]
      : undefined;

  const { data: supplierPages, isLoading: isCustomerSupplierPagesLoading } =
    useCustomerSupplierPages(currentPrincipal.id);

  const selectedSupplierPage = supplierPages?.links.find(
    ({ country, market_segment_code, language }) =>
      country === localizationConfig?.country &&
      market_segment_code === localizationConfig?.marketSegmentId &&
      language === localizationConfig?.language,
  );

  const [isConfigModalHidden, setIsConfigModalHidden] = useState(true);

  useEffect(() => {
    if (!localizationConfig) {
      setIsConfigModalHidden(false);
    }
  }, [localizationConfig]);

  const getMarketSemegentKeys = () =>
    Object.keys(marketSegments).filter((key) => {
      return marketSegments[key].customerPortalName
        ? marketSegments[key].customerPortalName ===
            localizationConfig?.marketSegmentId
        : marketSegments[key].name === localizationConfig?.marketSegmentId;
    });

  // From CP we receive Market segment names that are not 100% in line with our market segment keys. In the case of a missmatch, we store the name that is used in CP in Firebase
  // With this function, we can get the market segment key that is globally
  // And overwrite the marketsegment section for creating the porwerBi filters

  const marketSegmentKeys = marketSegments && getMarketSemegentKeys()[0];
  // create a variable that has the localizationConfig but replace the market segment with the keys
  const localizationConfigWithKeys = localizationConfig && {
    ...localizationConfig,
    marketSegment: marketSegmentKeys,
  };

  const fallbackTabToShowOnLoad = subFeatures ? subFeatures[0].id : '';

  if (!selectedTab && (tabFromURL || fallbackTabToShowOnLoad)) {
    setSelectedTab(tabFromURL ?? fallbackTabToShowOnLoad);
  }

  const userCanViewAtLeastOneTab = subFeatures && subFeatures.length > 0;

  useEffect(() => {
    const digitalActivitiesRoute = routes(currentPrincipal.slug).find(
      MainFeatureType.DIGITAL_ACTIVITIES,
    );
    navigate(`${digitalActivitiesRoute.url}/${selectedTab}`);
    // Because when we reach /digital-activities, there should always be a tab active
    // and the URL should reflect that.
  }, [currentPrincipal.slug, navigate, selectedTab]);

  const isCustomerSupplierPagesFeatureEnabled = !!subFeatures?.find(
    ({ id }) => id === SubFeatureType.DIGITAL_ACTIVITIES_SUPPLIER_PAGES,
  );
  const isCustomerSupplierPagesInMaintenanceMode = !!subFeatures?.find(
    ({ id }) => id === SubFeatureType.DIGITAL_ACTIVITIES_SUPPLIER_PAGES,
  )?.maintenanceMode;

  const isCpInsightsFeatureEnabled = !!subFeatures?.find(
    ({ id }) => id === SubFeatureType.DIGITAL_ACTIVITIES_CP_INSIGHTS,
  );
  const isCpInsightsInMaintenanceMode = !!subFeatures?.find(
    ({ id }) => id === SubFeatureType.DIGITAL_ACTIVITIES_CP_INSIGHTS,
  )?.maintenanceMode;

  const isPageDataReady =
    !isCustomerSupplierPagesLoading && !!countriesfromMandates;

  const hasLocalizationConfig = !!localizationConfig;

  return (
    <div className="w-full" data-testid="digital-activities">
      {!userCanViewAtLeastOneTab && <PermissionDenied />}
      {userCanViewAtLeastOneTab && (
        <div className="w-full px-4 md:px-6">
          <div className="max-w-xl-content mx-auto">
            <div className="mb-4 pt-6 md:pt-7" data-test="page-header">
              <div className="flex flex-col items-center justify-start gap-4 md:flex-row md:space-x-2">
                <Heading
                  text={`${t('features:digital-activities:heading')}`}
                  margin={0}
                  level="h1"
                />
                {selectedTab ===
                  SubFeatureType.DIGITAL_ACTIVITIES_CP_INSIGHTS && (
                  <Badge text={t('components:visibilityBadge:text')} />
                )}
              </div>
            </div>
            {!isPageDataReady && <LoadingBounce />}
            {isPageDataReady && (
              <>
                <LocalizationConfigDisplay
                  fullAvailableCountries={countriesfromMandates}
                  localizationConfig={localizationConfig}
                  onEditClick={() => {
                    setIsConfigModalHidden(false);
                  }}
                />
                {hasLocalizationConfig && (
                  <Tabs activeTab={selectedTab} onTabChange={setSelectedTab}>
                    {isCpInsightsFeatureEnabled && (
                      <Tab
                        tabKey={SubFeatureType.DIGITAL_ACTIVITIES_CP_INSIGHTS}
                        title={t(
                          'features:digital-activities:digitalActivities:tab',
                        )}
                      >
                        {isCustomerSupplierPagesInMaintenanceMode && (
                          <MaintenanceState />
                        )}
                        {!isCustomerSupplierPagesInMaintenanceMode &&
                          localizationConfigWithKeys &&
                          currentPrincipal && (
                            <CustomerPortalInsightsTab
                              principal={currentPrincipal.manufacturerName}
                              filters={localizationConfigWithKeys}
                            />
                          )}
                      </Tab>
                    )}
                    {isCustomerSupplierPagesFeatureEnabled && (
                      <Tab
                        tabKey={
                          SubFeatureType.DIGITAL_ACTIVITIES_SUPPLIER_PAGES
                        }
                        title={t(
                          'features:digital-activities:supplierPages:tab',
                        )}
                      >
                        {isCpInsightsInMaintenanceMode && <MaintenanceState />}
                        {!isCpInsightsInMaintenanceMode && (
                          <SupplierPagesTab link={selectedSupplierPage?.url} />
                        )}
                      </Tab>
                    )}
                  </Tabs>
                )}
                {!hasLocalizationConfig && (
                  <EmptyConfig
                    onLinkClick={() => {
                      setIsConfigModalHidden(false);
                    }}
                  />
                )}
                <DigitalActivitiesModal
                  supplierPageOptionData={supplierPages?.links}
                  existingLocalizationConfig={localizationConfig}
                  // We could just use !!localizationConfig to check if isHidden
                  // BUT then we would have to clear localizationConfig in order to show
                  // the modal, and we don't want that
                  isHidden={isConfigModalHidden}
                  onClose={() => {
                    setIsConfigModalHidden(true);
                  }}
                  principal={currentPrincipal.manufacturerName}
                  countriesFromMandates={countriesfromMandates}
                />
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
