import { getCurrentPrincipal } from 'lib/currentPrincipal';
import { Notification } from 'lib/notifications/notifications';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Role, UserType } from 'shared/types/authorization';
import { extractRoleFromUser } from 'utils/authorization.utils';
import {
  updateUserById,
  viewUsers,
} from 'utils/principal-service/user-endpoints';
import {
  GlobalUser,
  UserData,
} from '../../utils/firebase/collection/firebase-collection-types';
import { useCurrentUser } from './useCurrentUser';
import { usePrincipals } from './usePrincipals';
import { useRoleDefinitions } from './useRoleDefinitions';

// This is a helper object that encapsulates wraps the firebase [user] document and encapsulates the [global_user] document
export type PortalUser = UserData & {
  global?: GlobalUser;
  userType?: UserType;
};

export type BaseUseUserResult = {
  user: PortalUser;
  role: Role;
  userType: UserType;
  isGlobalUser: boolean;
};

export type UseUserResult = BaseUseUserResult & {
  update: (partial: Partial<PortalUser>) => Promise<void>;
};

// Gets currently logged in user by default
// Provide alternative userId to fetch that user
/**
 * Custom hook to fetch user of provided {userId} and update the user via {update}-method
 * @param userId
 * @returns {BaseUseUserResult} with fetched user object, role of the user in the current principal and if user is a global user
 */
export const useUser = (userId: string): UseUserResult | undefined => {
  const { user: currentUser } = useCurrentUser();
  const { data: principals } = usePrincipals();
  const { currentPrincipal } = getCurrentPrincipal(
    currentUser.userId,
    principals,
  );
  const roleDefinitions = useRoleDefinitions();
  const { t } = useTranslation();

  const [targetedUser, setTargetedUser] = useState<PortalUser>();
  const [isUpdated, setIsUpdated] = useState<boolean>(false);

  useEffect(() => {
    setIsUpdated(false);
    viewUsers({
      currentPrincipal: currentPrincipal.id,
      userIdToView: userId,
    })
      .then((data) => {
        if (!Array.isArray(data.result)) {
          setTargetedUser(data.result);
        }
      })
      .catch(() => {
        Notification({
          message: t('errors:failedToLoadUser'),
        });
      });
  }, [currentPrincipal, userId, isUpdated, t]);

  if (!targetedUser) return;

  const isGlobalUser = !!targetedUser.global_user;
  const role =
    extractRoleFromUser(currentPrincipal.id, targetedUser) ?? Role.NONE;
  const userType = roleDefinitions[role]?.user_type;

  const update = async (
    userChanges: Record<string, unknown>,
  ): Promise<void> => {
    await updateUserById(userId, currentPrincipal.id, userChanges);
    setIsUpdated(true);
  };

  return {
    isGlobalUser,
    role,
    update,
    user: targetedUser,
    userType,
  };
};
