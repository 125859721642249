import { useQuery } from '@tanstack/react-query';
import { getCustomerSupplierPages } from 'utils/principal-service/digital-activities-endpoints';

export const useCustomerSupplierPages = (principalId: string) => {
  return useQuery({
    queryFn: async () => {
      const res = await getCustomerSupplierPages(principalId);
      return res;
    },
    queryKey: ['customer-supplier-pages', principalId],
  });
};
