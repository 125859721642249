import axios from 'axios';
import { getFirebaseBearerToken } from '../../lib/getFirebaseBearerToken';

type PowerBiEmbedTokenRequestDto = {
  reportName: string;
  principalManufacturerName: string;
};

export const getPowerBiConfiguration = async () => {
  const bearerToken = await getFirebaseBearerToken();
  return await axios({
    url: `${process.env.REACT_APP_PORTAL_SERVICE_BASE_URL}/reports/configuration`,
    method: 'Get',
    headers: {
      Authorization: `Bearer ${bearerToken}`,
    },
  });
};

export const getPowerBiEmbedToken = async (
  data: PowerBiEmbedTokenRequestDto,
): Promise<string> => {
  const bearerToken = await getFirebaseBearerToken();
  return (
    await axios<string>({
      url: `${process.env.REACT_APP_PORTAL_SERVICE_BASE_URL}/reports/embed-token`,
      method: 'Post',
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
      data,
    })
  ).data;
};
