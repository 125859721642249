import { DropdownOptionProps } from 'components/Dropdown/helper/DropdownTypes';
import React from 'react';
import { type ExpansionOpportunity } from 'utils/principal-service/principal-endpoints.types';
import { mapStringToNameValueOption } from '../../lib/utils';
import { getCountries } from '../CountryDropdown/CountryDropdown';

export type CardProps = {
  entries: ExpansionOpportunity[];
};

export const countryOption = (country: string): string | number =>
  country &&
  (mapStringToNameValueOption(country, getCountries()) as DropdownOptionProps)
    ?.value;

export const handleEntry = (entry: ExpansionOpportunity): string =>
  entry.country
    ? `fi-${countryOption(entry.country.name ?? '')}`.toLowerCase()
    : '';

export const ExpansionOpportunityList: React.FC<CardProps> = ({
  entries,
}: CardProps) => (
  <React.Fragment>
    {entries.length > 0 &&
      entries.map((entry, i) => (
        <div className="mb-3" key={i}>
          <div className="mb-1">
            <span className="mr-1 text-xs font-semibold">{`0${i + 1}`}</span>
            <span
              className={`fi mr-1 inline-block ${handleEntry(entry)}`}
            ></span>
            <span className="mr-1 text-xs font-semibold">
              {entry.country?.name}
            </span>
          </div>
          <div className="text-grey-600 text-2xs leading-2 ml-3 truncate pl-1 font-medium">
            Portfolio: <span>{entry.portfolio?.name}</span>
            <span
              className={`${!entry.portfolio2 && 'hidden'}`}
              data-testid="second-portfolio"
            >
              / {entry.portfolio2?.name}
            </span>
          </div>
          <div className="text-grey-600 text-2xs leading-2 ml-3 truncate pl-1 font-medium">
            Market Segment: <span>{entry.marketSegment?.name}</span>
          </div>
        </div>
      ))}
  </React.Fragment>
);
