import { DropdownSingleSelect } from 'components/Dropdown/DropdownVariants/DropdownSingleSelect';
import { DropdownOptionProps } from 'components/Dropdown/helper/DropdownTypes';
import { Loading } from 'components/Loading';
import { Modal } from 'components/Modal';
import { CountryProps } from 'features/Dashboard/Map/DashboardMap';
import {
  addToLocalStorage,
  getFromLocalStorage,
  LocalStorageKeys,
} from 'lib/localStorageManager';
import { compact } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Regions } from 'utils/countries';
import { CPLinkProps } from 'utils/principal-service/digital-activities-endpoints.types';
import { marketSegments } from '../../utils/marketSegments.utils';

export type DaLocalizationConfig = {
  country: string;
  marketSegmentId: string;
  language: string;
};

type DigitalActivitiesModalProps = {
  supplierPageOptionData?: CPLinkProps[];
  existingLocalizationConfig?: DaLocalizationConfig;
  isHidden: boolean;
  onClose: () => void;
  countriesFromMandates: CountryProps[];
  principal: string;
};

export const iso3CountryCodesToDropdownOptions = (
  input: string[],
  countriesFromMandates: CountryProps[],
): DropdownOptionProps[] =>
  input
    .map((iso3CountryCode) => {
      const countryName = countriesFromMandates.find(
        ({ ISO_A3 }) => ISO_A3 === iso3CountryCode,
      )?.name;

      if (!countryName) return undefined;

      return {
        name: countryName,
        value: iso3CountryCode,
      };
    })
    .filter((i) => Boolean(i)) as DropdownOptionProps[];

export const iso3CountryCodesToRegionsDropdownOptions = (
  input: string[],
  regions: Regions,
): DropdownOptionProps[] => {
  return compact(
    Object.entries(regions).map(([region, countries]) => {
      const countriesPerRegion = compact(
        countries.map(({ ISO_A3, name }) => {
          //If country is available for this user (input-array) add it to the dropdownOption
          if (input.includes(ISO_A3)) {
            return {
              name,
              value: ISO_A3,
            };
          } else {
            return undefined;
          }
        }),
      );

      //If regions has no countries don't add it to dropdownOptions
      if (countriesPerRegion.length) {
        return {
          value: region,
          name: region,
          childOptions: countriesPerRegion,
        };
      } else {
        return undefined;
      }
    }),
  );
};

export const DigitalActivitiesModal: React.FC<DigitalActivitiesModalProps> = ({
  supplierPageOptionData,
  existingLocalizationConfig,
  isHidden,
  onClose,
  principal,
  countriesFromMandates,
}) => {
  const { t } = useTranslation();

  const initialOverlayData = {
    country: existingLocalizationConfig?.country || '',
    marketSegmentId: existingLocalizationConfig?.marketSegmentId || '',
    language: existingLocalizationConfig?.language || '',
  };

  const [selectedOverlayData, setSelectedOverlayData] =
    useState<DaLocalizationConfig>(initialOverlayData);

  const countryOptions = useMemo(
    () =>
      iso3CountryCodesToDropdownOptions(
        [...new Set(supplierPageOptionData?.map((i) => i.country))],
        countriesFromMandates,
      ) || [],
    [countriesFromMandates, supplierPageOptionData],
  );

  const marketSegmentOptions = useMemo(
    () =>
      [
        ...new Set(
          supplierPageOptionData
            ?.filter((i) => i.country === selectedOverlayData.country)
            .map((i) => i.market_segment_code) || [],
        ),
      ].map((option) => ({
        name: marketSegments[option]?.name,
        value: option,
      })),
    // 🤷 Why does adding marketSegmentDefinitions to deps cause infinite reloading??? 🤷
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedOverlayData.country, supplierPageOptionData],
  );

  const languageOptions = useMemo(
    () =>
      [
        ...new Set(
          supplierPageOptionData
            ?.filter(
              (i) =>
                i.country === selectedOverlayData.country &&
                i.market_segment_code === selectedOverlayData.marketSegmentId,
            )
            .map((i) => i.language) || [],
        ),
      ].map((languageCode) => ({
        name: t(`languages:${languageCode}`),
        value: languageCode,
      })),
    [
      selectedOverlayData.country,
      selectedOverlayData.marketSegmentId,
      supplierPageOptionData,
      t,
    ],
  );

  const {
    initialCountrySelection,
    initialMarketSegmentSelection,
    initialLanguageSelection,
  } = useMemo(() => {
    const initialCountrySelection = countryOptions.find(
      (i) => i.value === selectedOverlayData?.country,
    );
    const initialMarketSegmentSelection = marketSegmentOptions.find(
      (i) => i.value === selectedOverlayData?.marketSegmentId,
    );
    const initialLanguageSelection = languageOptions.find(
      (i) => i.value === selectedOverlayData?.language,
    );
    return {
      initialCountrySelection,
      initialMarketSegmentSelection,
      initialLanguageSelection,
    };
  }, [
    countryOptions,
    languageOptions,
    marketSegmentOptions,
    selectedOverlayData,
  ]);

  const isModalLoading =
    !supplierPageOptionData?.length ||
    !countriesFromMandates.length ||
    !countryOptions.length ||
    !principal;

  // AZEPP-405: "If a dropdown menu only has one selection, this selection should already be
  // preselected for user and be greyed out (since he/she cannot change it)."
  //
  // This function attempts to do that, but it just causes infinite loops. Not sure why :(
  // If someone can fix this, please do and I will owe you a beer!
  // 12.04.2020: Beer goes to Laura ;)

  const isConfirmBtnDisabled = Object.values(selectedOverlayData).some(
    (val) => val.length === 0,
  );

  const onConfirm = () => {
    const existingStuff: Record<string, DaLocalizationConfig> | null =
      getFromLocalStorage(LocalStorageKeys.DIGITAL_ACTIVITIES);
    addToLocalStorage(LocalStorageKeys.DIGITAL_ACTIVITIES, {
      ...existingStuff,
      [principal]: selectedOverlayData,
    });
    onClose();
  };

  return (
    <Modal
      isBig
      isHidden={isHidden}
      onCancel={() => {
        setSelectedOverlayData(initialOverlayData);
        onClose();
      }}
      headingAlign="left"
      heading={t('modals:digitalActivitiesModal:heading')}
      confirmText={t('modals:digitalActivitiesModal:confirm')}
      isConfirmButtonDisabled={isConfirmBtnDisabled}
      onConfirm={onConfirm}
    >
      <Loading loading={isModalLoading}>
        <form>
          <div className="text-grey-700">
            <p className="mb-2 mt-1">
              {t('modals:digitalActivitiesModal:description')}
            </p>
            <fieldset>
              <label className="mt-4 block font-medium">
                {t('modals:digitalActivitiesModal:labels:country')}
              </label>
              <DropdownSingleSelect
                placeholder={t('placeholders:dropdownDefault')}
                options={countryOptions}
                initialSelection={initialCountrySelection}
                onChange={(option) => {
                  setSelectedOverlayData({
                    country: option.value.toString(),
                    marketSegmentId: '',
                    language: '',
                  });
                }}
              />
              <label className="mt-4 block font-medium">
                {t('modals:digitalActivitiesModal:labels:marketSegment')}
              </label>
              <DropdownSingleSelect
                disabled={!selectedOverlayData.country}
                placeholder={t('placeholders:dropdownDefault')}
                options={marketSegmentOptions}
                initialSelection={initialMarketSegmentSelection}
                onChange={(option) => {
                  setSelectedOverlayData({
                    ...selectedOverlayData,
                    marketSegmentId: option.value.toString(),
                    language: '',
                  });
                }}
              />
              <label className="mt-4 block font-medium">
                {t('modals:digitalActivitiesModal:labels:language')}
              </label>
              <DropdownSingleSelect
                disabled={!selectedOverlayData.marketSegmentId}
                placeholder={t('placeholders:dropdownDefault')}
                initialSelection={initialLanguageSelection}
                options={languageOptions}
                onChange={(option) => {
                  setSelectedOverlayData({
                    ...selectedOverlayData,
                    language: option.value.toString(),
                  });
                }}
              />
            </fieldset>
          </div>
        </form>
      </Loading>
    </Modal>
  );
};
