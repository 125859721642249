import { Alert } from 'components/Alert';
import { DropdownSingleSelect } from 'components/Dropdown/DropdownVariants/DropdownSingleSelect';
import { LoadingBounce } from 'components/Loading';
import { Modal } from 'components/Modal';
import { routes } from 'controllers/ContentController/Routes';
import { APIKeyData } from 'features/DataConnection/types';
import { useApiKeys, useCurrentUser, useUsers } from 'hooks/queries';
import { Notification } from 'lib/notifications/notifications';
import { formatStandardDate } from 'lib/utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { Role, UserType } from 'shared/types/authorization';
import { MainFeatureType } from 'shared/types/features/FeatureIds';
import { ApiKeyTransferEmailItem } from 'utils/firebase';
import { Principal } from 'utils/firebase/collection/firebase-collection-types';
import { deleteUsers } from 'utils/principal-service/user-endpoints';

const developerHasKeys = (
  firebaseId: string,
  apiKeys: APIKeyData[] | undefined,
) => {
  return apiKeys?.some((key) => key.userDetails.firebaseId === firebaseId);
};

type UserToDelete = { userId: string; name: string; role: Role };

type DeleteUsersModalProps = {
  onCancel: () => void;
  usersToDelete: UserToDelete[];
  selectedPrincipal: Principal;
  onComplete?: () => void;
};

export const DeleteUsersModal: React.FC<DeleteUsersModalProps> = ({
  onCancel,
  usersToDelete,
  selectedPrincipal,
  onComplete,
}) => {
  const { t } = useTranslation();
  const [deletedUserNames, setDeletedUserNames] = useState<string[]>([]);
  const [disableConfirmButton, setDisableConfirmButton] = useState(true);
  const [keyTransferPairs, setKeyTransferPairs] = useState<
    {
      from: string;
      to: string;
    }[]
  >([]);
  const { moveSubscription, apiKeys, areAvailableKeysLoading } = useApiKeys();
  const { users } = useUsers();
  const currentUser = useCurrentUser();
  const isMulti = usersToDelete.length > 1;
  const developersToDelete = usersToDelete.filter(
    ({ role }) => role === Role.PRINCIPAL_DEVELOPER,
  );
  const nonDevelopersToDelete = usersToDelete.filter(
    ({ role }) => role !== Role.PRINCIPAL_DEVELOPER,
  );
  const numberOfDevelopersToDelete = developersToDelete.length;
  const numberOfDevelopersToDeleteWithKeys = developersToDelete.filter(
    ({ userId }) => developerHasKeys(userId, apiKeys),
  ).length;
  const numberOfNonDevelopersToDelete = nonDevelopersToDelete.length;
  const numberOfUsersToDelete = usersToDelete.length;
  const userNameOrNumber = isMulti
    ? usersToDelete.length
    : usersToDelete[0].name;
  const multiOrSingle = isMulti ? 'multi' : 'single';

  const availableDevelopersForKeyTransfer = useMemo(() => {
    const options = users
      ?.filter((user) => {
        const isPrincipalDeveloper = user.role === Role.PRINCIPAL_DEVELOPER;
        const isToBeDeleted = developersToDelete
          .map(({ userId }) => userId)
          .includes(user.user.userId);
        return isPrincipalDeveloper && !isToBeDeleted;
      })
      .map(({ user }) => {
        return { name: user.name || 'No name', value: user.userId };
      });
    options?.unshift({
      name: t('modals:deleteUsersModal:deleteWithoutTransferringKeys'),
      value: '0',
    });
    return options ?? [];
  }, [developersToDelete, t, users]);

  useEffect(() => {
    if (areAvailableKeysLoading) {
      setDisableConfirmButton(true);
      return;
    }

    const hasNotSelectedDestinationForApiKeys =
      keyTransferPairs.length !== numberOfDevelopersToDeleteWithKeys;
    const isDeletingAllDevelopers =
      availableDevelopersForKeyTransfer.length === 1;
    if (hasNotSelectedDestinationForApiKeys && !isDeletingAllDevelopers) {
      setDisableConfirmButton(true);
      return;
    }

    setDisableConfirmButton(false);
  }, [
    areAvailableKeysLoading,
    availableDevelopersForKeyTransfer.length,
    developersToDelete.length,
    keyTransferPairs.length,
    numberOfDevelopersToDelete,
    numberOfDevelopersToDeleteWithKeys,
    usersToDelete.length,
  ]);

  const transferKeysAndDeleteDisplayedUser = useCallback(() => {
    if (!numberOfUsersToDelete || !selectedPrincipal || !apiKeys) return;

    setDisableConfirmButton(true);

    keyTransferPairs.forEach(async (pair) => {
      if (pair.to === '0') return;

      const keysToTransfer = apiKeys.filter(({ userDetails }) => {
        return userDetails.firebaseId == pair.from;
      });

      const keysEmailData: ApiKeyTransferEmailItem[] = [];

      keysToTransfer.forEach(
        ({ keyId, projectName, userDetails, scopes, dateExpiry }) => {
          moveSubscription(keyId, pair.from, pair.to);

          keysEmailData.push({
            projectName: projectName,
            transferredFrom:
              userDetails.name ?? t('emails:apiKeyTransfer:unknownUser'),
            scopes: scopes.join(', ') || t('emails:apiKeyTransfer:noScopes'),
            expiryDate: formatStandardDate(dateExpiry) ?? t('labels:unknown'),
          });
        },
      );
    });

    const userIdsToDelete = usersToDelete.map((user) => user.userId);
    deleteUsers(userIdsToDelete, selectedPrincipal.id).then(
      ({ successfulDeletion, failedDeletion }) => {
        const successfulDeletionByName = successfulDeletion.map(
          (deletedUser) =>
            usersToDelete.find((user) => user.userId === deletedUser)?.name ||
            '',
        );
        setDeletedUserNames(successfulDeletionByName);
        if (failedDeletion.length > 0) {
          failedDeletion.forEach((userIdFailedToDelete) => {
            const failedDeletionByName = usersToDelete.find(
              (user) => user.userId === userIdFailedToDelete,
            )?.name;
            Notification({
              message: t('modals:deleteUsersModal:error', {
                undeletedUser: failedDeletionByName,
              }),
            });
          });
        }
        if (successfulDeletion.length > 0) {
          const isMulti = successfulDeletion.length > 1;
          Notification({
            message: t(
              `modals:deleteUsersModal:success:${isMulti ? 'multi' : 'single'}`,
              {
                data: isMulti
                  ? successfulDeletion.length
                  : successfulDeletionByName[0],
              },
            ),
          });
        }

        if (onComplete) {
          onComplete();
        }
      },
    );
  }, [
    apiKeys,
    keyTransferPairs,
    moveSubscription,
    numberOfUsersToDelete,
    onComplete,
    selectedPrincipal,
    t,
    usersToDelete,
  ]);

  return (
    <>
      {!!deletedUserNames.length && (
        <Navigate
          to={
            routes(selectedPrincipal.slug).find(MainFeatureType.PORTAL_MEMBERS)
              .url
          }
          state={{ deletedUserNames }}
        />
      )}
      <Modal
        heading={t(`modals:deleteUsersModal:title:${multiOrSingle}`)}
        confirmText={t(`modals:deleteUsersModal:confirm:${multiOrSingle}`)}
        onConfirm={transferKeysAndDeleteDisplayedUser}
        onCancel={onCancel}
        isConfirmButtonDisabled={disableConfirmButton}
        headingAlign="left"
        isBig
      >
        <div className="pt-1 text-left">
          <p className="mb-4">
            {t(
              `modals:deleteUsersModal:description:${currentUser.userType}:${multiOrSingle}`,
              {
                userNameOrNumber,
                selectedPrincipalName: selectedPrincipal.name,
              },
            )}
          </p>

          {currentUser.userType !== UserType.EXTERNAL && (
            <Alert
              message={t('modals:deleteUsersModal:info')}
              iconTop={true}
              flavour="warning"
              icon="InfoCircle"
              hasBorder={false}
              iconColor="orange-300"
            />
          )}
          <p className="text-grey-700 mb-1 mt-4 text-base font-bold">
            {t(
              `modals:deleteUsersModal:allUserCount:${
                numberOfUsersToDelete === 1 ? 'single' : 'multi'
              }`,
              {
                numberOfUsersToDelete,
              },
            )}
          </p>
          {numberOfNonDevelopersToDelete > 0 && (
            <>
              <p className="text-grey-700 mb-1 mt-4 text-base font-bold">
                {t(
                  `modals:deleteUsersModal:nonDeveloperUserCount:${
                    numberOfNonDevelopersToDelete === 1 ? 'single' : 'multi'
                  }`,
                  { numberOfNonDevelopersToDelete },
                )}
              </p>
              {nonDevelopersToDelete.map((user) => (
                <React.Fragment key={user.userId}>
                  <p className="text-grey-700 mb-1 text-base "> {user.name}</p>
                </React.Fragment>
              ))}
            </>
          )}
          {numberOfDevelopersToDelete > 0 && (
            <>
              <p className="text-grey-700 mb-1 mt-4 text-base font-bold">
                {t(
                  `modals:deleteUsersModal:developerUserCount:${
                    numberOfDevelopersToDelete === 1 ? 'single' : 'multi'
                  }`,
                  {
                    numberOfDevelopersToDelete,
                  },
                )}
              </p>
              <p className="text-grey-700 mb-4 text-base">
                {t('modals:deleteUsersModal:additionalDeveloperDescription')}
              </p>
              {areAvailableKeysLoading && <LoadingBounce />}
              {!areAvailableKeysLoading && (
                <ul>
                  {developersToDelete.filter(Boolean).map((dev) => (
                    <div
                      key={dev.userId}
                      className="text-grey-700 mb-4 grid grid-flow-col grid-cols-2 items-center text-base"
                    >
                      <div>{dev.name}:</div>
                      <div className="w-250px col-start-2 grid">
                        {developerHasKeys(dev.userId, apiKeys) ? (
                          <DropdownSingleSelect
                            onChange={(option) => {
                              if (
                                availableDevelopersForKeyTransfer.length === 1
                              )
                                return;
                              setKeyTransferPairs([
                                ...keyTransferPairs.filter(
                                  (item) => item.from !== dev.userId,
                                ),
                                {
                                  from: dev.userId,
                                  to: option.value.toString(),
                                },
                              ]);
                            }}
                            placeholder={t('placeholders:transferKeysTo')}
                            options={availableDevelopersForKeyTransfer}
                          />
                        ) : (
                          <div className="text-grey-600">
                            {t('modals:deleteUsersModal:noKeysToTransfer')}
                          </div>
                        )}
                      </div>

                      {keyTransferPairs.find((pair) => pair.from === dev.userId)
                        ?.to === '0' && (
                        <div className="col-start-2 row-start-2 -ml-2 -mt-1 grid">
                          <Alert
                            message={t(
                              'modals:deleteUsersModal:deleteUserWithoutTransferringKeysAlert',
                            )}
                            flavour="infoNoBg"
                            icon="InfoCircle"
                            hasBorder={false}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </ul>
              )}
            </>
          )}
        </div>
      </Modal>
    </>
  );
};
