import { Alert } from 'components/Alert';
import { Heading } from 'components/Heading';
import { Link } from 'components/Link';
import { routes } from 'controllers/ContentController/Routes';
import { ContactFormModal } from 'features/ContactFormModal/ContactFormModal';
import { useCurrentUser, usePrincipals } from 'hooks/queries';
import { getCurrentPrincipal } from 'lib/currentPrincipal';
import { ContactFormData } from 'shared/types/ContactFormData';
import { Notification } from 'lib/notifications/notifications';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MainFeatureType } from 'shared/types/features/FeatureIds';
import { postPrincipalEdi } from '../../utils/principal-service/principal-endpoints';

export const EDIInformation: React.FC = () => {
  const { user } = useCurrentUser();
  const { data: principals } = usePrincipals();
  const { currentPrincipal } = getCurrentPrincipal(user.userId, principals);
  const dataConnectionRoute = routes(currentPrincipal.slug).find(
    MainFeatureType.DATA_CONNECTIONS,
  );
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showContactFormModal, setShowContactFormModal] = useState(false);

  const onModalConfirmHandler = async (contactFormData: ContactFormData) => {
    setShowContactFormModal(false);
    try {
      const selectedInterests = contactFormData.ediProcess.other
        ? [
            ...contactFormData.ediProcess.selected,
            contactFormData.ediProcess.other,
          ]
        : contactFormData.ediProcess.selected;
      await postPrincipalEdi(currentPrincipal.id, {
        additionalInformation: contactFormData.additionalInformation,
        contactPersons: contactFormData.technicalTeams,
        countries: contactFormData.countries,
        selectedInterests,
      });
      Notification({
        message: t('modals:contactFormModal:notification:success'),
      });
    } catch (error) {
      Notification({
        message: t('modals:contactFormModal:notification:failed'),
      });
    }
  };

  return (
    <>
      <div className="max-w-xl-content xl:min-w-content mx-auto px-4 md:px-6">
        <div className="mb-0 pt-6 md:pt-7">
          <div className="mb-3">
            <Link
              label={t('linksOrButtons:back')}
              icon="ArrowLeft"
              iconPosition="left"
              onClick={() => navigate(dataConnectionRoute.url)}
            />
          </div>
        </div>

        <div className="mb-5 mt-6" data-test="page-header">
          <Heading
            text={t('features:data-connection:ediInfo:ediPage:title')}
            margin={0}
            level="h1"
          />
        </div>

        <div className="mb-7 flex flex-row">
          <Alert
            message={t('features:data-connection:ediInfo:ediPage:description')}
            flavour="description"
            icon="Support"
            hasBorder={false}
            iconSize="large"
            buttonLabel={t(
              'features:data-connection:ediInfo:ediPage:getStarted',
            )}
            onClick={() => setShowContactFormModal(true)}
          />
        </div>

        <div className="mb-7">
          <p className="mb-4">
            {t('features:data-connection:ediInfo:ediPage:entrySentence')}
          </p>
          <p className="font-semibold">
            {t('features:data-connection:ediInfo:ediPage:whatisEDI:headline')}
          </p>
          <p>
            {t('features:data-connection:ediInfo:ediPage:whatisEDI:content')}
          </p>
          <p className="mt-4 font-semibold">
            {t(
              'features:data-connection:ediInfo:ediPage:benefitsofEDI:headline',
            )}
          </p>
          <p>
            {t(
              'features:data-connection:ediInfo:ediPage:benefitsofEDI:content',
            )}
          </p>
          <ul className="list-disc pl-4 pt-1">
            <li>
              {t(
                'features:data-connection:ediInfo:ediPage:benefitsofEDI:bulletPoint1',
              )}
            </li>
            <li>
              {t(
                'features:data-connection:ediInfo:ediPage:benefitsofEDI:bulletPoint2',
              )}
            </li>
            <li>
              {t(
                'features:data-connection:ediInfo:ediPage:benefitsofEDI:bulletPoint3',
              )}
            </li>
          </ul>
          <p className="mt-4 font-semibold">
            {t(
              'features:data-connection:ediInfo:ediPage:currentEDICapabilities:headline',
            )}
          </p>
          <p>
            {t(
              'features:data-connection:ediInfo:ediPage:currentEDICapabilities:content',
            )}
          </p>

          <ul className="list-disc pl-4 pt-1">
            <li>
              {t(
                'features:data-connection:ediInfo:ediPage:currentEDICapabilities:bulletPoint1',
              )}
            </li>
            <li>
              {t(
                'features:data-connection:ediInfo:ediPage:currentEDICapabilities:bulletPoint2',
              )}
            </li>
          </ul>

          <p className="mt-4">
            {t('features:data-connection:ediInfo:ediPage:exitSentence')}
          </p>
        </div>
      </div>
      {showContactFormModal && (
        <ContactFormModal
          onCancel={() => setShowContactFormModal(false)}
          onSendContactForm={onModalConfirmHandler}
        />
      )}
    </>
  );
};
