import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getCurrentPrincipal } from 'lib/currentPrincipal';
import { useCallback, useMemo } from 'react';
import { Role } from 'shared/types/authorization';
import { extractRoleFromUser } from 'utils/authorization.utils';
import { UserData } from '../../utils/firebase/collection';
import {
  getCurrentUser,
  updateCurrentUser,
} from '../../utils/principal-service/user-endpoints';
import { useAuth } from './useAuth';
import { usePrincipals } from './usePrincipals';
import { useRoleDefinitions } from './useRoleDefinitions';
import { PortalUser, UseUserResult } from './useUser';

export type UseCurrentUserResult = UseUserResult;

export const useCurrentUser = (): UseCurrentUserResult => {
  const auth = useAuth();
  const queryClient = useQueryClient();

  const roleDefinitions = useRoleDefinitions();

  const { data: user } = useQuery({
    queryFn: getCurrentUser,
    queryKey: ['currentUser'],
    initialData: { acceptedTermsOfUse: new Date() } as UserData,
    select: (data) => ({ ...data, userId: auth?.uid }),
  });
  const { data: principals } = usePrincipals();
  const { currentPrincipal } = getCurrentPrincipal(user.userId, principals);

  const update = useCallback(
    async (user: Partial<PortalUser>) => {
      await updateCurrentUser(user);
      queryClient.invalidateQueries({
        queryKey: ['currentUser'],
      });
    },
    [queryClient],
  );

  return useMemo(() => {
    const role = extractRoleFromUser(currentPrincipal.id, user) ?? Role.NONE;
    const userType = roleDefinitions[role]?.user_type;
    const isGlobalUser = !!user.global_user;

    const currentUser = { user, role, isGlobalUser, update, userType };
    return { ...currentUser };
  }, [currentPrincipal.id, user, roleDefinitions, update]);
};
