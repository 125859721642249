import axios from 'axios';
import { getFirebaseBearerToken } from '../../lib/getFirebaseBearerToken';

export const getOperationalPerformanceTabsConfiguration = async () => {
  const bearerToken = await getFirebaseBearerToken();
  return await axios({
    url: `${process.env.REACT_APP_PORTAL_SERVICE_BASE_URL}/configuration/operational-performance-tabs`,
    method: 'Get',
    headers: {
      Authorization: `Bearer ${bearerToken}`,
    },
  });
};
