import { Checkbox } from 'components/Checkbox';
import { EmptyState } from 'components/EmptyState';
import { LoadingBounce } from 'components/Loading';
import { Table } from 'components/Table/Table';
import { TableBody } from 'components/Table/TableBody';
import { TableCell } from 'components/Table/TableCell';
import { TableHeader } from 'components/Table/TableHeader';
import { TableRow } from 'components/Table/TableRow';
import { GlobalUserTableColumn } from 'components/UserManagementModals';
import { ToggleUsersToDeleteFunc } from 'features/PortalMembers/PortalMembers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SortingConfig } from 'shared/types/SortingConfig';
import { UserData } from 'utils/firebase/collection/firebase-collection-types';
import { GlobalUserTableRow } from './GlobalUserTableRow';

interface GlobalUserTableContentProps {
  filteredUsers: UserData[] | undefined;
  selectedUser?: UserData | undefined;
  userIdsToDelete?: string[];
  isExistingGlobalUsersTable?: boolean;
  sortConfig: SortingConfig<GlobalUserTableColumn>;
  toggleUsersToDelete?: ToggleUsersToDeleteFunc;
  toggleEditPrincipalsModal?: () => void;
  handleSortingChange: (targetColumn: GlobalUserTableColumn) => void;
  handleSelectUser: (user: UserData) => void;
}
const sortOrderToShow = (
  targetColumn: string,
  { column, asc }: SortingConfig<GlobalUserTableColumn>,
): 'asc' | 'desc' | undefined => {
  if (column !== targetColumn) return;
  return asc ? 'asc' : 'desc';
};

type TableHeaderCellProps = {
  type: GlobalUserTableColumn;
  sortConfig: SortingConfig<GlobalUserTableColumn>;
  handleSortingChange: (targetColumn: GlobalUserTableColumn) => void;
};

const TableHeaderCell = ({
  type,
  sortConfig,
  handleSortingChange,
}: TableHeaderCellProps) => {
  const { t } = useTranslation();

  return (
    <TableCell
      noWrap
      displaySortingButton
      sortOrder={sortOrderToShow(type, sortConfig)}
      onClick={() => {
        handleSortingChange(type);
      }}
    >
      {t(`features:global-users:createGlobalUsers:${type}`)}
    </TableCell>
  );
};

export const GlobalUserTableContent: React.FC<GlobalUserTableContentProps> = ({
  filteredUsers,
  selectedUser,
  userIdsToDelete,
  sortConfig,
  isExistingGlobalUsersTable,
  toggleUsersToDelete,
  toggleEditPrincipalsModal,
  handleSortingChange,
  handleSelectUser,
}: GlobalUserTableContentProps) => {
  const { t } = useTranslation();

  if (!filteredUsers) {
    return <LoadingBounce />;
  }

  if (filteredUsers.length === 0) {
    return (
      <div className="my-6">
        <EmptyState
          message={t('features:portal-members:labels:noResults:users')}
          hasBorder
        />
      </div>
    );
  }

  const hasFilteredUsersAndUserIdsToDelete =
    !!filteredUsers?.length && !!userIdsToDelete?.length;

  const filteredUsersMinusCurrentUserEqualsUsersToDelete =
    filteredUsers.length === userIdsToDelete?.length;

  const selectAllCheckboxIsIntermediate =
    hasFilteredUsersAndUserIdsToDelete &&
    !filteredUsersMinusCurrentUserEqualsUsersToDelete;

  const selectAllCheckboxIsChecked =
    hasFilteredUsersAndUserIdsToDelete &&
    filteredUsersMinusCurrentUserEqualsUsersToDelete;

  return (
    <Table>
      <TableHeader>
        <TableRow darkBorder>
          {isExistingGlobalUsersTable && (
            <TableCell smallHeaderTable customYPadding>
              <Checkbox
                onChange={(isChecked) =>
                  toggleUsersToDelete &&
                  toggleUsersToDelete(
                    filteredUsers.map((user) => user?.userId),
                    isChecked,
                  )
                }
                initialChecked={selectAllCheckboxIsChecked}
                displayAsIntermediate={selectAllCheckboxIsIntermediate}
              />
            </TableCell>
          )}
          <TableHeaderCell
            type={GlobalUserTableColumn.NAME}
            handleSortingChange={handleSortingChange}
            sortConfig={sortConfig}
          />
        </TableRow>
      </TableHeader>
      <TableBody>
        {filteredUsers.map((user: UserData, index: number) => (
          <GlobalUserTableRow
            isExistingGlobalUsersTable={isExistingGlobalUsersTable}
            key={index}
            user={user}
            userIdsToDelete={userIdsToDelete}
            handleSelect={handleSelectUser}
            toggleUsersToDelete={toggleUsersToDelete}
            toggleEditPrincipalsModal={toggleEditPrincipalsModal}
            selectedUser={selectedUser}
          />
        ))}
      </TableBody>
    </Table>
  );
};
