import { useQuery } from '@tanstack/react-query';
import { getPrincipalFeatures } from '../utils/principal-service/principal-endpoints';

export interface SubFeature {
  id: string;
  maintenanceMode: boolean;
  visibilityOrder: number;
}

export interface MainFeature {
  id: string;
  isMaintenance: boolean;
  permissions: string[];
  subFeatures?: SubFeature[];
}

export interface FeatureResponse {
  features: MainFeature[];
}

export const usePrincipalFeatures = (principalId: string) => {
  const { data: principalFeatures } = useQuery({
    queryFn: () => getPrincipalFeatures(principalId),
    queryKey: ['principalFeatureAccessRights'],
    select: ({ data }) => data.features,
  });
  return { data: principalFeatures };
};
