import { DropdownOptionProps } from 'components/Dropdown/helper/DropdownTypes';
import { TFunction } from 'i18next';
import { models } from 'powerbi-client';
import { MouseEvent } from 'react';
import { Mandate } from '../../../functions/src/types/crm';
import '../i18n';

export const stripHTMLTags = (html: string): string => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
};

export const isValidHttpUrl = (string: string): boolean => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const translationsAsNameValueOptions = (
  t: TFunction,
  translationKey: string,
): DropdownOptionProps[] => {
  const translations: string[] = t(translationKey, {
    returnObjects: true,
  });
  return translations.map((item, index) => ({
    name: item,
    value: index,
  })) as DropdownOptionProps[];
};

export const mapStringToNameValueOption = (
  string: string | undefined,
  nameValueOptions: DropdownOptionProps[],
): DropdownOptionProps | undefined => {
  return searchStringInOptions(string, nameValueOptions);
};

export function searchStringInOptions(
  name: string | undefined,
  nameValueOptionsArray: DropdownOptionProps[],
): DropdownOptionProps | undefined {
  for (const nameValueOpt of nameValueOptionsArray) {
    if (nameValueOpt.name === name) {
      return nameValueOpt;
    }
  }
  return undefined;
}

export function checkIfClickEventWasOnAnchor(
  event: MouseEvent,
  recursion = 1,
): boolean {
  return checkParentForAnchor(
    event.nativeEvent.target as HTMLElement,
    recursion,
    0,
  );
}

export function checkParentForAnchor(
  element: HTMLElement,
  recursion: number,
  level: number,
): boolean {
  const currentParent = element.parentElement;
  if (currentParent?.tagName === 'A') {
    return true;
  } else if (currentParent && recursion > level) {
    return checkParentForAnchor(currentParent, recursion, level + 1);
  } else {
    return false;
  }
}

export function shortenEnvironmentName(environment?: string): string {
  switch (environment) {
    case 'production': {
      return 'prod';
    }
    case 'staging': {
      return 'staging';
    }
    default:
      return 'dev';
  }
}

export function isTimestampOlderThanOneHour(timestamp: number): boolean {
  const ONE_HOUR_IN_MILLISECONDS = 3600000;
  return new Date().getTime() - timestamp > ONE_HOUR_IN_MILLISECONDS;
}

export const formatStandardDate = (
  creationTime: string | undefined,
): string | undefined => {
  if (!creationTime) return;
  const creationDate = new Date(creationTime);
  return `${creationDate.getDate()}/${
    creationDate.getMonth() + 1
  }/${creationDate.getFullYear()}`;
};

//TODO: refactor email validation (maybe a package like ZOD)
export const validateEmailAddress = (email: string): boolean => {
  const emailAtCharIndex = email.indexOf('@');
  const hasContentBeforeAtChar = emailAtCharIndex > 0;
  const hasContentAfterAtChar = emailAtCharIndex < email.length - 1;
  return hasContentBeforeAtChar && hasContentAfterAtChar;

  /**
   * This function used to use the following regex:
   * return /^[.\S]+@[.\S]+$/g.test(email);
   * But SonarCloud tell us to:
   * "Make sure the regex used here, which is vulnerable to super-linear runtime due to backtracking, cannot lead to denial of service."
   */
};

export const isObjectValuesFilled = (
  objectToValidate: Record<
    string,
    | Record<
        string,
        | string
        | Record<string, string | string[] | boolean | undefined>
        | undefined
      >
    | string
    | undefined
  >,
): boolean => {
  let valid = true;
  Object.values(objectToValidate).forEach((val) => {
    if (val === undefined) valid = false;
    if (typeof val === 'string') {
      if (val.length === 0) valid = false;
    } else if (typeof val === 'object') {
      Object.values(val).forEach((newVal) => {
        if (typeof newVal === 'undefined') {
          valid = false;
        } else {
          if (newVal.length === 0) valid = false;
        }
      });
    }
  });
  return valid;
};

type MarketSegmentWithId = {
  name: string;
  id: string;
};

export const removeStringArrayDuplicates = (array: string[]): string[] => {
  return [...new Set(array.filter(Boolean))].flat();
};

export const removeMarketSegmentDuplicates = (
  array: MarketSegmentWithId[],
): MarketSegmentWithId[] => {
  const check = new Set();
  return array.filter((obj) => !check.has(obj['id']) && check.add(obj['id']));
};

export const removePortfolioDuplicates = (
  array: DropdownOptionProps[],
): DropdownOptionProps[] => {
  const check = new Set();
  return array.filter(
    (obj) => !check.has(obj['value']) && check.add(obj['value']),
  );
};

export const getPortfoliosWithIds = (
  m: Mandate,
  portfolioLevel: number | undefined,
): DropdownOptionProps => {
  if (portfolioLevel === 1) {
    return {
      name: m.aze_PortfolioId?.aze_name || '',
      value: m.aze_PortfolioId?.aze_portfolio1id || '',
    };
  }
  if (
    m.aze_PortfolioId?.aze_portfolio1id &&
    m.aze_Portfolio2Id?.aze_portfolio2id
  ) {
    return {
      name: `${m.aze_PortfolioId.aze_name} / ${m.aze_Portfolio2Id.aze_name}`,
      value: `${m.aze_PortfolioId.aze_portfolio1id} / ${m.aze_Portfolio2Id.aze_portfolio2id}`,
    };
  } else {
    return {
      name: `${m.aze_PortfolioId?.aze_name || m.aze_Portfolio2Id?.aze_name}`,
      value: `${
        m.aze_PortfolioId?.aze_portfolio1id ||
        m.aze_Portfolio2Id?.aze_portfolio2id
      }`,
    };
  }
};

export const extractAvailableMarketSegments = (
  mandates: Mandate[] | undefined,
): MarketSegmentWithId[] =>
  mandates?.length
    ? removeMarketSegmentDuplicates(
        mandates.map((m: Mandate) => ({
          name: m.aze_MarketSegmentId?.aze_name,
          id: m.aze_MarketSegmentId?.aze_code,
        })),
      ).sort(({ name: nameA }, { name: nameB }) => nameA.localeCompare(nameB))
    : [];

export const extractAvailableMarketSegmentsAsStrings = (
  mandates: Mandate[],
): string[] =>
  mandates?.length
    ? removeStringArrayDuplicates(
        mandates.map((m: Mandate) => m.aze_MarketSegmentId?.aze_name),
      )
    : [];

export const extractAvailablePortfoliosWithId = (
  mandates: Mandate[] | undefined,
  portfolioLevel: number,
): DropdownOptionProps[] => {
  const portfoliosUnfiltered = mandates?.length
    ? mandates
        .map((m: Mandate) => getPortfoliosWithIds(m, portfolioLevel))
        .sort()
    : [];

  return removePortfolioDuplicates(portfoliosUnfiltered);
};

export const isSafari = (): boolean => {
  let selectedBrowser = '';
  const userAgent = navigator.userAgent;
  // I know this looks weird but this is the recommended way by MDN.
  // For futher insights here is a link https://developer.mozilla.org/en-US/docs/Web/API/Window/navigator
  // The order matters here, and this may report false positives for unlisted browsers.
  if (userAgent.indexOf('Firefox') > -1) selectedBrowser = 'Mozilla Firefox';
  else if (userAgent.indexOf('SamsungBrowser') > -1)
    selectedBrowser = 'Samsung Internet';
  else if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1)
    selectedBrowser = 'Opera';
  else if (userAgent.indexOf('Trident') > -1)
    selectedBrowser = 'Microsoft Internet Explorer';
  else if (userAgent.indexOf('Edge') > -1) selectedBrowser = 'Microsoft Edge';
  else if (userAgent.indexOf('Chrome') > -1)
    selectedBrowser = 'Google Chrome or Chromium';
  else if (userAgent.indexOf('Safari') > -1) selectedBrowser = 'Apple Safari';
  else selectedBrowser = 'unknown';

  return selectedBrowser === 'Apple Safari';
};

export const generateFilters = (
  tableName: string,
  columnName: string,
  values: string[],
): models.IBasicFilter & {
  target: {
    table: string;
    column: string; // Market Segment Code
  };
  values: string[];
} => {
  return {
    target: {
      table: tableName,
      column: columnName, // Market Segment Code
    },
    operator: 'In',
    values: values, // Personal Care
    filterType: 1,
    $schema: 'http://powerbi.com/product/schema#basic',
  };
};

export const areStringArraysEqual = (
  array1: string[],
  array2: string[],
): boolean => {
  return (
    array1.every((item) => array2.includes(item)) &&
    array2.every((item) => array1.includes(item))
  );
};
