import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import { getCurrentPrincipal } from '../../lib/currentPrincipal';
import { getFirebaseData } from './useApiKeys';
import { useCurrentUser } from './useCurrentUser';
import { usePrincipals } from './usePrincipals';

export type Scope = {
  name: string;
  description: string;
  active: boolean;
};

export const useScopes = (): UseQueryResult<Scope[], Error> => {
  const { user: currentUser } = useCurrentUser();
  const { data: principals } = usePrincipals();
  const { currentPrincipal } = getCurrentPrincipal(
    currentUser.userId,
    principals,
  );

  return useQuery({
    queryFn: async () => {
      const token = (await getFirebaseData())?.bearerToken;
      return await axios({
        url: `${process.env.REACT_APP_PORTAL_SERVICE_BASE_URL}/scopes?principalId=${currentPrincipal.id}`,
        method: 'Get',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    queryKey: ['scopes'],
    select: ({ data }) => data.scopes,
    enabled: true,
    refetchOnWindowFocus: false,
  });
};
