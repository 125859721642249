import { CountryTile } from 'components/CountryTile';
import { ExpansionOpportunity } from 'components/ExpansionOpportunity';
import { Heading } from 'components/Heading';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { type ExpansionOpportunity as ExpansionOpportunityType } from 'utils/principal-service/principal-endpoints.types';

type CountryModalExpansionProps = {
  expansionOpportunities: ExpansionOpportunityType[];
  hasMandates?: boolean;
};

export const Expansion: React.FC<CountryModalExpansionProps> = ({
  expansionOpportunities,
  hasMandates,
}: CountryModalExpansionProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Heading
        level="h3"
        text={t('modals:countryModal:potentialForCollaboration')}
      />
      {expansionOpportunities.map((expOpp, index) =>
        expOpp.portfolio?.name ? (
          <CountryTile
            key={index}
            headline={t(
              `modals:countryModal:${
                hasMandates
                  ? 'consolidationOpportunity'
                  : 'newCountryOpportunity'
              }`,
            )}
          >
            <ExpansionOpportunity
              principalPortfolio={expOpp.portfolio?.name}
              portfolioFit={+(expOpp.ratings.portfolioFit ?? 0)}
              salesTeamStrength={+(expOpp.ratings.salesTeamStrength ?? 0)}
              labSupportResources={+(expOpp.ratings.labSupportResources ?? 0)}
              marketSegmentName={expOpp.marketSegment?.name}
              border={false}
              noPadding={true}
              maxRationaleLinesToDisplay={4}
            />
          </CountryTile>
        ) : null,
      )}
    </>
  );
};
