import { useMsal } from '@azure/msal-react';
import { getCurrentPrincipal } from 'lib/currentPrincipal';
import {
  getLastSharepointUpdatesForFolder,
  initSharePointConnection,
} from 'lib/sharepoint';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setSharepointState } from 'store/sharepoint/sharepoints.action';
import { useAuth, useCurrentUser, usePrincipals } from './queries';

export const useNewSharedDocumentsCount = (): number => {
  const auth = useAuth();
  const { user, update: updateUser } = useCurrentUser();
  const { data: principals } = usePrincipals();
  const { currentPrincipal } = getCurrentPrincipal(user.userId, principals);
  const { t } = useTranslation();
  const [newSharedDocumentsCount, setNewSharedDocumentsCount] =
    useState<number>(0);
  const sharePointDriveId = process.env.REACT_APP_SHAREPOINT_DRIVE_ID as string;
  const { instance } = useMsal();
  const MINUTE_MS = 60000;

  const client = initSharePointConnection(instance);
  const dispatch = useDispatch();
  // Time used for FileCheck Comparsion, when no lastNewFileCheck is available
  const initializationTime = Date.now();

  const [
    lastTimeCheckedNewSharedDocuments,
    setLastTimeCheckedNewSharedDocuments,
  ] = useState<number>(0);

  const checkForFileUpdates = useCallback(() => {
    const driveId = currentPrincipal.sharepointDriveId;
    if (
      driveId &&
      lastTimeCheckedNewSharedDocuments < Date.now() - MINUTE_MS + 1000 &&
      sharePointDriveId
    ) {
      setLastTimeCheckedNewSharedDocuments(Date.now());
      const lastCheckTimestamp = user?.lastNewFileCheck
        ? new Date(user.lastNewFileCheck).getTime()
        : Date.now();
      // use lastCheckTimestamp only after Login, after that use initalizationTime to see what change during session
      getLastSharepointUpdatesForFolder(
        client,
        sharePointDriveId,
        driveId,
        lastCheckTimestamp < initializationTime
          ? lastCheckTimestamp
          : initializationTime,
        t,
      ).then((result) => {
        setNewSharedDocumentsCount(result.updates);
        dispatch(setSharepointState(result));
        if (auth.uid) {
          updateUser({
            lastNewFileCheck: new Date().toISOString(),
          });
        }
      });
    }
  }, [
    auth.uid,
    client,
    currentPrincipal.sharepointDriveId,
    dispatch,
    initializationTime,
    lastTimeCheckedNewSharedDocuments,
    sharePointDriveId,
    t,
    updateUser,
    user.lastNewFileCheck,
  ]);

  useEffect(() => {
    checkForFileUpdates();
    const interval = setInterval(() => {
      checkForFileUpdates();
    }, MINUTE_MS);
    return () => clearInterval(interval); // The clear function is supposed to be called on unmount
  }, [checkForFileUpdates]);

  return newSharedDocumentsCount;
};
