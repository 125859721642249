import { useQuery } from '@tanstack/react-query';
import { Role } from 'shared/types/authorization';
import { RoleDefinition } from 'utils/firebase/collection';
import { getLegacyRoleDefinitions } from '../../utils/principal-service/legacy-role-definition-endpoints';

export type RoleDefinitions = {
  [key in Role]: RoleDefinition;
};

export function useRoleDefinitions(): RoleDefinitions {
  const { data: legacyRoleDefinitions } = useQuery({
    queryFn: getLegacyRoleDefinitions,
    queryKey: ['legacy-role-definitions'],
    initialData: {} as RoleDefinitions,
  });
  return legacyRoleDefinitions;
}
