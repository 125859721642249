import { Alert } from 'components/Alert';
import { DropdownFilter } from 'components/Dropdown/DropdownVariants/DropdownFilter';
import { DropdownOptionProps } from 'components/Dropdown/helper/DropdownTypes';
import { ExpansionOpportunityList } from 'components/ExpansionOpportunity';
import { Heading } from 'components/Heading';
import { LoadingBounce } from 'components/Loading';
import { CountryClickEvent, Map } from 'components/Map/Map';
import { MarketInsight } from 'components/MarketInsight';
import { Tile } from 'components/Tile';
import { routes } from 'controllers/ContentController/Routes';
import { CountryModal } from 'features/CountryModal/CountryModal';
import {
  CountryWithColor,
  isMandate,
  Mandate,
  useCRMCountriesData,
  useCRMMandates,
  useCRMMarketInsights,
  useCurrentUser,
  useExpansionOpportunities,
  useMarketInsights,
} from 'hooks/queries';
import { usePermission } from 'hooks/usePermission';
import i18n from 'i18n';
import { orderBy } from 'lodash';
import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Permission } from 'shared/types/authorization';
import {
  MainFeatureType,
  SubFeatureType,
} from 'shared/types/features/FeatureIds';
import {
  FilterByPrincipal,
  Principal,
} from 'utils/firebase/collection/firebase-collection-types';
import {
  type Country,
  type ExpansionOpportunity,
} from 'utils/principal-service/principal-endpoints.types';
import { DashboardTile } from './DashboardTile';
import { MapName } from './MapConfigs';

type DashboardMapProps = {
  selectedPrincipal: Principal;
};

export type FilterProps = {
  marketSegmentFilter?: FilterValues;
  portfolioFilter?: FilterValues;
};

type FilterValues = string[];

export type CountryProps = {
  ISO_A3: string;
  name: string;
};

export const filterForBothPortfolios = (
  value: Mandate | ExpansionOpportunity,
  filter: string,
  portfolioLevel: number,
): boolean => {
  let portfolioid: string | undefined;
  let portfolio2id: string | undefined;

  if (isMandate(value)) {
    portfolioid = value.aze_PortfolioId?.aze_portfolio1id;
    portfolio2id = value.aze_Portfolio2Id?.aze_portfolio2id;
  } else {
    portfolioid = value.portfolio?.id;
    portfolio2id = value.portfolio2?.id;
  }

  const filterEqualsPortfolios = filter === `${portfolioid} / ${portfolio2id}`;

  if (filterEqualsPortfolios) {
    return true;
  }

  if (filter === portfolioid) {
    if (portfolioLevel === 1) {
      return true;
    }

    if (!portfolio2id) {
      return true;
    }
  }

  return false;
};

export const filterForPortfolios = (
  value: Mandate | ExpansionOpportunity,
  portfolioFilters: FilterValues | undefined,
  portfolioLevel: number,
): number | boolean | undefined => {
  return (
    portfolioFilters?.length &&
    portfolioFilters.some((filter) => {
      return filterForBothPortfolios(value, filter, portfolioLevel);
    })
  );
};

export const filterForMarketSegments = (
  mandate: Mandate | ExpansionOpportunity,
  marketSegmentFilters: FilterValues | undefined,
): boolean | undefined => {
  if (isMandate(mandate)) {
    return marketSegmentFilters?.some(
      (filter): boolean => filter === mandate.marketSegment?.aze_code,
    );
  } else {
    return marketSegmentFilters?.some(
      (filter): boolean => filter === mandate.marketSegment?.code,
    );
  }
};

export const filterExpansionOpportunities = (
  opportunities: ExpansionOpportunity[],
  filters: FilterProps | undefined,
  portfolioLevel: number,
): ExpansionOpportunity[] => {
  return opportunities.filter(
    (opportunity) =>
      filterForMarketSegments(opportunity, filters?.marketSegmentFilter) &&
      filterForPortfolios(
        opportunity,
        filters?.portfolioFilter,
        portfolioLevel,
      ),
  );
};

const LEGEND_COLORS = {
  mandate: {
    name: i18n.t('features:dashboard:map:legend:mandates'),
    color: 'bg-blue-200',
  },
  opportunities: {
    name: i18n.t('features:dashboard:map:legend:opportunities'),
    color: 'bg-purple-100',
  },
  consolidation: {
    name: i18n.t('features:dashboard:map:legend:consolidation'),
    color: 'bg-teal-100',
  },
  azelisCountry: {
    name: i18n.t('features:dashboard:map:legend:azelis'),
    color: 'bg-blue-240',
  },
};

const getCountriesForMap = (
  countries: Country[],
  color?: keyof typeof LEGEND_COLORS,
) =>
  countries.map((m) => ({
    id: m.id,
    ISO: m.iso,
    colorGroup: color ? LEGEND_COLORS[color] : LEGEND_COLORS.azelisCountry,
  }));

const principalsWithAlertMessageEmeaMandatesOnly = ['CP Kelco'];

export const DashboardMap: React.FC<DashboardMapProps> = ({
  selectedPrincipal,
}) => {
  const [mandateFilter, setMandateFilter] = useState<FilterProps>();
  const [isValidAzelisCountry, setIsValidAzelisCountry] = useState(false);
  const [selectedCountryISO, setSelectedCountryISO] = useState<CountryProps>();
  const [filteredExpansionOpportunities, setFilteredExpansionOpportunities] =
    useState<ExpansionOpportunity[]>([]);
  const [isCountryModalHidden, setIsCountryModalHidden] =
    useState<boolean>(true);

  const { t } = useTranslation();
  const { userHasPermission } = usePermission();
  const { user, update: updateUser } = useCurrentUser();

  const { id: selectedPrincipalId, slug, portfolioLevel } = selectedPrincipal;

  const marketIntelligenceRoute = routes(slug).find(
    MainFeatureType.MARKET_INTELLIGENCE,
  );
  const navigate = useNavigate();

  const { data: mandates, isLoading: isLoadingMandates } = useCRMMandates({
    principalPri: selectedPrincipalId,
  });

  const { data: filteredMandates, isLoading: isLoadingFilteredMandates } =
    useCRMMandates(
      {
        filter: {
          marketSegments: mandateFilter?.marketSegmentFilter,
          portfolios: mandateFilter?.portfolioFilter,
        },
        principalPri: selectedPrincipalId,
      },
      true,
    );

  const { data: crmCountriesData, isLoading: isCRMCountriesDataLoading } =
    useCRMCountriesData();

  const azelisCountriesCodes =
    crmCountriesData?.map((country) => ({
      ISO: country.country.ISO3 || '',
      colorGroup: { name: 'Azelis countries', color: 'bg-blue-240' },
    })) ?? [];

  const { data: expansionOpportunities } = useExpansionOpportunities({
    principalId: selectedPrincipalId,
  });

  const userMapFilters = useMemo(() => user?.mapFilters || {}, [user]);

  const hasExpansionOpportunitiesPermissions = userHasPermission(
    Permission.EXPANSION_OPPORTUNITIES_VIEW,
  );
  const hasMarketInsightsPermissions = userHasPermission(
    Permission.MARKET_INSIGHTS_VIEW,
  );

  const [selectedMapName, setSelectedMapName] = useState<MapName>(
    MapName.WORLD,
  );

  const mapNameDropdownOptions: DropdownOptionProps[] = useMemo(
    () =>
      Object.keys(MapName)
        .map((mapName) => ({
          name: t(`features:dashboard:map:mapNames:${mapName}`),
          value: mapName,
        }))
        .sort((a, b) => {
          if (a.value == MapName.WORLD) return -1;
          if (b.value == MapName.WORLD) return 1;
          return a.name.localeCompare(b.name);
        }),
    [t],
  );

  const availableCountries = useMemo(
    () => mandates?.countries ?? [],
    [mandates],
  );

  const availableMarketSegmentOptions = useMemo(
    () =>
      mandates?.marketSegments.map(({ name, code }) => ({
        name,
        value: code,
      })) ?? [],
    [mandates],
  );

  const availablePortfolioOptions = useMemo(
    () =>
      mandates?.portfolios.map(({ name, id }) => ({
        name,
        value: id,
      })) ?? [],
    [mandates],
  );

  // Only take market segments that are valid from the current mandates.
  // This solves the problem of old filter values on the user record, that are no longer in the mandates.
  const selectedMarketSegmentOptions =
    userMapFilters[selectedPrincipalId]?.marketSegmentFilter &&
    availableMarketSegmentOptions.filter(({ value }) =>
      userMapFilters[selectedPrincipalId]?.marketSegmentFilter?.includes(value),
    );

  // Only take portfolios that are valid from the current mandates.
  // This solves the problem of old filter values on tbe user record, that are no longer in the mandates.
  const selectedPortfolioOptions =
    userMapFilters[selectedPrincipalId]?.portfolioFilter &&
    availablePortfolioOptions?.filter(({ value }) =>
      userMapFilters[selectedPrincipalId].portfolioFilter?.includes(
        value as string,
      ),
    );

  useEffect(() => {
    const filters = {
      marketSegmentFilter: userMapFilters[selectedPrincipalId]
        ?.marketSegmentFilter
        ? userMapFilters[selectedPrincipalId]?.marketSegmentFilter
        : availableMarketSegmentOptions.map(({ value }) => value),
      portfolioFilter: userMapFilters[selectedPrincipalId]?.portfolioFilter
        ? userMapFilters[selectedPrincipalId]?.portfolioFilter
        : availablePortfolioOptions?.map(({ value }) => value),
    };

    setMandateFilter(filters);

    setFilteredExpansionOpportunities(
      filterExpansionOpportunities(
        expansionOpportunities || [],
        filters,
        portfolioLevel,
      ),
    );
  }, [
    availableMarketSegmentOptions,
    availablePortfolioOptions,
    mandates,
    expansionOpportunities,
    portfolioLevel,
    selectedPrincipalId,
    userMapFilters,
  ]);

  const filteredCountriesArray = getCountriesForMap(
    filteredMandates?.countries ?? [],
    'mandate',
  );

  const expansionOpportunitiesForMap = filteredExpansionOpportunities.map(
    (eo: ExpansionOpportunity) => {
      return {
        ISO: eo.country.iso ?? '',
        // is country in mandates?
        colorGroup: mandates?.countries.find(
          (item) => item.iso === eo.country.iso,
        )
          ? LEGEND_COLORS.consolidation
          : LEGEND_COLORS.opportunities,
      };
    },
  );

  // Ensure that all countries of the selected principal are in azelis countries
  const allCountries: CountryWithColor[] = [
    ...azelisCountriesCodes,
    ...getCountriesForMap(availableCountries, 'azelisCountry'),
  ];

  // Combine the countries of selected principal with all azelis countries.
  // Order is important, as we want to have the principal countries on first place to use their color in the map
  // If no countries are in mandates, use Azelis countries only

  const concatArray: CountryWithColor[] = filteredCountriesArray
    ? expansionOpportunitiesForMap.concat(filteredCountriesArray, allCountries)
    : allCountries;

  const { data: crmMarketInsights, isLoading: isCrmMarketInsightsLoading } =
    useCRMMarketInsights(selectedPrincipal.id);

  const {
    data: firebaseMarketInsights,
    isLoading: isFirebaseMarketInsightsLoading,
  } = useMarketInsights();

  const marketInsights = useMemo(
    () => crmMarketInsights.concat(firebaseMarketInsights),
    [crmMarketInsights, firebaseMarketInsights],
  );

  const sortedMarketInsights = () => {
    const filteredMarketInsights = marketInsights.filter(
      (marketInsight) => marketInsight.marketSegment,
    );

    return orderBy(
      filteredMarketInsights,
      [(marketInsight) => marketInsight.publicationDate?.seconds],
      ['desc'],
    ).slice(0, 2);
  };

  const validAzelisCountryColourGroups: { [key: string]: boolean } =
    useMemo(() => {
      return {
        'Azelis countries': true,
        'Current mandates': true,
        'New country opportunities': true,
        'Consolidation opportunities': true,
      };
    }, []);

  const onMapClickHandler = useCallback(
    (country: CountryClickEvent) => {
      const azelisCountryDetails = concatArray.find(
        (current) => current && current.ISO === country.ISO_A3,
      );
      setIsValidAzelisCountry(
        validAzelisCountryColourGroups[
          azelisCountryDetails?.colorGroup.name || ''
        ],
      );
      setSelectedCountryISO(country);
      setIsCountryModalHidden(false);
    },
    [concatArray, validAzelisCountryColourGroups],
  );

  const onClickTile = useCallback(
    (e: MouseEvent<HTMLDivElement>, expansion = false) => {
      e.preventDefault();
      if (expansion) {
        navigate(
          `${marketIntelligenceRoute.url}/${SubFeatureType.MARKET_INTELLIGENCE_EXPANSION_OPPORTUNITIES}`,
        );
      } else {
        navigate(marketIntelligenceRoute.url);
      }
    },
    [navigate, marketIntelligenceRoute],
  );

  const onClickTileExpand = useCallback(
    (e: MouseEvent<HTMLDivElement>, expansion = true) =>
      onClickTile(e, expansion),
    [onClickTile],
  );

  const handleFiltering = useCallback(
    (
      options: string[],
      mapFilters: FilterByPrincipal | undefined,
      filterType: keyof FilterProps,
    ) => {
      const newMapFilters: FilterByPrincipal = {
        ...mapFilters,
        [selectedPrincipalId]: {
          ...mapFilters?.[selectedPrincipalId],
          [filterType]: options,
        },
      };

      newMapFilters[selectedPrincipalId][filterType] = options;

      updateUser({ mapFilters: newMapFilters });
    },
    [selectedPrincipalId, updateUser],
  );

  const onMarketSegmentDropdownChange = useCallback(
    (marketSegments: DropdownOptionProps[]) => {
      handleFiltering(
        marketSegments.map((option) => option.value.toString()),
        userMapFilters,
        'marketSegmentFilter',
      );
    },
    [handleFiltering, userMapFilters],
  );

  const onPortfolioDropdownChange = useCallback(
    (portfolios: DropdownOptionProps[]) =>
      handleFiltering(
        portfolios.map((option) => option.value.toString()),
        userMapFilters,
        'portfolioFilter',
      ),
    [handleFiltering, userMapFilters],
  );

  const hideCountryModal = useCallback(() => {
    setIsCountryModalHidden(true);
  }, [setIsCountryModalHidden]);

  const showTop3ExpansionOpportunities =
    expansionOpportunities &&
    expansionOpportunities.length > 0 &&
    hasExpansionOpportunitiesPermissions;

  const isLoading =
    isCrmMarketInsightsLoading || isFirebaseMarketInsightsLoading;

  return (
    <>
      {isLoadingMandates ? (
        <div>
          <LoadingBounce text={t('features:dashboard:map:loading')} />
        </div>
      ) : mandates ? (
        <>
          <div className="mb-4 grid grid-flow-row grid-cols-3 gap-4">
            <div className="col-span-3 self-center xl:col-span-1">
              <Heading
                text={t('features:dashboard:map:heading')}
                level="h2"
                margin={0}
              />
            </div>
            <div className=" flex-end col-span-3 flex justify-start gap-3 xl:col-span-2 xl:justify-end">
              <div className="w-220px">
                <DropdownFilter
                  selectAllLabel={t('placeholders:selectAll')}
                  deselectAllLabel={t('placeholders:deselectAll')}
                  typeAhead
                  options={availableMarketSegmentOptions}
                  label={t('features:dashboard:marketSegmentLabel')}
                  onChange={onMarketSegmentDropdownChange}
                  initialSelection={
                    selectedMarketSegmentOptions ||
                    availableMarketSegmentOptions
                  }
                />
              </div>
              <div className="w-220px">
                <DropdownFilter
                  options={availablePortfolioOptions}
                  label={t('features:dashboard:portfolioLabel')}
                  onChange={onPortfolioDropdownChange}
                  selectAllLabel={t('placeholders:selectAll')}
                  deselectAllLabel={t('placeholders:deselectAll')}
                  typeAhead
                  initialSelection={
                    selectedPortfolioOptions || availablePortfolioOptions
                  }
                />
              </div>
            </div>
          </div>
          <div className="grid grid-flow-row grid-cols-3 gap-4">
            {principalsWithAlertMessageEmeaMandatesOnly.includes(
              selectedPrincipalId,
            ) && (
              <div className="col-span-3 xl:col-span-2">
                <Alert
                  flavour="info"
                  message={t('features:dashboard:map:mandateInfoEMEA', {
                    principalName: selectedPrincipalId,
                  })}
                  icon="AttentionSign"
                />
              </div>
            )}
            <div className="col-span-3 xl:col-span-2">
              {isCRMCountriesDataLoading ? (
                <div>
                  <LoadingBounce text={t('features:dashboard:map:loading')} />
                </div>
              ) : (
                <div className="relative">
                  {isLoadingFilteredMandates ? (
                    <div className="fade-in absolute inset-0 z-50 h-full w-full bg-black/5 transition-all duration-300">
                      <LoadingBounce />
                    </div>
                  ) : null}
                  <Map
                    countries={concatArray.filter((m) => m !== null)}
                    fixedLegend={Object.values(LEGEND_COLORS)}
                    mapName={selectedMapName}
                    onClick={onMapClickHandler}
                    mapDropdown={{
                      options: mapNameDropdownOptions,
                      label: t('features:dashboard:map:region'),
                      initialSelection: mapNameDropdownOptions.find(
                        ({ value }) => value === MapName.WORLD,
                      ),
                      onChange: ({ value }) => {
                        setSelectedMapName(MapName[value as MapName]);
                      },
                    }}
                  />
                </div>
              )}
              {/* This is for bigger screen sizes */}
              <div className="mt-4 hidden xl:block">
                <div className="flex">
                  <div className="-mx-2 w-full grow">
                    {hasMarketInsightsPermissions && (
                      <Tile
                        headline={t('features:dashboard:latestInsights')}
                        link={{
                          label: t('features:dashboard:linkLabel'),
                          href: '/',
                        }}
                        onClick={onClickTile}
                      >
                        <div className="grid grid-flow-col grid-cols-2 gap-9">
                          {isLoading ? (
                            <LoadingBounce />
                          ) : (
                            sortedMarketInsights().map((content, idx) => (
                              <React.Fragment key={idx}>
                                <MarketInsight
                                  categories={content.categories}
                                  compact={true}
                                  content={content.content}
                                  publicationDate={content.publicationDate}
                                  divider={false}
                                  entry={content}
                                  marketSegment={content.marketSegment.name}
                                  title={content.title}
                                  region={content.region}
                                  countries={content.countries}
                                />
                              </React.Fragment>
                            ))
                          )}
                        </div>
                      </Tile>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-3 xl:col-span-1 xl:col-start-3 xl:row-span-2 xl:row-start-1">
              <div className="-mx-2 mb-3 flex grow flex-wrap">
                <div className="xs:mb-2 xs:w-5/12 w-full grow sm:mb-4 sm:w-2/12 md:ml-0 md:w-3/12 md:px-2 lg:mb-4 xl:w-6/12">
                  <DashboardTile
                    label={t('features:dashboard:tiles:countries')}
                    value={mandates.count.countries}
                  />
                </div>
                <div className="xs:mb-2 xs:ml-2 xs:w-5/12 w-full grow sm:mb-4 sm:ml-2 sm:w-2/12 md:ml-0 md:w-3/12 md:px-2 lg:mb-4 xl:w-6/12">
                  <DashboardTile
                    label={t('features:dashboard:tiles:portfolios')}
                    value={mandates.count.portfolios}
                  />
                </div>
                <div className="xs:mb-2 xs:w-5/12 w-full grow sm:mb-4 sm:ml-2 sm:w-2/12 md:ml-0 md:w-3/12 md:px-2 xl:w-6/12">
                  <DashboardTile
                    label={t('features:dashboard:tiles:marketSegments')}
                    value={mandates.count.marketSegments}
                  />
                </div>
                <div className="xs:mb-2 xs:ml-2 xs:w-5/12 w-full grow sm:mb-4 sm:ml-2 sm:w-2/12 md:ml-0 md:w-3/12 md:px-2 xl:w-6/12">
                  <DashboardTile
                    label={t('features:dashboard:tiles:expansionOpportunities')}
                    value={filteredExpansionOpportunities?.length ?? 0}
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-1">
                <div className="-mx-2 mb-0 flex grow flex-wrap">
                  <div className="xs:w-full xs:mr-0 w-full grow sm:w-5/12 md:mr-0 xl:w-11/12">
                    {showTop3ExpansionOpportunities && (
                      <Tile
                        headline={t(
                          'features:dashboard:expansionOpportunityCard',
                        )}
                        link={{
                          label: t('features:dashboard:linkLabel'),
                          href: '/',
                        }}
                        fullHeight={true}
                        onClick={onClickTileExpand}
                      >
                        <ExpansionOpportunityList
                          entries={filteredExpansionOpportunities.slice(0, 3)}
                        />
                      </Tile>
                    )}
                  </div>
                </div>
                <div className="xl:hidden">
                  {isLoading ? (
                    <LoadingBounce />
                  ) : (
                    sortedMarketInsights()
                      .slice(0, 1)
                      .map((content, idx) => (
                        <div className="-mx-2 flex grow flex-wrap" key={idx}>
                          {hasMarketInsightsPermissions && (
                            <Tile
                              headline={t('features:dashboard:latestInsights')}
                              link={{
                                label: t('features:dashboard:linkLabel'),
                                href: '/',
                              }}
                              onClick={onClickTile}
                            >
                              <MarketInsight
                                compact={true}
                                divider={false}
                                entry={content}
                                title={content.title}
                                publicationDate={content.publicationDate}
                                region={content.region}
                                content={content.content}
                                categories={content.categories}
                                marketSegment={content.marketSegment.name}
                                countries={content.countries}
                              />
                            </Tile>
                          )}
                        </div>
                      ))
                  )}
                </div>
              </div>
            </div>
          </div>
          {selectedCountryISO?.name &&
            !isCRMCountriesDataLoading &&
            selectedPrincipal &&
            isValidAzelisCountry && (
              <CountryModal
                crmCountriesData={crmCountriesData ?? []}
                expansionOpportunitiesPerCountry={
                  expansionOpportunities?.filter(
                    (expansionOpportuntity) =>
                      expansionOpportuntity.country.iso ===
                      selectedCountryISO.ISO_A3,
                  ) ?? []
                }
                selectedPrincipal={selectedPrincipal}
                isHidden={isCountryModalHidden}
                countryISO3Ref={selectedCountryISO.ISO_A3}
                onCancel={hideCountryModal}
              />
            )}
        </>
      ) : null}
    </>
  );
};
