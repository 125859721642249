import {
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  UpdateData,
  updateDoc,
} from 'firebase/firestore';
import {
  CollectionType,
  FirebaseCollections,
} from './collection/firebase-collection';
import { db } from './firebase-config';

export const docRef = <T extends FirebaseCollections, CT = CollectionType<T>>(
  collection: T,
  uid: string,
): DocumentReference<CT> => {
  return doc(db, collection, uid) as DocumentReference<CT>;
};

export const colRef = <T extends FirebaseCollections, CT = CollectionType<T>>(
  collectionName: T,
): CollectionReference<CT> => {
  return collection(db, collectionName) as CollectionReference<CT>;
};

export const updateDocument = async <T>(
  docRef: DocumentReference<T>,
  partial: UpdateData<T>,
): Promise<void> => {
  return await updateDoc(docRef, partial);
};
