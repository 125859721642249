import { CountryList } from 'components/CountryList';
import { CountryListItem } from 'components/CountryList/CountryList';
import { CountryTile } from 'components/CountryTile';
import { Heading } from 'components/Heading';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Principal } from 'utils/firebase/collection/firebase-collection-types';
import { MarketSegmentDefinitions } from '../../utils/marketSegments.utils';
import {
  Mandates,
  MarketSegment,
} from '../../utils/principal-service/principal-endpoints.types';

type CountryModalMandateProps = {
  mandates: Mandates;
  selectedPrincipal: Principal;
  selectedCountry: string | undefined;
  marketSegmentDefinitions: MarketSegmentDefinitions;
};

const mandatesToCountryListItems = (
  marketSegments: MarketSegment[],
  marketSegmentDefinitions: MarketSegmentDefinitions,
): CountryListItem[] => {
  const marketSegmentIds = marketSegments.map(
    (marketSegment) => marketSegment.code,
  );

  return marketSegmentIds
    .map((id) => {
      const def = marketSegmentDefinitions[id];

      if (def) {
        return {
          displayText: def.name,
          icon: def.icon,
        };
      }
      return false;
    })
    .filter(Boolean) as CountryListItem[];
};

export const Mandate: React.FC<CountryModalMandateProps> = ({
  mandates,
  marketSegmentDefinitions,
  selectedPrincipal,
  selectedCountry,
}: CountryModalMandateProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Heading
        level="h3"
        text={t('modals:countryModal:principalAndAzelisInCountry', {
          selectedPrincipal: selectedPrincipal.manufacturerName,
          selectedCountry,
        })}
      />
      <p className="mb-3">{t('modals:countryModal:mandateBlurb')}</p>
      <CountryTile headline={t(`modals:countryModal:representedPortfolios`)}>
        <CountryList
          items={mandates.portfolios.map(({ name }) => ({
            displayText: name,
          }))}
        />
      </CountryTile>
      <CountryTile
        headline={t(`modals:countryModal:representedMarketSegments`)}
      >
        <CountryList
          items={mandatesToCountryListItems(
            mandates.marketSegments,
            marketSegmentDefinitions,
          )}
        />
      </CountryTile>
    </>
  );
};
