import { ApiKeyManagementEmptyState } from 'components/ApiKeyManagement/ApiKeyManagementEmptyState';
import { ApiKeyManagementFiltersNoResults } from 'components/ApiKeyManagement/ApiKeyManagementFiltersNoResults';
import { DropdownFilter } from 'components/Dropdown/DropdownVariants/DropdownFilter';
import { DropdownSingleSelect } from 'components/Dropdown/DropdownVariants/DropdownSingleSelect';
import { DropdownOptionProps } from 'components/Dropdown/helper/DropdownTypes';
import { Input } from 'components/Input';
import { LoadingBounce } from 'components/Loading';
import {
  comparer,
  dateStringComparer,
} from 'features/PortalMembers/PortalMembers';
import { APIHistory } from 'hooks/queries';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { APIKeyHistoryItem } from './APIKeyHistoryItem';

export type APIKeyHistoryProps = {
  module: 'apiKeyGenerator' | 'apiKeyManagement';
  apiHistory?: APIHistory[];
  isApiKeyHistoryLoading: boolean;
};

export const APIKeyHistory: React.FC<APIKeyHistoryProps> = ({
  module,
  apiHistory,
  isApiKeyHistoryLoading,
}: APIKeyHistoryProps) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [activityTypeFilter, setActivityTypeFilter] = useState<string[]>();

  const [isSortAscForKeyHistory, setIsSortAscForKeyHistory] = useState(true);

  const availableActivityTypeOptions: DropdownOptionProps[] = useMemo(() => {
    const uniqueActivityTypeValues = new Set(
      apiHistory?.map(({ activity }) => activity),
    );
    return [...uniqueActivityTypeValues].map((activityType) => ({
      name: activityType[0].toLocaleUpperCase() + activityType.slice(1),
      value: activityType,
    }));
  }, [apiHistory]);

  const availableSortOptionsForKeyHistory: DropdownOptionProps[] = useMemo(
    () => [
      {
        name: t(
          `features:market-intelligence:marketInsights:filters:sort:label`,
          { context: 'desc' },
        ),
        value: 'desc',
      },
      {
        name: t(
          `features:market-intelligence:marketInsights:filters:sort:label`,
          { context: 'asc' },
        ),
        value: 'asc',
      },
    ],
    [t],
  );

  if (!apiHistory || isApiKeyHistoryLoading) {
    return <LoadingBounce />;
  }

  const filteredHistory = apiHistory
    .filter(({ activity }) => {
      if (!activityTypeFilter) return true;
      return activityTypeFilter.some((activityTypeFilter) =>
        activity.includes(activityTypeFilter),
      );
    })
    .filter(({ userName, fromUserName, toUserName, invitedby }) => {
      if (!searchTerm) return true;
      return (
        userName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        fromUserName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        toUserName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        invitedby?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    })
    .sort((a: APIHistory, b: APIHistory) =>
      comparer(
        isSortAscForKeyHistory,
        a.dateOfActivity,
        b.dateOfActivity,
        dateStringComparer,
      ),
    );

  if (apiHistory?.length === 0 && !isApiKeyHistoryLoading) {
    return (
      <div className="my-6">
        <ApiKeyManagementEmptyState
          module={module}
          tab="keyHistory"
          hideButton
        />
      </div>
    );
  }

  return (
    <>
      <div className="mt-5 flex justify-end">
        <div>
          <DropdownSingleSelect
            initialSelection={availableSortOptionsForKeyHistory[0]}
            widthVariant="full-width"
            onChange={(option) => {
              setIsSortAscForKeyHistory(option.value.toString() === 'asc');
            }}
            allowUnset={false}
            options={availableSortOptionsForKeyHistory}
          />
        </div>
        <div className="ml-4 flex">
          <DropdownFilter
            label={t(
              'features:data-connection:apiKeyManagement:keyHistory:activityTypes',
            )}
            options={availableActivityTypeOptions}
            initialSelection={availableActivityTypeOptions}
            onChange={(options) => {
              setActivityTypeFilter(
                options.map(({ value }) => value.toString()),
              );
            }}
          />
        </div>
        {module === 'apiKeyManagement' && (
          <div className="ml-4 h-[3.15rem] w-[350px]">
            <Input
              placeholder={t(
                'features:data-connection:apiKeyManagement:keyHistory:search',
              )}
              iconPosition="right"
              icon="Search"
              onChange={(e) => setSearchTerm(e.currentTarget.value)}
            />
          </div>
        )}
      </div>
      <div className="max-w-xl-content xl:min-w-content mx-auto mb-7 pt-6 md:mb-9 md:pt-7">
        {filteredHistory?.length === 0 ? (
          <ApiKeyManagementFiltersNoResults module={module} tab="keyHistory" />
        ) : (
          filteredHistory.map((apiHistoryEvent: APIHistory) => (
            <APIKeyHistoryItem
              key={apiHistoryEvent.keyId.concat(apiHistoryEvent.dateOfActivity)}
              module={module}
              historyEvent={apiHistoryEvent}
            />
          ))
        )}
      </div>
    </>
  );
};
