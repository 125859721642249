import { AuthenticationResult, InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { ErrorFallbackPrincipals } from 'components/ErrorBoundary/ErrorFallbackPrincipals';
import { Loading } from 'components/Loading';
import { ContentController } from 'controllers/ContentController';
import { AuthErrorScreen } from 'features/AuthErrorScreen';
import CookieStatement from 'features/CookieStatement/CookieStatement';
import { Intro } from 'features/Intro';
import TermsOfUse from 'features/TermsOfUse/TermsOfUse';
import { usePrincipals } from 'hooks/queries';
import React, { useCallback, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { setAuth } from 'store/user/user.action';
import { selectAuth } from 'store/user/user.selector';
import {
  onAuthStateChangedListener,
  setDisplayName,
  signInWithToken,
} from 'utils/firebase/firebase-auth';
import { auth } from 'utils/firebase/firebase-config';
import { getPrincipalSlugFromLocalStorage } from 'utils/localStorage.utils';
import { getFirebaseToken } from 'utils/principal-service/auth-endpoints';
import { tracking } from './lib/tracking';
import { shortenEnvironmentName } from './lib/utils';
import { loginRequest } from './shared/authentication-config';
import './tailwind.css';

const App: React.FC = () => {
  const [authError, setAuthError] = useState<string | undefined>(undefined);
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();
  const dispatch = useDispatch();
  const authUser = useSelector(selectAuth);
  const { data: principals } = usePrincipals();

  useEffect(() => {
    instance.enableAccountStorageEvents();
    return () => {
      instance.disableAccountStorageEvents();
    };
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChangedListener(async (auth) => {
      dispatch(setAuth(auth));
    });
    return unsubscribe;
  }, [dispatch]);

  useEffect(() => {
    const environment = shortenEnvironmentName(
      process.env.REACT_APP_ENVIRONMENT,
    );
    tracking.push('site', 'Init', {
      environment,
      viewport: `${window.innerWidth}x${window.innerHeight}`,
      language: 'en',
    });
  }, []);

  const acquireTokenRedirectHandler = useCallback(async () => {
    await instance.acquireTokenRedirect({
      ...loginRequest,
      account: instance.getActiveAccount() ?? undefined,
    });
  }, [instance]);

  const acquireTokenSilentHandler = useCallback(
    async (forceRefresh = false): Promise<AuthenticationResult> => {
      const account = instance.getActiveAccount();
      if (!account)
        throw Error('No active account! Verify a user has been signed in!');
      try {
        const response = await instance.acquireTokenSilent({
          ...loginRequest,
          account,
          forceRefresh,
        });
        return response;
      } catch (error) {
        await acquireTokenRedirectHandler();
        throw error;
      }
    },
    [instance, acquireTokenRedirectHandler],
  );

  const authenticateWithFirebase = useCallback(
    async ({ idToken, account }: AuthenticationResult): Promise<boolean> => {
      return getFirebaseToken(idToken)
        .then(async (tokenResponse) => {
          if (!tokenResponse.data) return false;
          const { token } = tokenResponse.data;

          if (token) {
            await signInWithToken(token);

            const currentPrincipal = getPrincipalSlugFromLocalStorage(
              auth?.currentUser?.uid || '',
            );

            await setDisplayName(account, currentPrincipal);

            return !!auth.currentUser;
          }
          return false;
        })
        .catch((error) => {
          if (error.message === 'missing-portal-member') {
            setAuthError(error);
            return true;
          }
          if (error.message === 'no-valid-token') {
            throw Error('Could not authenticate with Firebase');
          }
          setAuthError(error);
          instance.logoutRedirect({ onRedirectNavigate: () => false });
          return false;
        });
    },
    [instance],
  );

  const authenticateFirebaseHandler =
    useCallback(async (): Promise<boolean> => {
      const authenticationResult = await acquireTokenSilentHandler();
      return await authenticateWithFirebase(authenticationResult);
    }, [authenticateWithFirebase, acquireTokenSilentHandler]);

  useEffect(() => {
    if (inProgress === InteractionStatus.None && instance.getActiveAccount()) {
      authenticateFirebaseHandler().catch((error) => {
        if (error !== 'no-valid-token') {
          acquireTokenRedirectHandler();
          return;
        }
        // the idToken can expire and the firebase authentication will fail before it's updated
        // This will force a token refresh and attempt another firebase authentication
        acquireTokenSilentHandler(true).then(() => {
          authenticateFirebaseHandler().catch((error) => {
            console.error(error);
            acquireTokenRedirectHandler();
          });
        });
      });
    }
  }, [
    authenticateFirebaseHandler,
    inProgress,
    instance,
    acquireTokenRedirectHandler,
    acquireTokenSilentHandler,
  ]);

  return (
    <>
      {isAuthenticated ? (
        authUser && principals ? (
          // logged in to MS Active Directory and firebase auth, no error occured
          // error boundary if no prinipal is selected (not in url and localStorage)
          <ErrorBoundary
            FallbackComponent={({ error, resetErrorBoundary }) => (
              <ErrorFallbackPrincipals
                userId={authUser.uid}
                error={error}
                resetErrorBoundary={resetErrorBoundary}
              />
            )}
          >
            <ContentController />
          </ErrorBoundary>
        ) : authError ? (
          // logged in to MS Active Directory but no firebase auth user found
          <AuthErrorScreen error={authError} />
        ) : (
          // logged in to MS Active Directory, user login is not complete but firebase auth user found
          <Loading
            className="flex w-full items-center justify-center"
            loading
          />
        )
      ) : inProgress !== 'none' ? (
        // MS Active Directory authentication is running
        <Loading className="flex w-full items-center justify-center" loading />
      ) : (
        // not logged in to both MS Active Directory (also occurs when not completely logged out (sign-back-in via MS AD)) + firebase Auth or authError occured
        <Routes>
          <Route path="/cookie-statement" element={<CookieStatement />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          {/* routing to intro-component on all subpages */}
          <Route path="/*" element={<Intro />} />
        </Routes>
      )}
      <Toaster
        containerStyle={{
          top: 0,
          left: 0,
          bottom: 72,
          right: 0,
        }}
        reverseOrder={false}
      />
    </>
  );
};

export default App;
