import { Button, ButtonType } from 'components/Button';
import { Checkbox } from 'components/Checkbox';
import { Link } from 'components/Link';
import { Modal } from 'components/Modal';
import { Table } from 'components/Table/Table';
import { TableBody } from 'components/Table/TableBody';
import { TableCell } from 'components/Table/TableCell';
import { TableRow } from 'components/Table/TableRow';
import { Textarea } from 'components/Textarea';
import { UserDetails } from 'components/UserManagementModals/UserDetails/UserDetails';
import { ContactFormData } from 'shared/types/ContactFormData';
import { validateEmailAddress } from 'lib/utils';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseUserType } from 'utils/firebase';

type ContactFormModalProps = {
  onCancel: () => void;
  onSendContactForm: (contactFormData: ContactFormData) => void;
};

export enum EdiProcessType {
  OPTION1 = 'optionOne',
  OPTION2 = 'optionTwo',
  OTHER = 'other',
}

export const ContactFormModal: React.FC<ContactFormModalProps> = ({
  onCancel,
  onSendContactForm,
}) => {
  const { t } = useTranslation();

  const [isEmailValid, setIsEmailValid] = useState(true);
  const [initialTechnicalTeamsData, setInitialTechnicalTeamsData] =
    useState<BaseUserType>({
      firstName: '',
      lastName: '',
      email: '',
    });
  const [contactFormData, setContactFormData] = useState<ContactFormData>({
    ediProcess: {
      selected: [],
      other: '',
    },
    countries: '',
    technicalTeams: [],
    additionalInformation: '',
  });

  const userDetailsPlaceholder: BaseUserType = {
    firstName: 'Name',
    lastName: 'Last Name',
    email: 'email@mail.com',
  };

  const ediProcessOptions = [
    EdiProcessType.OPTION1,
    EdiProcessType.OPTION2,
    EdiProcessType.OTHER,
  ];

  const isOtherCheckedAndFilledOut =
    contactFormData.ediProcess.selected.includes('Other') &&
    !contactFormData.ediProcess.other?.length;

  const isAddMemberDisabled =
    !initialTechnicalTeamsData.firstName.length ||
    !initialTechnicalTeamsData.lastName.length ||
    !initialTechnicalTeamsData.email.length ||
    !isEmailValid;

  const isConfirmButtonDisabled =
    !contactFormData.countries.length ||
    !contactFormData.technicalTeams.length ||
    !contactFormData.ediProcess.selected.length ||
    isOtherCheckedAndFilledOut;

  const onChangeForm = (
    inputValue: Record<
      string,
      Record<string, string | string[] | Record<string, string[]>> | string
    >,
  ) => {
    setInitialTechnicalTeamsData({
      ...initialTechnicalTeamsData,
      ...inputValue,
    });
  };

  const validateNewUsersEmailAdress = (value: string) => {
    if (!isEmailValid && validateEmailAddress(value)) {
      setIsEmailValid(true);
    }

    if (!validateEmailAddress(value)) {
      setIsEmailValid(false);
    }
    onChangeForm({ email: value });
  };

  const handleCheckboxChange = (value: string) => {
    const checkedValues = contactFormData.ediProcess.selected;

    const newCheckboxValues = checkedValues.includes(value)
      ? checkedValues.filter((str) => str !== value)
      : [...checkedValues, value];

    if (
      contactFormData.ediProcess.selected.includes('Other') &&
      value === 'Other'
    ) {
      setContactFormData({
        ...contactFormData,
        ediProcess: {
          selected: newCheckboxValues,
          other: '',
        },
      });
    } else {
      setContactFormData({
        ...contactFormData,
        ediProcess: {
          ...contactFormData.ediProcess,
          selected: newCheckboxValues,
        },
      });
    }
  };

  const handleAddTechnicalTeamMember = () => {
    setContactFormData({
      ...contactFormData,
      technicalTeams: [
        ...contactFormData.technicalTeams,
        initialTechnicalTeamsData,
      ],
    });
    setInitialTechnicalTeamsData({
      firstName: '',
      lastName: '',
      email: '',
    });
  };

  const handleRemoveMember = (member: BaseUserType) => {
    const checkedValues = contactFormData.technicalTeams;

    const newMemberValue = checkedValues.filter(
      ({ email }) => email !== member.email,
    );

    setContactFormData({
      ...contactFormData,
      technicalTeams: [...newMemberValue],
    });
  };

  const onClickConfirm = contactFormData
    ? () => {
        onSendContactForm(contactFormData);
      }
    : undefined;

  return (
    <>
      <Modal
        isFullscreen={true}
        heading={t('modals:contactFormModal:heading')}
        confirmText={t('modals:contactFormModal:sendContactInfo')}
        onConfirm={onClickConfirm}
        onCancel={onCancel}
        isConfirmButtonDisabled={isConfirmButtonDisabled}
        headingAlign="left"
      >
        <div className="flex">
          <div className="text-grey-700 grow">
            <p className=" mt-1">{t('modals:contactFormModal:description')}</p>
            <div className="my-3 flex flex-row items-center">
              <h3 className="text-2xl font-medium">
                {t('modals:contactFormModal:ediProcessSelection:heading')}
              </h3>
              <p className="text-grey-400 ml-1 font-medium">
                {t('modals:contactFormModal:ediProcessSelection:subHeading')}
              </p>
            </div>

            <fieldset>
              {ediProcessOptions.map((val) => (
                <div className="my-1" key={val}>
                  <Checkbox
                    initialChecked={
                      !!contactFormData.ediProcess.selected.find(
                        (option) => option === val,
                      )
                    }
                    label={t(
                      `modals:contactFormModal:ediProcessSelection:${val}`,
                    )}
                    onChange={() =>
                      handleCheckboxChange(
                        t(`modals:contactFormModal:ediProcessSelection:${val}`),
                      )
                    }
                  />
                </div>
              ))}

              {contactFormData.ediProcess.selected.includes('Other') && (
                <div data-testid="other-textarea">
                  <Textarea
                    rows={5}
                    placeholder={`${t(
                      'modals:contactFormModal:ediProcessSelection:textFieldPlaceholder',
                    )}`}
                    initialValue={contactFormData.ediProcess.other || ''}
                    onChange={(e) =>
                      setContactFormData({
                        ...contactFormData,
                        ediProcess: {
                          ...contactFormData.ediProcess,
                          other: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              )}
            </fieldset>

            <h3 className="mb-3 mt-8 text-2xl font-medium">
              {t('modals:contactFormModal:countriesSelection:heading')}
            </h3>
            <p className="mb-3 font-medium">
              {t('modals:contactFormModal:countriesSelection:textBoxHeading')}
            </p>
            <fieldset>
              <Textarea
                dataTestId="country-field"
                rows={5}
                placeholder={`${t(
                  'modals:contactFormModal:countriesSelection:textFieldPlaceholder',
                )}`}
                initialValue={contactFormData.countries || ''}
                onChange={(e) =>
                  setContactFormData({
                    ...contactFormData,
                    countries: e.target.value,
                  })
                }
              />
            </fieldset>

            <div className="mt-8">
              <UserDetails
                placeholder={userDetailsPlaceholder}
                initialUserData={initialTechnicalTeamsData}
                heading={t(
                  'modals:contactFormModal:technicalTeamSelection:heading',
                )}
                isEmailValid={isEmailValid}
                validateNewUsersEmailAdress={validateNewUsersEmailAdress}
                onChangeForm={onChangeForm}
              />

              <Button
                data-testid="add-team-member"
                className="px-4 font-medium"
                buttonType={ButtonType.NORMAL}
                label={t(
                  'modals:contactFormModal:technicalTeamSelection:emailField:addEmailButton',
                )}
                onClick={handleAddTechnicalTeamMember}
                disabled={isAddMemberDisabled}
              />
            </div>

            {!!contactFormData.technicalTeams.length && (
              <div
                className="mb-3 mt-6 font-medium"
                data-testid="technical-members-table"
              >
                <p>
                  {t(
                    `modals:contactFormModal:technicalTeamSelection:addedMembers`,
                    {
                      numberOfAddedMembers:
                        contactFormData.technicalTeams.length,
                    },
                  )}
                </p>
                <Table>
                  <TableBody>
                    {contactFormData.technicalTeams.map((contact) => (
                      <TableRow key={contact.email} hoverable>
                        <TableCell>
                          {contact.firstName} {contact.lastName},{' '}
                          {contact.email}
                        </TableCell>
                        <TableCell justify="end">
                          <Link
                            onClick={(event) => {
                              event.preventDefault();
                              handleRemoveMember(contact);
                            }}
                            icon="Delete"
                            iconPosition="left"
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            )}

            <div className="mb-3 mt-6 flex flex-row items-center">
              <p className="font-medium">
                {t('modals:contactFormModal:additionalInfo:heading')}
              </p>
              <p className="text-grey-400 ml-1 font-medium">
                {t('modals:contactFormModal:additionalInfo:subHeading')}
              </p>
            </div>
            <fieldset>
              <Textarea
                rows={5}
                placeholder={`${t(
                  'modals:contactFormModal:additionalInfo:additionalInfoPlaceholder',
                )}`}
                initialValue={contactFormData.additionalInformation || ''}
                onChange={(e) =>
                  setContactFormData({
                    ...contactFormData,
                    additionalInformation: e.target.value,
                  })
                }
              />
            </fieldset>
          </div>
        </div>
      </Modal>
    </>
  );
};
