import { APIDeleteKeyModal } from 'components/ApiKeyManagement/APIDeleteKeyModal';
import { ApiKeyManagementEmptyState } from 'components/ApiKeyManagement/ApiKeyManagementEmptyState';
import { ApiKeyManagementFiltersNoResults } from 'components/ApiKeyManagement/ApiKeyManagementFiltersNoResults';
import { DropdownFilter } from 'components/Dropdown/DropdownVariants/DropdownFilter';
import { DropdownOptionProps } from 'components/Dropdown/helper/DropdownTypes';
import { Input } from 'components/Input';
import { LoadingBounce } from 'components/Loading';
import { Table } from 'components/Table/Table';
import { TableBody } from 'components/Table/TableBody';
import { TableCell } from 'components/Table/TableCell';
import { TableHeader } from 'components/Table/TableHeader';
import { TableRow } from 'components/Table/TableRow';
import { ToggleSwitch } from 'components/ToggleSwitch';
import { SavingModal } from 'components/UserManagementModals';
import { APIKeyData, APIKeyTableColumn } from 'features/DataConnection/types';
import { comparer, stringComparer } from 'features/PortalMembers/PortalMembers';
import { useApiKeys } from 'hooks/queries';
import { Notification } from 'lib/notifications/notifications';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SortingConfig } from 'shared/types/SortingConfig';
import { differenceInDays } from 'utils/dateHandling.utils';
import { APIKeyTableRow } from './APIKeyTableRow';

const APIsorter = (
  objectA: APIKeyData,
  objectB: APIKeyData,
  { column, asc }: SortingConfig<APIKeyTableColumn>,
): number => {
  switch (column) {
    case APIKeyTableColumn.USERNAME: {
      return comparer(
        asc,
        objectA.userDetails?.name,
        objectB.userDetails?.name,
        stringComparer,
      );
    }
    case APIKeyTableColumn.PROJECT_NAME: {
      return comparer(
        asc,
        objectA.projectName,
        objectB.projectName,
        stringComparer,
      );
    }
    case APIKeyTableColumn.SCOPES: {
      return comparer(
        asc,
        objectA.scopes[0],
        objectB.scopes[0],
        stringComparer,
      );
    }
    case APIKeyTableColumn.DATE_CREATED: {
      return comparer(
        asc,
        objectA.dateCreated,
        objectB.dateCreated,
        stringComparer,
      );
    }
    case APIKeyTableColumn.STATUS: {
      return comparer(asc, objectA.status, objectB.status, stringComparer);
    }
    case APIKeyTableColumn.DATE_EXPIRY: {
      return comparer(
        asc,
        objectA.dateExpiry,
        objectB.dateExpiry,
        stringComparer,
      );
    }

    default:
      return 0;
  }
};
interface APIKeyOverviewProps {
  apiKeys: APIKeyData[] | undefined;
  areAvailableKeysLoading: boolean;
  module: 'apiKeyGenerator' | 'apiKeyManagement';
  tab: 'keyHistory' | 'keyOverview';
  handleShowAPIGeneratorModal: (value: boolean) => void;
}

const sortOrderToShow = (
  targetColumn: string,
  { column, asc }: SortingConfig<APIKeyTableColumn>,
): 'asc' | 'desc' | undefined => {
  if (column !== targetColumn) return;
  return asc ? 'asc' : 'desc';
};

type TableHeaderCellProps = {
  sortConfig: SortingConfig<APIKeyTableColumn>;
  type: APIKeyTableColumn;
  handleSortingChange?: (targetColumn: APIKeyTableColumn) => void;
};

const TableHeaderCell = ({
  sortConfig,
  type,
  handleSortingChange,
}: TableHeaderCellProps) => {
  const { t } = useTranslation();

  return (
    <TableCell
      noWrap
      displaySortingButton={!!handleSortingChange}
      sortOrder={sortOrderToShow(type, sortConfig)}
      onClick={() => {
        handleSortingChange && handleSortingChange(type);
      }}
    >
      {t(`features:data-connection:table:${type}`)}
    </TableCell>
  );
};

export const APIKeyOverview: React.FC<APIKeyOverviewProps> = ({
  apiKeys,
  areAvailableKeysLoading,
  module,
  handleShowAPIGeneratorModal,
}: APIKeyOverviewProps) => {
  const { t } = useTranslation();
  const [apiKeyToDelete, setAPIKeyToDelete] = useState('');
  const [showAPIDeleteKeyModal, setShowAPIDeleteKeyModal] = useState(false);
  const [showLoadingScreen, setShowLoadingScreen] = useState(false);
  const { deleteSubscription } = useApiKeys();

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [scopesFilters, setScopesFilters] = useState<string[]>();
  const [statusFilters, setStatusFilters] = useState<string[]>();
  const [dateExpiryFilter, setDateExpiryFilter] = useState<boolean>();

  const availableScopeOptions: DropdownOptionProps[] = useMemo(() => {
    if (!apiKeys) return [];
    const uniqueScopeValues = new Set(apiKeys.flatMap(({ scopes }) => scopes));
    return [...uniqueScopeValues].map((scope) => ({
      name: scope,
      value: scope,
    }));
  }, [apiKeys]);

  const availableStatusOptions: DropdownOptionProps[] = useMemo(() => {
    if (!apiKeys) return [];
    const uniqueStatusValues = new Set(apiKeys.map(({ status }) => status));
    return [...uniqueStatusValues].map((status) => ({
      name: t(`features:data-connection:apiKeyManagement:keyStatus:${status}`),
      value: status,
    }));
  }, [apiKeys, t]);

  const [sortConfig, setSortConfig] = useState<
    SortingConfig<APIKeyTableColumn>
  >({
    column: APIKeyTableColumn.DATE_CREATED,
    asc: false,
  });

  if (areAvailableKeysLoading || !apiKeys) {
    return <LoadingBounce />;
  }

  const handleSortingChange = (targetColumn: APIKeyTableColumn) => {
    const asc = sortConfig.column === targetColumn ? !sortConfig.asc : true;
    setSortConfig({ column: targetColumn, asc });
  };

  const sortedKeys = apiKeys
    ?.filter(({ scopes }) => {
      if (!scopesFilters) {
        return true;
      }
      return scopesFilters.some((scopeFilter) => scopes.includes(scopeFilter));
    })
    .filter(({ status }) => {
      if (!statusFilters) {
        return true;
      }
      return statusFilters.some((statusFilter) =>
        status.includes(statusFilter),
      );
    })
    .filter(({ dateExpiry }) => {
      if (!dateExpiryFilter) {
        return true;
      }
      const currentDate = new Date().getTime();
      const expiringDate = new Date(dateExpiry).getTime();
      const daysRemaining = differenceInDays(expiringDate, currentDate);
      const isExpiring = daysRemaining <= 30 && daysRemaining > -1;
      return isExpiring;
    })
    .filter(({ projectName, userDetails }) => {
      if (!searchTerm) {
        return true;
      }
      if (module === 'apiKeyGenerator') {
        return projectName.toLowerCase().includes(searchTerm.toLowerCase());
      }
      return (
        projectName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        userDetails.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        userDetails.name?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    })
    .sort((a: APIKeyData, b: APIKeyData) => APIsorter(a, b, sortConfig));

  if (apiKeys.length === 0) {
    return (
      <div className="my-6">
        <ApiKeyManagementEmptyState
          module={module}
          tab="keyOverview"
          onClick={() => handleShowAPIGeneratorModal(true)}
        />
      </div>
    );
  }

  const showUserName = module === 'apiKeyManagement';
  const onCancel = () => {
    setShowAPIDeleteKeyModal(false);
  };
  const handleDeleteConfirmation = () => {
    setShowAPIDeleteKeyModal(false);
    setShowLoadingScreen(true);

    deleteSubscription(apiKeyToDelete)
      .then(() => {
        Notification({
          message: t(
            `features:data-connection:apiKeyManagement:keyDeletion:deletedNotice:message`,
          ),
        }),
          setShowLoadingScreen(false);
      })
      .catch((error) => console.log('deleteSubscription: ', error));
  };

  return (
    <>
      <div className="flex justify-end">
        <div className="flex">
          <ToggleSwitch
            initialChecked={false}
            label="Expires soon"
            onChange={setDateExpiryFilter}
          />
        </div>
        <div className="ml-4 flex">
          <DropdownFilter
            label="Scopes:"
            options={availableScopeOptions}
            initialSelection={availableScopeOptions}
            onChange={(options) => {
              setScopesFilters(options.map(({ value }) => value.toString()));
            }}
          />
        </div>
        <div className="ml-4 flex">
          <DropdownFilter
            label="Status:"
            options={availableStatusOptions}
            initialSelection={availableStatusOptions}
            onChange={(options) => {
              setStatusFilters(options.map(({ value }) => value.toString()));
            }}
          />
        </div>
        <div className="w-300px ml-4 h-[3.15rem] ">
          <Input
            placeholder={t(
              `features:data-connection:${module}:keyOverview:searchFilter:placeholder`,
            )}
            iconPosition="right"
            icon="Search"
            onChange={(e) => setSearchTerm(e.currentTarget.value)}
          />
        </div>
      </div>
      {sortedKeys.length === 0 ? (
        <ApiKeyManagementFiltersNoResults module={module} tab="keyOverview" />
      ) : (
        <div className="overflow-auto">
          <Table>
            <TableHeader>
              <TableRow darkBorder>
                {showUserName && (
                  <TableHeaderCell
                    type={APIKeyTableColumn.USERNAME}
                    sortConfig={sortConfig}
                    handleSortingChange={handleSortingChange}
                  />
                )}
                <TableHeaderCell
                  type={APIKeyTableColumn.PROJECT_NAME}
                  sortConfig={sortConfig}
                  handleSortingChange={handleSortingChange}
                />
                <TableHeaderCell
                  type={APIKeyTableColumn.SCOPES}
                  sortConfig={sortConfig}
                  handleSortingChange={handleSortingChange}
                />
                <TableHeaderCell
                  type={APIKeyTableColumn.DATE_CREATED}
                  sortConfig={sortConfig}
                  handleSortingChange={handleSortingChange}
                />
                <TableHeaderCell
                  type={APIKeyTableColumn.STATUS}
                  sortConfig={sortConfig}
                  handleSortingChange={handleSortingChange}
                />
                <TableHeaderCell
                  type={APIKeyTableColumn.DATE_EXPIRY}
                  sortConfig={sortConfig}
                  handleSortingChange={handleSortingChange}
                />
                <TableHeaderCell
                  type={APIKeyTableColumn.API_KEY}
                  sortConfig={sortConfig}
                />
              </TableRow>
            </TableHeader>
            <TableBody>
              {sortedKeys.map((apiKey: APIKeyData) => (
                <APIKeyTableRow
                  handleShowAPIDeleteKeyModal={() => {
                    setAPIKeyToDelete(apiKey.keyId);
                    setShowAPIDeleteKeyModal(true);
                  }}
                  apiDetails={apiKey}
                  showUserName={showUserName}
                  key={apiKey.keyId}
                />
              ))}
            </TableBody>
          </Table>
        </div>
      )}
      {showAPIDeleteKeyModal && (
        <APIDeleteKeyModal
          isHidden={!showAPIDeleteKeyModal}
          onCancel={onCancel}
          onConfirm={handleDeleteConfirmation}
        />
      )}

      {showLoadingScreen && (
        <SavingModal
          topMessage={t(
            `features:data-connection:apiKeyManagement:keyDeletion:savingModal:topMessage`,
          )}
          bottomMessage={t(
            `features:data-connection:apiKeyManagement:keyDeletion:savingModal:bottomMessage`,
          )}
        />
      )}
    </>
  );
};
