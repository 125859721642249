import { getFirebaseBearerToken } from '../../lib/getFirebaseBearerToken';
import axios from 'axios';

export const basePrincipalServiceRequest = async <T>(
  method: string,
  urlExtention: string,
  body?: Record<string, unknown>,
) => {
  const bearerToken = await getFirebaseBearerToken();
  return axios<T>({
    url: `${process.env.REACT_APP_PORTAL_SERVICE_BASE_URL}/${urlExtention}`,
    method: `${method}`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
    },
    data: body,
  });
};
