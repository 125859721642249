import { useMsal } from '@azure/msal-react';
import { useCurrentUser, usePrincipals } from 'hooks/queries';
import { getCurrentPrincipal } from 'lib/currentPrincipal';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUpdateResult } from 'store/sharepoint/sharepoint.selector';
import { Heading } from '../../components/Heading';
import { Sharepoint } from '../../components/Sharepoint';
import { Tab } from '../../components/Tabs';
import { Tabs } from '../../components/Tabs/Tabs';
import { MainFeatureComponent } from '../../controllers/ContentController/Routes';
import {
  FileTreeElement,
  getSharePointFolder,
  initSharePointConnection,
  SharePointApiResult,
} from '../../lib/sharepoint';

export const SharedDocuments: MainFeatureComponent = () => {
  const { t } = useTranslation();
  const { instance } = useMsal();
  const client = initSharePointConnection(instance);

  const { user } = useCurrentUser();
  const { data: principals } = usePrincipals();
  const { currentPrincipal } = getCurrentPrincipal(user.userId, principals);

  const sharePointDriveId = process.env.REACT_APP_SHAREPOINT_DRIVE_ID as string;

  const updateResult = useSelector(selectUpdateResult);
  const [currentDriveId, setCurrentDriveId] = useState<string | null>(null);
  const [currentFolderId, setCurrentFolderId] = useState<string | null>(null);
  const [parentFolderIdStack, setParentFolderIdStack] = useState<string[]>([]);
  const [parentFolderId, setParentFolderId] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<string>('last-added');

  const [sharePointRoot, setSharePointRoot] = useState<SharePointApiResult>({
    sharePointFolder: '',
    value: [],
  });

  interface CachedRoots {
    [id: string]: SharePointApiResult;
  }

  const [cachedSharePointRoots, setCachedSharePointRoots] =
    useState<CachedRoots>({});

  interface CachedFolderStats {
    updates: number;
    filesInAllSubTrees: number;
  }

  interface CachedNotificationsByFolderId {
    [id: string]: CachedFolderStats;
  }

  const [cachedNotificationsByFolderId, setCachedNotificationsByFolderId] =
    useState<CachedNotificationsByFolderId>({});
  /**
   * Init Component State when Principal Changes
   */
  useEffect(() => {
    const driveId = currentPrincipal.sharepointDriveId;
    if (driveId && currentDriveId !== driveId) {
      setSharePointRoot({
        sharePointFolder: '',
        value: [],
      });
      setCurrentDriveId(driveId);
      setCurrentFolderId(driveId);
      setParentFolderIdStack([driveId]);
      setParentFolderId(driveId);
    }
    // only do something in the section above when currentPrincipal changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPrincipal]);

  /**
   * Intercept API Results for Sharepoint Root to maintain Cache
   */
  const interceptSetSharePointRoot = (apiResult: SharePointApiResult) => {
    // Maintain Existing Cache for SubTree if a Cached Result is here (Way-Downwards)
    if (
      apiResult?.sharePointFolder === currentFolderId &&
      sharePointRoot.subFileTrees
    ) {
      apiResult.subFileTrees = sharePointRoot.subFileTrees;
    }

    // When the User is clicking fast through Cached Results, there might be situations where we dont want to set the SharePointRoot, when it is "too late"
    if (apiResult?.sharePointFolder === currentFolderId) {
      setSharePointRoot(apiResult);
    }

    if (apiResult?.sharePointFolder) {
      setCachedSharePointRoots({
        ...cachedSharePointRoots,
        [apiResult?.sharePointFolder]: apiResult,
      });
    }
  };

  /**
   * Init Data with Cached Result, when cachedResult changes e.g. on login and every x minutes after update
   */
  useEffect(() => {
    if (
      updateResult &&
      updateResult.fileTree &&
      sharePointRoot?.sharePointFolder === '' &&
      updateResult?.sharePointFolder === currentFolderId // only init if currentFolder is the root of the principal
    ) {
      // Add Notification Information to Cache
      cachedNotificationsByFolderId[updateResult.sharePointFolder] = {
        updates: updateResult.updates,
        filesInAllSubTrees: updateResult.filesInAllSubTrees,
      };
      setCachedNotificationsByFolderId({ ...cachedNotificationsByFolderId });

      // Set Current Sharepoint Folder to Cached Result from notifcations
      setSharePointRoot({
        sharePointFolder: updateResult?.sharePointFolder,
        value: updateResult.fileTree,
        subFileTrees: updateResult.subFileTrees
          ? updateResult.subFileTrees
          : undefined,
      });
    } else {
      if (
        updateResult?.sharePointFolder === currentFolderId &&
        updateResult.subFileTrees &&
        sharePointRoot.subFileTrees === undefined
      ) {
        const subFileTrees = updateResult.subFileTrees;
        const value = updateResult.fileTree ? updateResult.fileTree : [];
        setSharePointRoot({ ...sharePointRoot, subFileTrees, value });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFolderId, updateResult]);

  /**
   * Update parentFolderId of currently selected sharepoint folder
   */
  useEffect(() => {
    setParentFolderId(
      parentFolderIdStack.length > 0
        ? parentFolderIdStack[parentFolderIdStack.length - 1]
        : '',
    );
  }, [parentFolderIdStack]);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  /**
   * Reset error on principal change
   */
  useEffect(() => {
    setErrorMessage(null);
  }, [currentPrincipal]);

  /**
   * Load Data if currentFolder changes
   */
  useEffect(() => {
    if (currentFolderId && sharePointDriveId) {
      // Start Updating
      getSharePointFolder(
        client,
        sharePointDriveId,
        currentFolderId,
        interceptSetSharePointRoot,
        setErrorMessage,
        t,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharePointDriveId, currentFolderId]);

  /**
   * Travel Downwards in Tree
   */
  const folderClickHandler = (folder: FileTreeElement) => {
    // Look if we have a cached Result
    if (cachedSharePointRoots[folder.id]) {
      setSharePointRoot(cachedSharePointRoots[folder.id]);
    }
    // Check Cache existing from notfications for way downwards
    else if (sharePointRoot.subFileTrees) {
      const cachedTree = sharePointRoot.subFileTrees.find(
        (subTree) => subTree?.sharePointFolder === folder.id,
      );
      if (cachedTree && cachedTree.sharePointFolder && cachedTree.fileTree) {
        // Add Notification Information to Cache
        cachedNotificationsByFolderId[cachedTree.sharePointFolder] = {
          updates: cachedTree.updates ? cachedTree.updates : 0,
          filesInAllSubTrees: cachedTree.filesInAllSubTrees
            ? cachedTree.filesInAllSubTrees
            : 0,
        };
        setCachedNotificationsByFolderId({ ...cachedNotificationsByFolderId });
        // Use Cache File Infos
        setSharePointRoot({
          sharePointFolder: cachedTree?.sharePointFolder,
          value: cachedTree?.fileTree,
          subFileTrees: cachedTree?.subFileTrees
            ? cachedTree.subFileTrees
            : undefined,
        });
      }
    }
    const updatedParentStack = parentFolderIdStack.concat(
      currentFolderId as string,
    );
    setParentFolderIdStack(updatedParentStack);
    setCurrentFolderId(folder.id);
  };

  /**
   * Travel Upwards in Tree
   */
  const parentFolderClickHandler = () => {
    // Look if we have a cached Result
    if (parentFolderId && cachedSharePointRoots[parentFolderId]) {
      setSharePointRoot(cachedSharePointRoots[parentFolderId]);
    }

    setCurrentFolderId(parentFolderId);
    setParentFolderIdStack(parentFolderIdStack.slice(0, -1));
  };

  return (
    <div className="w-full">
      <div className="w-full px-4 md:px-6">
        <div className="max-w-xl-content mx-auto">
          <div className="mb-7 pt-6 md:mb-9 md:pt-7" data-test="page-header">
            <div className="flex flex-col items-baseline justify-between md:flex-row md:space-x-2">
              <Heading
                text={`${t('features:shared-documents:heading')}`}
                margin={0}
                level="h1"
              />
            </div>
          </div>
          <Tabs activeTab={activeTab} onTabChange={setActiveTab}>
            <Tab
              title={`${t('features:shared-documents:tabs:lastAdded')}`}
              tabKey="last-added"
              key="last-added"
            >
              <Sharepoint
                currentPrincipalId={currentPrincipal.id}
                fileTree={
                  updateResult.updatedFiles
                    ? [...updateResult.updatedFiles]
                    : []
                }
                onFolderClick={folderClickHandler}
                onParentFolderClick={parentFolderClickHandler}
              />
            </Tab>
            <Tab
              title={`${t('features:shared-documents:tabs:allDocuments')}`}
              tabKey="all-documents"
              key="all-documents"
            >
              <div className="pb-4 md:pb-5">
                {process.env.REACT_APP_ENVIRONMENT === 'dev' && (
                  <div className="flex flex-col">
                    Configurations SharepointDriveId <br></br>Root Folder
                    specific to Principal{' '}
                    {!currentPrincipal.sharepointDriveId &&
                      'Maybe you need to change the Principal'}
                    <br></br>
                  </div>
                )}
                {currentFolderId && parentFolderId && (
                  <Sharepoint
                    currentPrincipalId={currentPrincipal.id}
                    currentFolderId={currentFolderId}
                    parentFolderId={parentFolderId}
                    fileTree={sharePointRoot.value}
                    subFileTrees={sharePointRoot.subFileTrees}
                    onFolderClick={folderClickHandler}
                    onParentFolderClick={parentFolderClickHandler}
                  />
                )}
                {errorMessage && (
                  <div className="py-4 text-center text-red-200">
                    {errorMessage}
                  </div>
                )}
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};
