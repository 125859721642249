import { basePrincipalServiceRequest } from './base-request';
import { GetMarketInsightsResponseSingleDto } from './dto/GetMarketInsights.dto';
import { CRMCountryData } from '../../hooks/queries';

export async function getCountriesInformation() {
  const { data } = await basePrincipalServiceRequest<{
    items: CRMCountryData[];
  }>('get', 'principals/countries');
  return data;
}

export async function getCRMMarketInsights(principalId: string) {
  const {
    data: { items },
  } = await basePrincipalServiceRequest<{
    items: GetMarketInsightsResponseSingleDto[];
  }>('get', `principals/${principalId}/market-insights`);

  return items;
}
