import { EditDataAccessModal } from 'components/EditDataAccessModal';
import { Heading } from 'components/Heading';
import { Link } from 'components/Link';
import { Table } from 'components/Table/Table';
import { TableBody } from 'components/Table/TableBody';
import { TableCell } from 'components/Table/TableCell';
import { TableHeader } from 'components/Table/TableHeader';
import { TableRow } from 'components/Table/TableRow';
import { routes } from 'controllers/ContentController/Routes';
import { useCurrentUser, usePrincipals } from 'hooks/queries';
import { getCurrentPrincipal } from 'lib/currentPrincipal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MainFeatureType } from 'shared/types/features/FeatureIds';
import { Principal } from 'utils/firebase/collection';
import { PrincipalTableRow } from './PrincipalsTableRow';

export const PrincipalAdministration: React.FC = () => {
  const { t } = useTranslation('translation');
  const { user } = useCurrentUser();
  const { data: principals } = usePrincipals();
  const { currentPrincipal } = getCurrentPrincipal(user.userId, principals);
  const navigate = useNavigate();
  const adminCenterRoute = routes(currentPrincipal.slug).find(
    MainFeatureType.ADMIN_CENTER,
  );

  const [targetPrincipal, setTargetPrincipal] = useState<Principal>();
  const [showEditDataAccessModal, setShowEditDataAccessModal] = useState(false);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | undefined>();

  const onPrincipalSelection = (principalId: string) => {
    setTargetPrincipal(principals?.find(({ id }) => id === principalId));
    setShowEditDataAccessModal(true);
  };

  return (
    <div className="w-full">
      <div className="max-w-xl-content xl:min-w-content mx-auto mb-7 space-y-5 px-4 pt-6 md:mb-9 md:px-6 md:pt-7">
        <div className="mb-2">
          <Link
            label={t('linksOrButtons:back')}
            icon="ArrowLeft"
            iconPosition="left"
            onClick={() => navigate(adminCenterRoute.url)}
          />
        </div>

        <div className="space-y-4">
          <Heading
            text={t('features:admin-center:principalAdministration:tile:title')}
            margin={0}
            level="h1"
          />
          <div className="text-grey-700 mt-1 w-3/4 ">
            {t('features:admin-center:principalAdministration:description')}
          </div>
          <Table>
            <TableHeader>
              <TableRow darkBorder>
                <TableCell
                  noWrap
                  displaySortingButton={true}
                  sortOrder={sortOrder}
                  onClick={() =>
                    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
                  }
                >
                  {t('features:admin-center:principalAdministration:principal')}
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {principals
                ?.sort(({ name: a }, { name: b }) =>
                  sortOrder === 'desc'
                    ? -a.localeCompare(b)
                    : a.localeCompare(b),
                )
                .map((p: Principal) => (
                  <PrincipalTableRow
                    key={p.id}
                    principalId={p.id}
                    logoUrl={p.logoUrl}
                    principalName={p.name}
                    onPrincipalSelection={onPrincipalSelection}
                  />
                ))}
            </TableBody>
          </Table>
        </div>
      </div>
      {showEditDataAccessModal && targetPrincipal && (
        <EditDataAccessModal
          onClose={() => {
            setShowEditDataAccessModal(false);
          }}
          targetPrincipal={targetPrincipal}
        />
      )}
    </div>
  );
};
