import axios from 'axios';

export const getFirebaseToken = (idToken: string) => {
  return axios({
    url: `${process.env.REACT_APP_PORTAL_SERVICE_BASE_URL}/auth/firebase-token`,
    method: `get`,
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  });
};
