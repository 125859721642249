import axios from 'axios';
import { CustomerDataByReportType } from '../../components/EditDataAccessModal';
import { FeatureResponse } from '../../hooks/usePrincipalFeatures';
import { getFirebaseBearerToken } from '../../lib/getFirebaseBearerToken';
import {
  ExpansionOpportunityResponse,
  PostEdiFormRequestDto,
  Mandates,
  PrincipalAssignmentFilter,
} from './principal-endpoints.types';

export const temp = () => console.log('temp');

const baseRequestFunction = async <T>(
  method: string,
  urlExtention: string,
  body?: Record<string, unknown>,
) => {
  const bearerToken = await getFirebaseBearerToken();
  return axios<T>({
    url: `${process.env.REACT_APP_PORTAL_SERVICE_BASE_URL}/principals/${urlExtention}`,
    method: `${method}`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
    },
    data: body,
  });
};

export const postPrincipalEdi = async (
  principalId: string,
  contactForm: PostEdiFormRequestDto,
) => {
  return baseRequestFunction<void>('post', `${principalId}/edi`, contactForm);
};

export const getPrincipalFeatures = async (principalId: string) => {
  return baseRequestFunction<FeatureResponse>('get', `${principalId}/features`);
};

export const updatePrincipalFeatureAccessRights = async (
  principalId: string,
  disabledFeatures: string[],
  maintenanceMode: string[],
  customerData: CustomerDataByReportType,
): Promise<void> => {
  await baseRequestFunction('patch', `${principalId}`, {
    disabledFeatures,
    maintenanceMode,
    customerData,
  });
};

const buildQueryString = (filter: PrincipalAssignmentFilter | undefined) => {
  if (!filter || filter.hasAllMandates) return '';

  return (
    '?' +
    Object.entries(filter)
      .map(([key, value]) => {
        if ((Array.isArray(value) && !value.length) || !value) return;
        return `${key}=${value.toString()}`;
      })
      .filter((stringParam): stringParam is string => !!stringParam)
      .join('&')
  );
};

export const getPrincipalMandateAssignments = async (
  principalId: string,
  filter?: PrincipalAssignmentFilter,
) => {
  const queryString = buildQueryString(filter);
  const response = await baseRequestFunction<Mandates>(
    'get',
    `${principalId}/assignments${queryString}`,
  );
  return response.data;
};

export const getExpansionOpportunities = (principalId: string) => {
  return baseRequestFunction<ExpansionOpportunityResponse>(
    'Get',
    `${principalId}/expansion-opportunities`,
  );
};
