import { useQuery } from '@tanstack/react-query';
import { getAllGlobalUsers } from '../../utils/principal-service/user-endpoints';

export const useGlobalUsers = () => {
  const { data: allGlobalUsers } = useQuery({
    queryFn: getAllGlobalUsers,
    queryKey: ['global-users'],
  });
  return allGlobalUsers;
};
