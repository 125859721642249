import { getCurrentPrincipal } from 'lib/currentPrincipal';
import { Notification } from 'lib/notifications/notifications';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Role } from 'shared/types/authorization';
import { extractRoleFromUser } from 'utils/authorization.utils';
import { viewUsers } from 'utils/principal-service/user-endpoints';
import { useCurrentUser } from './useCurrentUser';
import { useGlobalUsers } from './useGlobalUsers';
import { usePrincipals } from './usePrincipals';
import { useRoleDefinitions } from './useRoleDefinitions';
import { BaseUseUserResult, PortalUser } from './useUser';

export type UseUsersResult = {
  users: BaseUseUserResult[] | undefined;
  setUsersAreUpdated: Dispatch<SetStateAction<boolean>>;
};

type UseUsersProps = {
  allInternalUsers?: boolean;
  disabled?: boolean;
};

/**
 * Custom Hook to get all users of the current principal and global users (if you have the permissions to get them)
 * @param allInternalUsers Fetches all internal users (only available with global_user rights)
 * @returns {UseUsersResult}, Array of users and setter if user data is changed to indicate an update of the userData
 */
export const useUsers = (props?: UseUsersProps): UseUsersResult => {
  const [principalUsers, setPrincipalUsers] = useState<PortalUser[]>();
  const [isUpdated, setIsUpdated] = useState<boolean>(false);

  const { user } = useCurrentUser();
  const { data: principals } = usePrincipals();
  const { currentPrincipal } = getCurrentPrincipal(user.userId, principals);
  const globalUserDocsTest = useGlobalUsers();
  const roleDefinitions = useRoleDefinitions();
  const { t } = useTranslation();

  useEffect(() => {
    if (props?.disabled) return;

    setIsUpdated(false);
    viewUsers({
      currentPrincipal: currentPrincipal.id,
      allInternalUsers: props?.allInternalUsers,
    })
      .then((data) => {
        if (Array.isArray(data.result)) {
          setPrincipalUsers(data.result);
        }
      })
      .catch(() => {
        Notification({
          message: t('errors:failedToLoadUsers'),
        });
      });
  }, [
    currentPrincipal.id,
    globalUserDocsTest,
    isUpdated,
    props?.allInternalUsers,
    props?.disabled,
    t,
  ]);

  const resultData = useMemo(() => {
    return principalUsers?.map((principalUser) => {
      const role =
        extractRoleFromUser(currentPrincipal.id, principalUser) ?? Role.NONE;

      return {
        user: principalUser,
        role,
        userType: roleDefinitions[role]?.user_type,
        isGlobalUser: !!principalUser.global_user,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPrincipal,
    principalUsers,
    globalUserDocsTest,
    props?.allInternalUsers,
  ]);

  return { users: resultData, setUsersAreUpdated: setIsUpdated };
};
