import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getPowerBiConfiguration } from 'utils/principal-service/powerbi-endpoints';

type ReportConfig = {
  datasetId: string;
  groupId: string;
  reportId: string;
  reportSectionId: string;
};

export type ConfigPowerBI = {
  reports: Reports;
};

type Reports = {
  [key in ReportKey]: ReportConfig;
};

export type ReportKey =
  | 'dashboard'
  | 'operational_performance'
  | 'customer_insights';

export const usePowerBI = (): UseQueryResult<ConfigPowerBI> =>
  useQuery({
    queryFn: getPowerBiConfiguration,
    queryKey: ['powerbi', 'configuration'],
    select: ({ data }) => data,
  });
